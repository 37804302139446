import { format } from 'date-fns'
import { FC, useEffect } from 'react'

import { getEligibilityStatusInfo, StatusDisplay } from 'ui'

import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'

import { SmartTable } from '../../_siteWide/table/SmartTable'
import { ColumnType } from '../../_siteWide/table/SmartTableTypes'
import {
  EligibilityPatients,
  EligibilityPatientsRequest,
} from '../../../api/eligibility/eligibility-client/api'
import { GetPatients } from '../../../api/eligibility/eligibilityApi'
import { Errors } from '../../../constants/errors'
import { showMessage } from '../../../utilities/general'
import { useEligibilityContext } from '../shared/context/EligibilityContext'
import { usePatientEligibilityDetailContext } from '../shared/context/PatientEligibilityDetailContext'
import ActionColumnCell from './shared/EligibilityActionCell/GenericActionCell'
import EligibilityRowActionsMenu from './shared/EligibilityRowActionsMenu/EligibilityRowActionsMenu'
import GlobalState from 'trellis:state/globalState'

const defaultSearch = {
  Name: '',
  Birthdate: '',
  Carrier: '',
  Status: '',
  LastVerified: '',
}

const PatientsGrid: FC<{ toolbar: JSX.Element }> = ({ toolbar }) => {
  const {
    loading,
    patientDateRange,
    patientState,
    requiresTableRefresh,
    setLoading,
    setPatientDateRange,
    setPatientState,
    setRequiresTableRefresh,
    setShowEligibilityResponseModal,
    setShowPatientUpdateFormModal,
    setVerificationResponse,
  } = useEligibilityContext()
  const { handleGetPatient } = usePatientEligibilityDetailContext()

  useEffect(() => {
    if (requiresTableRefresh) handleGetPatients()
  }, [requiresTableRefresh])

  const handleGetPatients = async () => {
    setLoading(true)

    const getPatientsRequest: EligibilityPatientsRequest = {
      CurrentPage: patientState.Filters.CurrentPage,
      PageSize: patientState.Filters.PageSize,
      SortColumn: {
        Column: patientState.Filters.SortColumn,
        Sort: patientState.Filters.SortDirection,
      },
      Filters: {
        ...patientState.Filters.Config,
        name: patientState.Filters.Name,
        status: patientState.Filters.Config.Status,
      },
    }

    await GetPatients(
      GlobalState.Auth.peek(),
      GlobalState.Auth.CustomerId.peek(),
      getPatientsRequest,
    )
      .then(({ data: response }) => {
        const copy = { ...patientState }
        copy.Data = response.Data
        copy.Filters.Options.Carriers = response.Carriers?.map(
          (carrier: string, index: number) => ({ key: index, value: carrier }),
        )
        copy.Filters.Options.Statuses = response.Statuses?.map(
          (status: string, index: number) => ({ key: index, value: status }),
        )
        copy.Total = response.TotalCount

        setPatientState(copy)
      })
      .catch(() => showMessage(Errors.getPatientsError))
      .finally(() => {
        setRequiresTableRefresh(false)
        setLoading(false)
      })
  }

  const columns: ColumnType[] = [
    {
      dataIndex: 'name',
      noFilter: true,
      onCell: (tableItem: EligibilityPatients) => ({
        onClick: () => handleGetPatient(tableItem),
      }),
      render: (_value, tableItem: EligibilityPatients) => (
        <p className='cursor-pointer'>
          {tableItem.PatientFirstName} {tableItem.PatientLastName}
        </p>
      ),
      title: 'Patient Name',
      width: 200,
    },
    {
      dataIndex: 'CarrierName',
      options: patientState.Filters.Options.Carriers,
      title: 'Carrier',
      type: 'auto',
      width: 200,
    },
    {
      dataIndex: 'Status',
      noFilter: true,
      render: (value: string) => {
        const info = getEligibilityStatusInfo(value)
        return (
          <StatusDisplay
            status={info.status}
            toolTip={info.toolTip}
            getStatusOverride={getStatusOverride}
          />
        )
      },
      type: 'select',
      width: 200,
    },
    {
      dataIndex: 'ResponseDate',
      render: (value: string) => {
        return value && format(new Date(value), 'MM/dd/yyy')
      },
      title: 'Response Date',
      type: 'range',
      width: 200,
    },
    {
      dataIndex: '',
      noSort: true,
      render: (_value, tableItem: EligibilityPatients) => (
        <ActionColumnCell tableItem={tableItem} />
      ),
      title: 'Action',
      type: 'range',
      width: 125,
    },
    {
      dataIndex: '',
      noSort: true,
      render: (_value, tableItem: EligibilityPatients, index: number) => (
        <EligibilityRowActionsMenu
          index={index}
          tableItem={tableItem}
        />
      ),
      width: 50,
    },
  ]

  return (
    <SmartTable
      columns={columns}
      dateRange={patientDateRange}
      defaultSearch={defaultSearch}
      getData={handleGetPatients}
      isFullViewport={true}
      loading={loading}
      rowKey='PatientId'
      setDateRange={setPatientDateRange}
      setState={setPatientState}
      state={patientState}
      tableClass='patients-table'
      toolbar={toolbar}
    />
  )
}

export default PatientsGrid
