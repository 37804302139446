import { ActiveServicesObject } from 'utils'

export const parseActiveServices = (
  activeServicesArray: string[],
): ActiveServicesObject => {
  const activeServices: ActiveServicesObject = {
    DENTAL_INTEL: false,
    FASTATTACH: false,
    OPERA_CHAT: false,
    OPERA_EMAIL_ADVANCED: false,
    OPERA_FORMS: false,
    OPERA_RECALL: false,
    OPERA_REMINDERS: false,
    OPERA_REVIEWS: false,
    PEARL_IMAGE_SYNC: false,
    SIMPLIFEYE_CAMPAIGNS: false,
    SIMPLIFEYE_LIVE_WEBCHAT: false,
    SIMPLIFEYE_ONLINE_SCHEDULING: false,
    SIMPLIFEYE_PAYMENTS: false,
    TRELLIS_CLAIMS: false,
    TRELLIS_DSO_DATA: false,
    TRELLIS_ELIGIBILITY: false,
    EAGLESOFT: false,
  }

  if (activeServicesArray?.length) {
    Object.keys(activeServices).forEach((activeService) => {
      if (activeServicesArray.includes(activeService)) {
        activeServices[activeService] = true
      }
    })
  }

  return activeServices
}
