import { Col, Row } from 'antd'
import { FC, SetStateAction, useEffect, useState } from 'react'

import { DatePicker } from 'ui'
import { endOfDay, isBefore } from 'date-fns'
import { AttachmentDatePickerConstants } from 'trellis:components/attachmentsShared/shared/sharedConstants'

import './imageDateTaken.scss'

type ImageDateTakenProps = {
  setImageDate: (value: SetStateAction<Date>) => void
  imageDate: Date
}
const ImageDateTaken: FC<ImageDateTakenProps> = ({
  setImageDate,
  imageDate,
}) => {
  const [imageDateTaken, setImageDateTaken] = useState<Date>()

  useEffect(() => {
    if (imageDate) {
      onImageDateChange(new Date(imageDate))
    } else {
      onImageDateChange(new Date())
    }
  }, [])

  const onImageDateChange = (event: Date) => {
    setImageDateTaken(event)
    setImageDate(event)
  }

  return (
    <Row className='mb-425'>
      <Col>
        <h4 className='image-date-taken__title'>Date Taken</h4>
        <DatePicker 
          value={imageDateTaken}
          format='MM/DD/YYYY'
          placeholder='MM/DD/YYYY'
          onChange={onImageDateChange}
          disabledDate={(date) =>
            date &&
            (isBefore(
              date,
              new Date(AttachmentDatePickerConstants.MINIMUM_DISABLED_DATE),
            ) ||
              date > endOfDay(new Date()))
          }
        />
      </Col>
    </Row>
  )
}

export default ImageDateTaken
