import { useEffect, useRef } from 'react'

export const useTimeout = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (typeof delay !== 'number') return () => null

    const timeoutId = setTimeout(() => savedCallback.current(), delay)

    return () => clearTimeout(timeoutId)
  }, [delay])
}
