import { AutoComplete, Input, Select } from 'antd'
import { ChangeEvent, FC, useEffect, useState } from 'react'

import {
  getAppoinmentDateRanges,
  getDateRanges,
  setSelectedDateRange,
} from 'ui'
import { RangePicker } from 'ui'
import {
  ControlOptionType,
  ObjAnyPropsType,
  sortHeaderDateRangeType,
} from '../../SmartTableTypes'


type SearchControlType = {
  options: any[]
  dateRange: sortHeaderDateRangeType
  updateSearch: (value: string) => void
  search: ObjAnyPropsType
  searchInput: any
  filterLoading: boolean
  openDate: boolean
  setDateFilter: (
    date: [Date, Date],
    dateString: string[],
    info: any,
    confirm: () => void,
  ) => void
  type: string
  property: string
  rangeType: string
  confirm: () => void
  handleSearch: (...args: any) => void
}

const { Option } = Select

const SearchControl: FC<SearchControlType> = ({
  options,
  dateRange,
  updateSearch,
  search,
  searchInput,
  filterLoading,
  openDate,
  setDateFilter,
  property,
  type,
  rangeType,
  confirm,
  handleSearch,
}) => {
  const [foundOptions, setOptions] = useState<ControlOptionType[]>(options)
  let SelectedControl: JSX.Element

  useEffect(() => {
    if (dateRange) setSelectedDateRange(dateRange)
  }, [dateRange])

  const onSearch = (searchValue: string) => {
    if (searchValue) {
      searchValue = searchValue.toLowerCase()
      const filteredOptions: ControlOptionType[] = options.filter(
        (option: ControlOptionType) =>
          option.value.toLowerCase().includes(searchValue),
      )
      setOptions(filteredOptions)
    } else setOptions(options)

    updateSearch(searchValue)
  }

  switch (type) {
    case 'auto':
      SelectedControl = (
        <AutoComplete
          value={search[property]}
          ref={(node) => (searchInput.current = node)}
          placeholder='Search'
          options={foundOptions}
          onSelect={(value: string) => {
            updateSearch(value), handleSearch(value, confirm)
          }}
          onSearch={onSearch}
          style={{ width: 400 }}
        />
      )
      break
    case 'text':
      SelectedControl = (
        <Input
          value={search[property]}
          placeholder='Search'
          ref={(node) => (searchInput.current = node)}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            updateSearch(e.target.value)
          }
          onPressEnter={(e: any) => handleSearch(e.target.value, confirm)}
          style={{ width: 400 }}
        />
      )
      break
    case 'select':
      SelectedControl = (
        <Select
          style={{ width: 400 }}
          value={search[property]}
          ref={(node) => (searchInput.current = node)}
          onChange={(value: string) => {
            updateSearch(value), handleSearch(value, confirm)
          }}
          loading={filterLoading}
        >
          {options.map((option: ControlOptionType, i: number) => (
            <Option
              key={i}
              value={option.value}
            >
              {option.value === 'In Queue' ? 'Ready' : option.value}
            </Option>
          ))}
        </Select>
      )
      break
    case 'range':
      SelectedControl = (
        <RangePicker
          open={openDate}
          //ref={(node) => (searchInput.current = node)}
          value={dateRange.dates}
          format='MM/dd/y'
          allowClear={false}
          onOpenChange={() => setSelectedDateRange(dateRange)}
          onCalendarChange={(date, dateString, info) =>
            setDateFilter(date, dateString, info, confirm)
          }
          ranges={
            rangeType === 'future' ? getAppoinmentDateRanges() : getDateRanges()
          }
        />
      )
      break
    default:
      SelectedControl = null
      break
  }

  return SelectedControl
}

export default SearchControl
