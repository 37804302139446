import { batch, ObservableObject } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { useQuery } from '@tanstack/react-query'
import { endOfDay, startOfDay, sub } from 'date-fns'
import { FC } from 'react'

import { DataTable, DataTableConfig, DataTableDateRange } from 'ui'
import { LogError } from 'utils'

import {
  BillingStatementFiles,
  StatementsQueryRequest,
} from 'trellis:api/statements/statements-client'
import { GetStatementFiles } from 'trellis:api/statements/statementsApi'
import { Errors } from 'trellis:constants/errors'
import GlobalState from 'trellis:state/globalState'
import { showMessage } from 'trellis:utilities/general'

import {
  billingFilesColumns,
  FileFilterOptions,
  getBillingStatementsDateRanges,
  getFormattedDateRangeString,
  getQueryConfig,
} from '../utils/billing-statements-helpers'

type BillingFilesTableProps = {
  downloadTableRequest$: ObservableObject<StatementsQueryRequest>
}

export const BillingFilesTable: FC<BillingFilesTableProps> = observer(
  ({ downloadTableRequest$ }) => {
    const data$ = useObservable<BillingStatementFiles[]>([])
    // TODO: persist the config so filter/sort state
    // TODO: maybe retrieve initial data via loader
    const dataTableConfig$ = useObservable<
      DataTableConfig<BillingStatementFiles>
    >({
      columns: billingFilesColumns,
      pagination: {
        currentPage: 1,
        dataTotal: 0,
        pageLimit: 50,
      },
      sortBy: {
        dataField: 'ProcessDate',
        value: 'asc',
      },
      filters: {
        ProcessDate: getFormattedDateRangeString(
          startOfDay(sub(new Date(), { days: 30 })),
          endOfDay(new Date()),
        ),
      },
    })
    const { data, error, isError, isPending } = useQuery({
      queryKey: ['billingStatementsData', dataTableConfig$.get()],
      queryFn: () =>
        GetStatementFiles(GlobalState.Auth.peek(), {
          Filters: dataTableConfig$.filters.peek(),
          Range: dataTableConfig$.pagination.pageLimit.peek(),
          Offset:
            (dataTableConfig$.pagination.currentPage.peek() - 1) *
            dataTableConfig$.pagination.pageLimit.peek(),
          SortColumn: dataTableConfig$.sortBy.dataField.peek(),
          SortDirection: dataTableConfig$.sortBy.value.peek(),
        }),
    })

    if (!isPending) {
      if (data) {
        batch(() => {
          data$.set(data.data.StatementFiles)
          dataTableConfig$.pagination.dataTotal.set(data.data.FilteredFiles)
          downloadTableRequest$.set(getQueryConfig(dataTableConfig$))
        })
      }

      if (isError) {
        LogError(error)
        showMessage(Errors.getBillingFilesError)
      }
    }

    const dateRange$ = useObservable<DataTableDateRange>({
      dataField: 'ProcessDate',
      options: getBillingStatementsDateRanges(),
      defaultRange: [
        startOfDay(sub(new Date(), { days: 30 })),
        endOfDay(new Date()),
      ],
    })

    return (
      <DataTable<BillingStatementFiles>
        config$={dataTableConfig$}
        data$={data$}
        dateRange$={dateRange$}
        filterBy={FileFilterOptions}
        isLoading={isPending}
        shouldFillViewport={true}
      />
    )
  },
)
