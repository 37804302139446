import { Col, Modal, Row } from 'antd'
import { FC } from 'react'

import './imageSyncNotConfiguredModal.scss'

import { Button } from 'ui'

import { Urls } from '../../shared/sharedConstants'
import { ImageSyncNotConfiguredModalProps } from '../imageSync.interface'

const ImageSyncNotConfiguredModal: FC<ImageSyncNotConfiguredModalProps> = ({
  isVisible,
  setIsVisible,
}) => {
  const handleChatDisplay = () => {
    window.open(Urls.IMAGE_SYNC_CHAT_NOW, '_blank')
  }

  return (
    <Modal
      className='image-sync-not-configured-modal'
      title={'Image Sync'}
      visible={isVisible}
      onCancel={() => setIsVisible(!isVisible)}
      width={1000}
      footer={null}
    >
      <Row gutter={[24, 24]}>
        <Col
          span={24}
          className='image-sync-not-configured-modal__body'
        >
          <p className='image-sync-not-configured-modal__body-title'>
            Image sync not configured
          </p>
        </Col>
        <Col
          span={24}
          className='image-sync-not-configured-modal__body'
        >
          <p>
            Please contact us to confirm your installation and configuration
            appointment.
          </p>
        </Col>
        <Col
          span={24}
          className='image-sync-not-configured-modal__body'
        >
          <Button
            type='primary'
            label='Chat Now'
            onClick={() => handleChatDisplay()}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default ImageSyncNotConfiguredModal
