import { Button, Col, Row, Tabs } from 'antd'

import { InvoiceHeader } from '../_siteWide/payment/invoice/invoiceHeader'
import { InvoiceSummary } from '../_siteWide/payment/invoice/invoiceSummary'

import './dashboard.scss'

import { useNavigate } from 'react-router-dom'

import { LDFlags$ } from 'trellis:state/globalState'

import {
  InvoiceContextProvider,
  useInvoiceContext,
} from '../_siteWide/payment/context/invoiceContext'

const { TabPane } = Tabs

export const Dashboard = () => {
  const {
    selectedTin,
    invoicesLoading,
    updateSelectedTin,
    selectedInvoice,
    invoices,
  } = useInvoiceContext()

  //TODO: Remove this page after 01/2024
  const legacyPaymentsDisabled = LDFlags$.trellisDisableLegacyPayments.use()
  const navigate = useNavigate()
  if (legacyPaymentsDisabled) {
    navigate('/', {
      replace: true,
    })
  }

  return (
    <article className='page-content__container page-content__container--with-page-header'>
      <section>
        <Row>
          <Col flex={1}>
            <span className='page-content-header__title'>Dashboard</span>
          </Col>
          <Col>
            <Button
              size='large'
              type='primary'
              onClick={() => (window.location.href = '/Payment')}
            >
              Setup Payment
            </Button>
          </Col>
        </Row>
        <InvoiceHeader
          selectedTin={selectedTin}
          invoice={selectedInvoice}
          invoices={invoices}
          initialLoad={invoicesLoading}
          updateSelectedTin={updateSelectedTin}
        />
      </section>
      <Tabs className='pt-100'>
        <TabPane
          key='Invoice'
          tab='Most Recent Invoice'
        >
          <InvoiceSummary
            loading={invoicesLoading}
            invoice={selectedInvoice}
          />
        </TabPane>
      </Tabs>
    </article>
  )
}

const content = (
  <InvoiceContextProvider>
    <Dashboard />
  </InvoiceContextProvider>
)
