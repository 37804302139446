// packages
import { FC } from 'react'

import { Button } from 'ui'

// utils
import {
  AdditionalServiceContentData,
  additionalServiceContentData,
} from './utils/additionalServiceContentData'

// assets
import './AdditionalServiceContent.scss'

import GlobalState from 'trellis:state/globalState'

export const additionalServiceContentTypes = [
  'campaigns',
  'eligibility',
  'forms',
  'onlineScheduling',
  'patientMessaging',
  'reminders',
  'reviews',
  'teamChat',
] as const
export type AdditionalServiceContentTypes =
  (typeof additionalServiceContentTypes)[number]

interface AdditionalServiceContentProps {
  type: AdditionalServiceContentTypes
}

export const AdditionalServiceContent: FC<AdditionalServiceContentProps> = ({
  type,
}) => {
  const {
    bullets,
    disclaimer,
    img,
    paragraph,
    quote,
    title,
    url,
  }: AdditionalServiceContentData = additionalServiceContentData[type]

  const handleLearnMore = (url?: string) => {
    if (GlobalState.ActiveServices.EAGLESOFT.peek()) {
      window.open(
        'https://www.pattersondental.com/cp/software/revenue-cycle-management-software/vyne-trellis',
        '_blank',
        'noopener,noreferrer',
      )
    } else if (url) {
      window.open(url, '_blank', 'noopener,noreferrer,resizable=yes')
    }
  }

  return (
    <article className='flex flex-row-reverse-wrap justify-center items-center'>
      <img
        alt={paragraph ? paragraph[0] : ''}
        className='additional-service__img'
        data-testid='additional-service-img'
        src={`${img}`}
      />
      <section>
        <h1 className='additional-service__title'>{title}</h1>
        <section className='page-section'>
          <section className='additional-service'>
            <div className='additional-service__body'>
              {paragraph &&
                paragraph.map((para, idx) => (
                  <p
                    key={`${para.slice(0, 12)}-${idx}`}
                    className={`additional-service__text ${''}`}
                  >
                    {para}
                  </p>
                ))}
              {bullets && (
                <ul className='additional-service__body'>
                  {bullets.map((bullt, ix) => (
                    <li key={`${bullt.slice(0, 12)}-${ix}`}>{bullt}</li>
                  ))}
                </ul>
              )}
              <Button
                data-testid='learn-more'
                label='Learn More'
                onClick={() => handleLearnMore(url)}
                size='large'
                type='primary'
              />
              {quote && (
                <div className='additional-service__quote'>
                  {quote.map((quot, indx) => (
                    <p
                      key={`${quot.slice(0, 12)}-${indx}`}
                      className={`additional-service__text2 ${
                        indx === 0 ? 'additional-service__text2--bold' : ''
                      }`}
                    >
                      {quot}
                    </p>
                  ))}
                </div>
              )}
              {disclaimer && (
                <div className='additional-service__subtext'>{disclaimer}</div>
              )}
            </div>
          </section>
        </section>
      </section>
    </article>
  )
}
