import { FC, useEffect } from "react"
import DateFnsDatePicker, { DateFnsRangePickerProps } from "../DatePicker/DateFnsDatePicker"

import { addListenerToPickerInput, formatPanelYears, formatPickerInputDate, removeListenerFromPickerInput } from "./utils/rangePickerUtilities"
import './RangePicker.scss'

const { RangePicker: DateFnsRangePicker } = DateFnsDatePicker

export interface DateRange {
  dates: [Date, Date]
  numberOfDays: number
}

export const RangePicker: FC<DateFnsRangePickerProps> = (props) => {

  useEffect(() => {
    addListenerToPickerInput(formatPickerInputDate)

    return () => {
      removeListenerFromPickerInput(formatPickerInputDate)
    }
  }, [])

  const rangePickerProps: DateFnsRangePickerProps = { ...props }

  // Force CSS
  rangePickerProps.className = `date-fns-range-picker ${props.className ?? ''}`

  const handleDateRender: DateFnsRangePickerProps['dateRender'] = (currentDate) => {
    const baseStyle: React.CSSProperties = {
      borderRadius: '16px',
      transition: 'none',
    }
  
    if (typeof currentDate === 'number' || typeof currentDate === 'string') {
      return <div className='ant-picker-cell-inner'>{currentDate}</div>
    }

    return (
      <div
        className={`date-fns-range-picker-cell ant-picker-cell-inner`}
        style={{ ...baseStyle }}
      >
        {currentDate.getDate()}
      </div>
    )
  }

  return (
    <DateFnsRangePicker 
      className='date-fns-range-picker'
      dateRender={handleDateRender}
      panelRender={(panelNode) => {
        formatPanelYears()
        return panelNode
      }}
      popupClassName='date-fns-range-picker-popup'
      {...props}
    />
  )
}