import { DeleteFilled } from '@ant-design/icons'
import { Button, Col, Popconfirm, Row, Tabs } from 'antd'
import { getUnixTime } from 'date-fns'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { RecurringPayment } from 'trellis:api/billing/billing-client'
import GlobalState, { LDFlags$ } from 'trellis:state/globalState'

import { useInvoiceContext } from '../_siteWide/payment/context/invoiceContext'
import { InvoiceHeader } from '../_siteWide/payment/invoice/invoiceHeader'
import { SmartTable } from '../_siteWide/table/SmartTable'
import {
  CancelRecurringPayment,
  GetRecurringPayments,
} from '../../api/billing/billingApi'
import { useGlobalContext } from '../../context/GlobalContextProvider'
import { showMessage } from '../../utilities/general'

const { TabPane } = Tabs

export const RecurringPayments = () => {
  //TODO: Remove this page after 01/2024
  const legacyPaymentsDisabled = LDFlags$.trellisDisableLegacyPayments.use()
  const navigate = useNavigate()
  if (legacyPaymentsDisabled) {
    navigate('/', {
      replace: true,
    })
  }

  const { authentication } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [state, setState] = useState({
    Key: 0,
    Data: [],
    OriginalData: [],
    Total: 0,
    Filters: {
      CurrentPage: 1,
      PageSize: 50,
      Config: {},
    },
  })

  const {
    selectedTin,
    updateSelectedTin,
    selectedInvoice,
    invoices,
    invoicesLoading,
    refreshInvoices,
  } = useInvoiceContext()

  useEffect(() => {
    getRecurringPayments()
  }, [authentication?.AccessToken])

  const getRecurringPayments = () => {
    if (authentication?.AccessToken) {
      if (!initialLoad) return
      setLoading(true)
      const copy = { ...state }
      GetRecurringPayments(authentication, authentication.CustomerId)
        .then(({ data }) => {
          if (data) {
            data.map((d: any, i: number) => {
              d.PaymentId = i
            })
          }
          copy.Data = data
          copy.OriginalData = data
          copy.Total = data ? data.length : 0
          setState(copy)
          setLoading(false)
        })
        .catch((e: any) => {
          showMessage(
            'There was an error getting your recurring payments. Please try again later.',
          )
          setLoading(false)
        })
    }
  }

  const cancelPayment = (recurringId: number, paymentType: string) => {
    CancelRecurringPayment(GlobalState.Auth.CustomerId.peek(), recurringId, {
      RecurringType: paymentType,
    })
      .then(({ data }) => {
        const copy = { ...state }
        const match = copy.Data.filter(
          (d: any) => d.RecurringScheduleId === recurringId,
        )[0]
        if (match) match.Status = 'Non-Active'
        setState(copy)
        showMessage('Payment was successfully canceled.', 'success')
        refreshInvoices()

        setLoading(false)
      })
      .catch(() => {
        showMessage(
          'There was an error canceling your payment. Please try again later.',
        )
        setLoading(false)
      })
  }

  const columns = [
    {
      title: 'Account/Tax ID',
      dataIndex: 'Tin',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: any, b: any) => a.Tin.localeCompare(b.Tin),
    },
    {
      title: 'Frequency',
      dataIndex: 'Frequency',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: any, b: any) => a.Frequency.localeCompare(b.Frequency),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: any, b: any) => a.Amount.localeCompare(b.Amount),
    },
    {
      title: 'First Payment',
      dataIndex: 'FirstPayment',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: any, b: any) =>
        getUnixTime(new Date(a.FirstPayment)) -
        getUnixTime(new Date(b.FirstPayment)),
      render: (value: string) => new Date(value).toLocaleDateString(),
    },
    {
      title: 'Total Transaction',
      dataIndex: 'TransactionCount',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: any, b: any) => a?.TransactionCount - b?.TransactionCount,
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: any, b: any) => a.Status.localeCompare(b.Status),
    },
    {
      title: 'Wallet',
      dataIndex: 'Wallet',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: any, b: any) => a.Wallet.localeCompare(b.Wallet),
    },
    {
      title: 'Action',
      dataIndex: 'cancel',
      noFilter: true,
      noSort: true,
      width: 170,
      render: (value: string, row: RecurringPayment) => {
        if (row.Status === 'Non-Active') return 'N/A'
        return (
          <Popconfirm
            title={`Are you sure to cancel the recurring payment for Tax ID ${row.Tin}?`}
            onConfirm={() =>
              cancelPayment(row.RecurringScheduleId, row.RecurringType)
            }
            okText='Yes'
            cancelText='No'
          >
            <DeleteFilled className='delete-icon fs-125' />
          </Popconfirm>
        )
      },
    },
  ]

  return (
    <article className='page-content__container page-content__container--with-page-header'>
      <section>
        <Row>
          <Col flex={1}>
            <span className='page-content-header__title'>
              Recurring Payments
            </span>
          </Col>
          <Col>
            <Button
              size='large'
              type='primary'
              onClick={() => (window.location.href = '/Payment')}
            >
              Setup Payment
            </Button>
          </Col>
        </Row>
        <InvoiceHeader
          invoice={selectedInvoice}
          invoices={invoices}
          selectedTin={selectedTin}
          initialLoad={invoicesLoading}
          updateSelectedTin={updateSelectedTin}
        />
      </section>
      <Tabs className='pt-100'>
        <TabPane
          key='Transactions'
          tab='Payment History'
        >
          <SmartTable
            columns={columns}
            getData={getRecurringPayments}
            isFullViewport={true}
            loading={loading && initialLoad}
            noFilter
            rowKey='PaymentId'
            setState={setState}
            state={state}
            storageKey='recurring-payment-grid-filters'
            tableClass='recurring-payment-table'
          />
        </TabPane>
      </Tabs>
    </article>
  )
}
