import { EyeOutlined, FileTextOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import { format } from 'date-fns'

import { getEligibilityStatusInfo, StatusDisplay } from 'ui'

import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'
import { LDFlags$ } from 'trellis:state/globalState'

import { EligibilityHistory } from '../../../../api/eligibility/eligibility-client'
import { useGlobalContext } from '../../../../context/GlobalContextProvider'
import { useEligibilityContext } from '../../shared/context/EligibilityContext'
import { usePatientEligibilityDetailContext } from '../../shared/context/PatientEligibilityDetailContext'
import EligibilityActionCell from '../../tabs/shared/EligibilityActionCell/EligibilityActionCell'

const PatientEligibilityHistory = () => {
  const flags = LDFlags$.use()

  const { authentication } = useGlobalContext()
  const { setShowEligibilityResponseModal, setVerificationResponse } =
    useEligibilityContext()
  const {
    formatPatientResponse,
    patient,
    eligibilityHistory,
    setShowingPatientResponseModal,
  } = usePatientEligibilityDetailContext()

  if (
    (flags.eligibilityUsevynedentalsync ||
      flags.operaVynesyncAutomatedeligibility) &&
    patient.PatientId === 0
  )
    return null

  let counter = 0
  eligibilityHistory.map((h: any) => {
    h.key = counter++
  })

  const columns = [
    {
      dataIndex: 'RequestDate',
      render: (dateString: string): string =>
        format(new Date(dateString), 'MM/dd/yyyy'),
      title: 'Date',
    },
    {
      dataIndex: 'CarrierName',
      title: 'Carrier',
    },
    {
      dataIndex: 'GroupNumber',
      title: 'Group #',
    },
    {
      dataIndex: 'Status',
      render: (value: string) => {
        const info = getEligibilityStatusInfo(value)
        return (
          <StatusDisplay
            status={info.status}
            toolTip={info.toolTip}
            getStatusOverride={getStatusOverride}
          />
        )
      },
      title: 'Status',
    },
    {
      render: (_value: string, tableItem: EligibilityHistory) => {
        return tableItem?.Status ===
          'Pending Patient Response' ? null : tableItem?.Status ===
          'Patient Response' ? (
          <FileTextOutlined
            onClick={() => loadPatientResponsePreview(tableItem.Response)}
            style={{ color: '#87878A', fontSize: '1.5em' }}
          />
        ) : (
          <EligibilityActionCell<EligibilityHistory>
            authentication={authentication}
            tableItem={{ ...patient, ...tableItem }}
            icon={<EyeOutlined />}
            setShowEligibilityResponseModal={setShowEligibilityResponseModal}
            setVerificationResponse={setVerificationResponse}
          />
        )
      },
      title: '',
    },
  ]

  const loadPatientResponsePreview = (response: string) => {
    formatPatientResponse(JSON.parse(response))
    setShowingPatientResponseModal(true)
  }

  return (
    <div className='mt-100'>
      {eligibilityHistory.length > 0 && (
        <Table
          className='eligibility-history table--non-smart'
          columns={columns}
          dataSource={eligibilityHistory}
          pagination={false}
          rowClassName={(_row, index) => index % 2 !== 0 && 'table-row-gray'}
          rowKey='key'
          scroll={{ y: 910 }}
        />
      )}
      {eligibilityHistory.length === 0 && (
        <div className='no-patient-eligibility'>
          <div className='no-history-icon' />
          <span>
            Enter Patient info and click Verify to get a verification response.
          </span>
        </div>
      )}
    </div>
  )
}

export default PatientEligibilityHistory
