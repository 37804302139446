import { Observable, observable } from '@legendapp/state'

import GlobalState from 'trellis:state/globalState'

export interface IClaimsPermissions {
  HideGridTabs: boolean
}

export interface IRoleHelper {
  isTrellis: boolean
  isDentalIntel: boolean
  Claims: IClaimsPermissions
}

export const RoleHelper$: Observable<IRoleHelper> = observable<IRoleHelper>({
  isTrellis: () => GlobalState.ActiveServices.TRELLIS_CLAIMS.get(),
  isDentalIntel: () => GlobalState.ActiveServices.DENTAL_INTEL.get(),
  Claims: {
    HideGridTabs: () => (RoleHelper$.isTrellis.get() ? false : true),
  },
})
