import React, { useEffect } from 'react'

import { LDFlags$ } from 'trellis:state/globalState'

import AttachmentsList from '../../../attachment/attachmentsList'
import { useClaimDetailContext } from '../../context/claimDetailContext'
import { useClaimGridContext } from '../../context/claimGridContext'

const AttachmentContent = () => {
  const flags = LDFlags$.use()
  const {
    claim,
    updateClaim,
    attachment,
    setAttachment,
    sentAttachments,
    attachmentRequirements,
    supportsAttachment,
    setIsCreatedFromSentAttachment,
  } = useClaimDetailContext()
  const { claimId, authentication } = useClaimGridContext()

  useEffect(() => {
    if (claim.Resend && claim.Carrier && sentAttachments?.length <= 0) {
      setIsCreatedFromSentAttachment(true)
    }
  }, [])

  return (
    <>
      <AttachmentsList />
    </>
  )
}

export default AttachmentContent
