import {
  Col,
  Divider,
  Dropdown,
  Input,
  InputRef,
  Menu,
  MenuProps,
  Modal,
  Radio,
  RadioChangeEvent,
  Spin,
  Switch,
} from 'antd'
import {
  ChangeEvent,
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'

import { Button } from 'ui'

import './EligibilitySettingsModal.scss'

import { DownOutlined, PlusOutlined } from '@ant-design/icons'

import { LogError } from 'utils'

import GlobalState from 'trellis:state/globalState'

import {
  GetSettings,
  SaveSettings,
} from '../../../../api/eligibility/eligibilityApi'
import errorOutline from '../../../../assets/error_outline.png'
import { EligibilitySettingsConstants } from '../../../../constants/general'
import { showMessage } from '../../../../utilities/general'

const { Group } = Radio
const { TextArea } = Input
const {
  MAX_LENGTH,
  MIN_DAYS,
  MAX_DAYS,
  DEFAULT_DAYS,
  DEFAULT_START_HOUR,
  DEFAULT_END_HOUR,
} = EligibilitySettingsConstants

interface EligibilitySettingsModalProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

type DeliveryMethod = 'TEXT_AND_EMAIL' | 'TEXT' | 'EMAIL'

type EligibilitySettingsFormProps = {
  automationTermDays: number
  sendUpdateForm: boolean
  startHour: number
  endHour: number
  customMessageText: string
  messageType: 'CUSTOM' | 'DEFAULT'
  deliveryMethod: DeliveryMethod
  sendReminder: boolean
  reminderTermHours: number
}

const initialEligibilitySettings: EligibilitySettingsFormProps = {
  automationTermDays: DEFAULT_DAYS,
  sendUpdateForm: false,
  startHour: DEFAULT_START_HOUR,
  endHour: DEFAULT_END_HOUR,
  customMessageText: '',
  messageType: 'DEFAULT',
  deliveryMethod: null,
  sendReminder: false,
  reminderTermHours: 0,
}

const EligibilitySettingsModal: FC<EligibilitySettingsModalProps> = ({
  isOpen,
  setIsOpen,
}): ReactElement => {
  const [saving, isSaving] = useState<boolean>(false)
  const [loading, setIsLoading] = useState<boolean>(false)
  const [settings, setSettings] = useState<EligibilitySettingsFormProps>(
    initialEligibilitySettings,
  )
  const [startHourLabel, setStartHourLabel] = useState<string>('9 AM')
  const [endHourLabel, setEndHourLabel] = useState<string>('7 PM')

  const legalBusinessStatus = GlobalState.LegalBusinessStatus.get()

  const customMessageRef = useRef<InputRef>(null)

  const defaultMessageText = 'Hi [[Patient Name]]. This is [[Office Name]]. To ensure a quick and smooth check-in for your next visit, please update your insurance information using the following link: '
  const lbsStatus = legalBusinessStatus?.verificationStatus

  useEffect(() => {
    if (isOpen) {
      getSettings()
    }
  }, [isOpen])

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSave = () => {
    isSaving(true)
    saveSettings()
  }

  const getSettings = async () => {
    setIsLoading(true)

    try {
      const { data } = await GetSettings(
        parseInt(GlobalState.Auth.CustomerId.peek()),
      )
      const {
        SendUpdateForm,
        AutomationTermDays,
        StartHour,
        EndHour,
        SendText,
        SendEmail,
        MessageText,
        MessageIsCustom,
      } = data

      let delivery: DeliveryMethod
      if (lbsStatus !== 2) delivery = 'EMAIL'
      else if (SendText && SendEmail) delivery = 'TEXT_AND_EMAIL'
      else if (SendText) delivery = 'TEXT'
      else if (SendEmail) delivery = 'EMAIL'
      // Defaults to the first option
      else delivery = 'TEXT_AND_EMAIL'

      let start: number = StartHour
      let end: number = EndHour
      if (!SendUpdateForm && StartHour === 0 && EndHour === 0) {
        start = DEFAULT_START_HOUR
        end = DEFAULT_END_HOUR
      }

      setSettings({
        ...settings,
        automationTermDays:
          AutomationTermDays > 0 ? AutomationTermDays : DEFAULT_DAYS,
        sendUpdateForm: SendUpdateForm,
        startHour: start,
        endHour: end,
        customMessageText: MessageIsCustom ? MessageText : '',
        messageType: MessageIsCustom ? 'CUSTOM' : 'DEFAULT',
        deliveryMethod: delivery,
        sendReminder: false,
        reminderTermHours: null,
      })
    } catch (e) {
      LogError(e, 'Error while retrieving eligibility settings')
    } finally {
      setIsLoading(false)
    }
  }

  const saveSettings = async () => {
    isSaving(true)

    if (getCurrentLength(settings.customMessageText) > MAX_LENGTH) {
      showMessage(
        'Please shorten custom message to allowed length before saving.',
      )
      isSaving(false)
      return
    }

    if (
      !settings.automationTermDays ||
      settings.automationTermDays < MIN_DAYS ||
      settings.automationTermDays > MAX_DAYS
    ) {
      showMessage('Please enter a number of days between 1 and 21')
      isSaving(false)
      return
    }

    const req = transformRequest()
    try {
      await SaveSettings(
        parseInt(GlobalState.Auth.CustomerId.peek()),
        req,
      )
      showMessage('Successfully saved Eligibility Settings.', 'success')
    } catch (e) {
      LogError(e)
      showMessage('Unable to save Eligibility Settings.')
    } finally {
      isSaving(false)
      setIsOpen(false)
    }
  }

  const transformRequest = () => {
    return {
      SendUpdateForm: settings.sendUpdateForm,
      AutomationTermDays: settings.automationTermDays,
      StartHour: settings.startHour,
      EndHour: settings.endHour,
      SendText: settings.deliveryMethod.includes('TEXT'),
      SendEmail: settings.deliveryMethod.includes('EMAIL'),
      MessageText:
        settings.messageType === 'CUSTOM'
          ? settings.customMessageText
          : defaultMessageText,
      MessageIsCustom: settings.messageType === 'CUSTOM' ? true : false,
    }
  }

  const handleUpdateDeliveryMethod = (e: RadioChangeEvent) => {
    const updatedMethod: DeliveryMethod = e.target.value
    setSettings({ ...settings, deliveryMethod: updatedMethod })
  }

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const date = new Date().toString().slice(25).replace(/[()]/g, '')
  const dateStr =
    '(' +
    date.substring(0, date.indexOf(' ')) +
    ')' +
    date.substring(date.indexOf(' '))
  const frankenDate = dateStr + ' (' + timeZone + ')'

  const menuItems: { key: number; label: string }[] = [
    { key: 1, label: '1 AM' },
    { key: 2, label: '2 AM' },
    { key: 3, label: '3 AM' },
    { key: 4, label: '4 AM' },
    { key: 5, label: '5 AM' },
    { key: 6, label: '6 AM' },
    { key: 7, label: '7 AM' },
    { key: 8, label: '8 AM' },
    { key: 9, label: '9 AM' },
    { key: 10, label: '10 AM' },
    { key: 11, label: '11 AM' },
    { key: 12, label: '12 PM' },
    { key: 13, label: '1 PM' },
    { key: 14, label: '2 PM' },
    { key: 15, label: '3 PM' },
    { key: 16, label: '4 PM' },
    { key: 17, label: '5 PM' },
    { key: 18, label: '6 PM' },
    { key: 19, label: '7 PM' },
    { key: 20, label: '8 PM' },
    { key: 21, label: '9 PM' },
    { key: 22, label: '10 PM' },
    { key: 23, label: '11 PM' },
    { key: 24, label: '12 AM' },
  ]

  const onHourStartClick: MenuProps['onClick'] = ({ key }) => {
    setStartHourLabel(
      menuItems.find((item) => item.key === parseInt(key)).label,
    )
    setSettings({ ...settings, startHour: parseInt(key) })
  }

  const onHourEndClick: MenuProps['onClick'] = ({ key }) => {
    setEndHourLabel(menuItems.find((item) => item.key === parseInt(key)).label)
    setSettings({ ...settings, endHour: parseInt(key) })
  }

  const menuStart = (
    <Menu
      onClick={onHourStartClick}
      className='ant-dropdown-settings-menu'
    >
      {menuItems
        .filter((item) => item.key < settings.endHour)
        .map((item) => {
          return <Menu.Item key={item.key}>{item.label}</Menu.Item>
        })}
    </Menu>
  )

  const menuEnd = (
    <Menu
      onClick={onHourEndClick}
      className='ant-dropdown-settings-menu'
    >
      {menuItems
        .filter((item) => item.key > settings.startHour)
        .map((item) => {
          return <Menu.Item key={item.key}>{item.label}</Menu.Item>
        })}
    </Menu>
  )

  const getHourLabel = (hour: number, end: boolean) => {
    const itemFound = menuItems.find((item) => item.key === hour)

    return itemFound?.label != null
      ? itemFound.label
      : end
      ? endHourLabel
      : startHourLabel
  }

  const handleAddPatientName = () => {
    const updatedMessage = `${settings.customMessageText}${'[[Patient Name]]'}`
    setSettings({ ...settings, customMessageText: updatedMessage })
    customMessageRef.current.focus({ cursor: 'end' })
  }

  const handleUpdateCustomMessage = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    // Note: accounting for the fact that the placeholder is 16 characters in length but we want to allow only 15 characters for the patient name here
    setSettings({
      ...settings,
      customMessageText: e.target.value,
    })
  }

  const getCurrentLength = (message: string) =>
    message.includes('[[Patient Name]]') ? message.length - 1 : message.length

  const getRemainingCharacters = (message: string) => {
    const currentLength = getCurrentLength(message)
    if (currentLength >= MAX_LENGTH) return 0
    else return MAX_LENGTH - currentLength
  }

  const handleTermDaysChange = (e: ChangeEvent<HTMLInputElement>) => {
    const days = parseInt(e.target.value)
    if (days < MIN_DAYS || days > MAX_DAYS) {
      showMessage('Please enter a number of days between 1 and 21')
    }

    setSettings({ ...settings, automationTermDays: days })
  }

  return (
    <Modal
      className='eligibility-settings-modal'
      destroyOnClose
      footer={[
        <Button
          key='close-settings'
          label='Close'
          onClick={handleClose}
        />,
        <Button
          loading={saving}
          key='save-settings'
          label='Save'
          onClick={handleSave}
          type='primary'
        />,
      ]}
      onCancel={handleClose}
      title='Eligibility Settings'
      visible={isOpen}
      width='fit-content'
    >
      <Spin spinning={loading}>
        <section
          className={`eligibility-settings-modal__form eligibility-settings-modal__form--auto-settings`}
        >
          <section className='eligibility-settings-modal__form-title-auto'>
            Automated Settings
          </section>
          <div className='eligibility-settings-modal__form-settings-text'>
            Verify eligibility
            <input
              className='eligibility-settings-modal__form-number-input'
              type='number'
              min={MIN_DAYS}
              max={MAX_DAYS}
              title='Please enter a number between 1 and 21'
              required
              value={settings.automationTermDays}
              onChange={handleTermDaysChange}
            />
            days before a patient's scheduled appointment
          </div>
        </section>
        <section
          className={`eligibility-settings-modal__form eligibility-settings-modal__form--${
            settings.sendUpdateForm ? 'enabled' : 'disabled'
          }`}
        >
          <section className='eligibility-settings-modal__form-title-form'>
            Patient Update Form
          </section>
          <div>
            <Col className='eligibility-settings-modal__form-switch'>
              <Switch
                checked={settings.sendUpdateForm}
                checkedChildren='ON'
                unCheckedChildren='OFF'
                onChange={(checked: boolean) =>
                  setSettings({ ...settings, sendUpdateForm: checked })
                }
              />
              <div className='eligibility-settings-modal__form-switch-text'>
                When a "Not Eligible" status is detected, automatically send a
                patient the Insurance Update form.
              </div>
            </Col>
          </div>
          <div className='eligibility-settings-modal__form-settings'>
            Text/Email Settings
          </div>
          <div className='eligibility-settings-modal__form-settings-text'>
            Please choose the times of day to send Text Messages and Emails on
            your behalf.
          </div>
          <div>
            <Col className='eligibility-settings-modal__form-settings-time'>
              <div style={{ color: 'grey' }}>From</div>
              <Dropdown
                overlay={menuStart}
                trigger={['click']}
                placement='bottom'
                disabled={!settings.sendUpdateForm}
                className={`eligibility-settings-modal__form-settings-dropdown--${
                  settings.sendUpdateForm ? 'enabled' : 'disabled'
                }`}
              >
                <Button
                  className='eligibility-settings-modal__form-settings-time-button'
                  label={getHourLabel(settings.startHour, false)}
                  type='default'
                  style={{ width: '6em', height: '2em' }}
                >
                  <DownOutlined
                    style={{ fontSize: '0.65rem', paddingLeft: '0.75em' }}
                  />
                </Button>
              </Dropdown>
              <div style={{ color: 'grey' }}>To</div>
              <Dropdown
                overlay={menuEnd}
                trigger={['click']}
                placement='bottom'
                disabled={!settings.sendUpdateForm}
                className={`eligibility-settings-modal__form-settings-dropdown--${
                  settings.sendUpdateForm ? 'enabled' : 'disabled'
                }`}
              >
                <Button
                  className='eligibility-settings-modal__form-settings-time-button'
                  label={getHourLabel(settings.endHour, true)}
                  type='default'
                  style={{ width: '6em', height: '2em' }}
                >
                  <DownOutlined
                    style={{ fontSize: '0.65rem', paddingLeft: '0.75em' }}
                  />
                </Button>
              </Dropdown>
            </Col>
          </div>
          <div>
            <Col
              className='eligibility-settings-modal__form-settings-timezone'
              style={{ color: 'grey' }}
            >
              Time zone {frankenDate}.
            </Col>
          </div>
          <Divider />
          <div>
            <p className='eligibility-settings-modal__form-section-title'>
              Patient Message
            </p>
            <Group
              className='eligibility-settings-modal__form-message-content'
              onChange={(e) =>
                setSettings({ ...settings, messageType: e.target.value })
              }
              value={settings.messageType}
              disabled={!settings.sendUpdateForm}
            >
              <Radio
                value='DEFAULT'
                className='mb-050'
              >
                Default
              </Radio>
              <TextArea
                rows={2}
                value={defaultMessageText}
                disabled
              />
              <Radio
                value='CUSTOM'
                className='mb-050'
              >
                Custom Message
              </Radio>
              <TextArea
                rows={2}
                value={settings.customMessageText}
                disabled={
                  !settings.sendUpdateForm || settings.messageType !== 'CUSTOM'
                }
                onChange={(e) => handleUpdateCustomMessage(e)}
                ref={customMessageRef}
                status={
                  getCurrentLength(settings.customMessageText) > MAX_LENGTH &&
                  'error'
                }
              />
            </Group>
            {getCurrentLength(settings.customMessageText) > MAX_LENGTH && (
              <p className='eligibility-settings-modal__form--error'>
                Messages are limited to {MAX_LENGTH} characters in length.
              </p>
            )}
            <div className='eligibility-settings-modal__form-message-controls'>
              <Button
                label='Patient Name'
                icon={<PlusOutlined />}
                onClick={handleAddPatientName}
                disabled={
                  !settings.sendUpdateForm || settings.messageType !== 'CUSTOM'
                }
              />
              <p className='eligibility-settings-modal__form-message-controls char-count'>{`${getRemainingCharacters(
                settings.customMessageText,
              )} characters remaining`}</p>
            </div>
          </div>
          <Divider />
          <div>
            <p className='eligibility-settings-modal__form-section-title'>
              Delivery Method
            </p>
            <p className='fs-0875'>
              Select the default delivery method when sending patient update
              forms.
            </p>
            <Group
              className='eligibility-settings-modal__form-delivery-method'
              onChange={handleUpdateDeliveryMethod}
              value={settings.deliveryMethod}
              disabled={!settings.sendUpdateForm}
            >
              <Radio
                value='TEXT_AND_EMAIL'
                disabled={lbsStatus !== 2}
              >
                Send both text message & email
              </Radio>
              <Radio
                value='TEXT'
                disabled={lbsStatus !== 2}
              >
                Send text message only
              </Radio>
              <Radio value='EMAIL'>Send email message only</Radio>
            </Group>
          </div>
        </section>
        {lbsStatus !== 2 && (
          <div className='eligibility-settings-modal--lbs'>
            <img
              alt='SMS Error'
              src={errorOutline}
              className='eligibility-settings-modal--lbs__icon'
            />
            <div className='fs-0875'>
              <p className='eligibility-settings-modal--lbs__title'>
                SMS Unavailable
              </p>
              <p className='mt-025 mb-050'>
                Due to SMS regulations, you must successfully verify your office
                as a legal business entity by supplying your information in the
                My Practice Section.
              </p>
              <Button>
                <a
                  className='eligibility-settings-modal--lbs__button'
                  href='/Practice/PracticeInfo'
                  target='_blank'
                  rel='noreferrer'
                >
                  Take me there!
                </a>
              </Button>
            </div>
          </div>
        )}
      </Spin>
    </Modal>
  )
}

export default EligibilitySettingsModal
