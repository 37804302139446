import { ObservableObject } from '@legendapp/state'
import { FC } from 'react'

import { EligibilityResponseStateType } from '../utilities/eligibilityTyping'
import BenefitsCard from './benefitsCard'
import StructuredViewBenefitsGrid from './structuredViewBenefitsGrid'

import './structuredViewBenefits.scss'

type StructuredViewBenefitsProps = {
  state: ObservableObject<EligibilityResponseStateType>
}

const StructuredViewBenefits: FC<StructuredViewBenefitsProps> = ({ state }) => {
  return (
    <section className='structured-benefits'>
      <header className='structured-benefits__header'>
        <p className='structured-benefits__header--bold'>Benefits</p>
        <p className='structured-benefits__header--light'>
          The data displayed relates to the network selection above.
        </p>
      </header>
      <div className='structured-benefits__cards'>
        <BenefitsCard
          cardType='Deductible'
          state={state}
        />
        <BenefitsCard
          cardType='Maximums'
          state={state}
        />
        <BenefitsCard
          cardType='Ortho'
          state={state}
        />
      </div>
      {state?.currentBenefits?.use() && (
        <StructuredViewBenefitsGrid state={state} />
      )}
    </section>
  )
}

export default StructuredViewBenefits
