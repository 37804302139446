import { ObservableObject } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { useQuery } from '@tanstack/react-query'
import { endOfDay, startOfDay, sub } from 'date-fns'
import { FC } from 'react'

import {
  DataTable,
  DataTableConfig,
  DataTableDateRange,
  DataTableFilterBy,
} from 'ui'
import { LogError } from 'utils'

import {
  BillingStatements,
  StatementsQueryRequest,
} from 'trellis:api/statements/statements-client'
import { GetStatements } from 'trellis:api/statements/statementsApi'
import { Errors } from 'trellis:constants/errors'
import GlobalState from 'trellis:state/globalState'
import { showMessage } from 'trellis:utilities/general'

import {
  billingStatementsColumns,
  getBillingStatementsDateRanges,
  getFormattedDateRangeString,
  getQueryConfig,
  StatementfilterOptions,
} from '../utils/billing-statements-helpers'

type BillingStatementsTableProps = {
  downloadTableRequest$: ObservableObject<StatementsQueryRequest>
}

export const BillingStatementsTable: FC<BillingStatementsTableProps> = observer(
  ({ downloadTableRequest$ }) => {
    const data$ = useObservable<BillingStatements[]>([])
    // TODO: persist the config so filter/sort state
    // TODO: maybe retrieve initial data via loader
    const dataTableConfig$ = useObservable<DataTableConfig<BillingStatements>>({
      columns: billingStatementsColumns,
      pagination: {
        currentPage: 1,
        dataTotal: 0,
        pageLimit: 50,
      },
      sortBy: {
        dataField: 'StatementDate',
        value: 'asc',
      },
      filters: {
        StatementDate: getFormattedDateRangeString(
          startOfDay(sub(new Date(), { days: 30 })),
          endOfDay(new Date()),
        ),
      },
    })

    const { data, error, isError, isPending } = useQuery({
      queryKey: ['billingStatementsData', dataTableConfig$.get()],
      queryFn: () =>
        GetStatements(GlobalState.Auth.peek(), {
          Filters: dataTableConfig$.filters.peek(),
          Range: dataTableConfig$.pagination.pageLimit.peek(),
          Offset:
            (dataTableConfig$.pagination.currentPage.peek() - 1) *
            dataTableConfig$.pagination.pageLimit.peek(),
          SearchText: dataTableConfig$.searchText.peek(),
          SortColumn: dataTableConfig$.sortBy.dataField.peek(),
          SortDirection: dataTableConfig$.sortBy.value.peek(),
        }),
    })

    if (data) {
      data$.set(data.data.Statements)
      dataTableConfig$.pagination.dataTotal.set(data.data.FilteredStatments)
      downloadTableRequest$.set(getQueryConfig(dataTableConfig$))
    }

    if (isError) {
      LogError(error)
      showMessage(Errors.getBillingStatementsError)
    }

    const dateRange$ = useObservable<DataTableDateRange>({
      dataField: 'StatementDate',
      options: getBillingStatementsDateRanges(),
      defaultRange: [
        startOfDay(sub(new Date(), { days: 30 })),
        endOfDay(new Date()),
      ],
    })

    return (
      <DataTable<BillingStatements>
        config$={dataTableConfig$}
        data$={data$}
        dateRange$={dateRange$}
        filterBy={StatementfilterOptions}
        isLoading={isPending}
        searchPlaceholder='Search by Name or Account #'
        shouldFillViewport={true}
      />
    )
  },
)
