import {
  DownloadOutlined,
  InsertRowRightOutlined,
  PrinterOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { Tooltip } from 'antd'
import { Dispatch, FC, SetStateAction } from 'react'

import { SearchBar, SearchStates } from 'ui'

import {
  refreshSentAttachmentGrid,
  refreshUnsentAttachmentGrid,
} from '../_attachmentGridServices'
import AttachmentStatusFilter from '../_AttachmentStatusFilter'
import { AttachmentStatusFilters } from '../../AttachmentsTypes'
import { useAttachmentsContext } from '../../Context/AttachmentsContext'

import './AttachmentsGridToolbar.scss'

interface AttachmentsGridToolbarProps {
  downloadTable: () => void
  filters: AttachmentStatusFilters
  printTable: () => void
  searchInput: SearchStates
  sentDates: [Date, Date]
  setFilters: Dispatch<SetStateAction<AttachmentStatusFilters>>
  setSearchInput: Dispatch<SetStateAction<SearchStates>>
}

const AttachmentsGridToolbar: FC<AttachmentsGridToolbarProps> = ({
  downloadTable,
  filters,
  printTable,
  searchInput,
  sentDates,
  setFilters,
  setSearchInput,
}) => {
  const {
    activeTab,
    authentication,
    facilityId,
    gridLoading,
    setAttachments,
    setGridLoading,
    setSentAttachments,
    setShowingSettings,
    getSortedAttachments,
  } = useAttachmentsContext()

  const onGridRefresh = async () => {
    setGridLoading(true)
    activeTab === 'Unsent'
      ? await refreshUnsentAttachmentGrid(
          authentication,
          facilityId,
          setAttachments,
          getSortedAttachments,
        )
      : await refreshSentAttachmentGrid(
          authentication,
          facilityId,
          setSentAttachments,
          sentDates,
          getSortedAttachments,
        )
    setGridLoading(false)
  }

  return (
    <section className='sa-toolbar__actions'>
      {activeTab === 'Unsent' && (
        <AttachmentStatusFilter
          filters={filters}
          setFilters={setFilters}
        />
      )}
      {activeTab === 'Unsent' && (
        <SearchBar
          disabled={gridLoading}
          setState={setSearchInput}
          state={searchInput}
        />
      )}
      <section className='flex items-center gap-100'>
        <Tooltip title='Update'>
          <SyncOutlined
            className='attachments-grid-toolbar__action-icon'
            onClick={onGridRefresh}
          />
        </Tooltip>
        <Tooltip title='Print'>
          <PrinterOutlined
            className='attachments-grid-toolbar__action-icon'
            onClick={printTable}
          />
        </Tooltip>
        <Tooltip title='Download'>
          <DownloadOutlined
            className='attachments-grid-toolbar__action-icon'
            onClick={downloadTable}
          />
        </Tooltip>
        <Tooltip title='Columns'>
          <InsertRowRightOutlined
            className='attachments-grid-toolbar__action-icon'
            onClick={() => setShowingSettings(true)}
          />
        </Tooltip>
      </section>
    </section>
  )
}

export default AttachmentsGridToolbar
