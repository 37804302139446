import { Avatar } from 'antd'
import React, { FC } from 'react'

import { ClaimErrorType } from '../../claimTypes'

type ClaimTabType = {
  label: string
  possibleErrors: ClaimErrorType[] | number
  isAttachmentRequirementsIgnored?: boolean
  isAttachmentRequirementsIgnoredLoaded?: boolean
}

const ClaimTab: FC<ClaimTabType> = ({
  label,
  possibleErrors,
  isAttachmentRequirementsIgnored = false,
  isAttachmentRequirementsIgnoredLoaded = true,
}) => {
  const normalizedError: number =
    typeof possibleErrors === 'number' ? possibleErrors : possibleErrors?.length

  return (
    <>
      <span>{label}</span>
      {isAttachmentRequirementsIgnoredLoaded &&
        !isAttachmentRequirementsIgnored &&
        normalizedError > 0 && (
          <Avatar
            size='small'
            className='form-error-count'
          >
            {normalizedError}
          </Avatar>
        )}
    </>
  )
}

export default ClaimTab
