import {
  EyeOutlined,
  FolderViewOutlined,
  ReloadOutlined,
  SendOutlined,
} from '@ant-design/icons'

import { useEligibilityContext } from '../../../shared/context/EligibilityContext'
import { usePatientEligibilityDetailContext } from '../../../shared/context/PatientEligibilityDetailContext'
import {
  EligibilityData,
  handleEligibilityResponse,
  handleSendUpdateForm,
  handleVerify,
} from '../utilities/eligibilityRowActions'

import './EligibilityRowActionsMenu.scss'

import { useComputed } from '@legendapp/state/react'

import {
  ActionItem,
  RowActionsMenu,
} from 'trellis:components/RowActionsMenu/RowActionsMenu'

import GlobalState, { LDFlags$ } from 'trellis:state/globalState'

interface EligibilityRowActionsMenuProps<TableItem> {
  index: number
  tableItem: TableItem
}

const EligibilityRowActionsMenu = <TableItem extends EligibilityData>({
  index,
  tableItem,
}: EligibilityRowActionsMenuProps<TableItem>) => {
  const flags = LDFlags$.get()

  const showSendUpdateFormAction$ = useComputed(
    () =>
      !GlobalState.ActiveServices.DENTAL_INTEL.get() &&
      GlobalState.ActiveServices.TRELLIS_CLAIMS.get(),
  )

  const practiceDetails = GlobalState.PracticeInfo.get()
  
  const {
    participatingCarriers,
    providers,
    setRequiresTableRefresh,
    setShowEligibilityResponseModal,
    setShowPatientUpdateFormModal,
    setVerificationResponse,
    isActionCalledFromTable$
  } = useEligibilityContext()
  const {
    formatPatientResponse,
    handleGetPatient,
    patient,
    setPatient,
    setShowingPatientResponseModal,
    setPatientEligibilityResponse,
  } = usePatientEligibilityDetailContext()

  const actions: ActionItem[] = [
    {
      clickHandler: () => handleGetPatient(tableItem),
      icon: <EyeOutlined />,
      label: 'Patient Info',
      isVisible: true,
    },
    {
      clickHandler: () =>
        handleEligibilityResponse(
          GlobalState.Auth.peek(),
          formatPatientResponse,
          patient,
          tableItem.PatientId,
          participatingCarriers,
          practiceDetails,
          providers,
          setPatient,
          setShowEligibilityResponseModal,
          setShowingPatientResponseModal,
          setVerificationResponse,
          tableItem,
          setPatientEligibilityResponse,
        ),
      icon: <FolderViewOutlined />,
      label: 'View Response',
      isVisible:
        !tableItem.HistoryId ||
        !['Pending Patient Response', 'Unverified', null].includes(tableItem.Status),
    },
    {
      clickHandler: () =>
        handleSendUpdateForm(
          GlobalState.Auth.peek(),
          patient,
          tableItem.PatientId,
          participatingCarriers,
          practiceDetails,
          providers,
          setPatient,
          setShowPatientUpdateFormModal,
        ),
      icon: <SendOutlined />,
      label: 'Send Update Form',
      requiresTableRefresh: true,
      isVisible:
        showSendUpdateFormAction$.get() &&
        ['Failed', 'Not Eligible'].includes(tableItem.Status),
    },
    {
      clickHandler: () =>
        handleVerify(
          GlobalState.Auth.peek(),
          flags.operaVynesyncAutomatedeligibility,
          patient,
          tableItem.PatientId,
          participatingCarriers,
          practiceDetails,
          providers,
          setPatient,
          setRequiresTableRefresh,
          setShowEligibilityResponseModal,
          setVerificationResponse,
        ),
      icon: <ReloadOutlined />,
      label: 'Verify',
      requiresTableRefresh: true,
      isVisible: true,
    },
  ]

  return (
    <RowActionsMenu
      index={index}
      actions={actions}
      isActionCalledFromTable$={isActionCalledFromTable$}
    />
  )
}

export default EligibilityRowActionsMenu
