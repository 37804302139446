import { Button, Col, Form, Modal, Row, Tooltip } from 'antd'
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import ReactToPrint from 'react-to-print'

import { FormSwitch } from '../../../_siteWide/form/formSwitch'
import { Genders } from '../../../../constants/general'
import { useEligibilityContext } from '../../shared/context/EligibilityContext'
import { usePatientEligibilityDetailContext } from '../../shared/context/PatientEligibilityDetailContext'
import EligibilityResponse from '../../shared/EligibilityResponse'

import '../../shared/styles/_patient-update-modals.scss'

interface PatientUpdateResponseModalProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const PatientUpdateResponseModal: FC<PatientUpdateResponseModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { verificationResponse } = useEligibilityContext()
  const {
    patientResponse,
    clearPatientResponseData,
    updatePatientWithPatientResponse,
  } = usePatientEligibilityDetailContext()

  const [patientGender, setPatientGender] = useState('')
  const [subscriberGender, setSubscriberGender] = useState('')
  const [verifying, setVerifying] = useState<boolean>(false)

  const printRef = useRef(null)
  const isApprovedCarrier = patientResponse?.CARRIER_NAME !== 'Other'

  useEffect(() => {
    if (patientResponse?.PAT_SEX) {
      const gender = Genders.find(
        (gender) => gender.value === patientResponse?.PAT_SEX,
      )
      gender ? setPatientGender(gender.text) : setPatientGender(Genders[2].text)
    }
    if (patientResponse?.SUB_SEX) {
      const gender = Genders.find(
        (gender) => gender.value === patientResponse?.SUB_SEX,
      )
      gender
        ? setSubscriberGender(gender.text)
        : setSubscriberGender(Genders[2].text)
    }
  }, [patientResponse])

  const handleCancel = () => {
    setIsOpen(false)
    clearPatientResponseData()
  }

  const handleVerify = async () => {
    setVerifying(true)
    await updatePatientWithPatientResponse()
    setVerifying(false)
  }

  const modalFooter = isOpen && (
    <div key='modal-footer'>
      <ReactToPrint
        key='reactToPrint'
        trigger={() => (
          <Button
            key='print'
            type='default'
          >
            Print
          </Button>
        )}
        content={() => printRef.current}
      />
      <Button
        key='submit'
        type='primary'
        onClick={handleVerify}
        loading={verifying}
        disabled={!isApprovedCarrier}
      >
        Verify
      </Button>
    </div>
  )

  return (
    <Modal
      footer={!verificationResponse ? [modalFooter] : null}
      onCancel={handleCancel}
      title={
        !verificationResponse ? 'Patient Response' : 'Patient Verification'
      }
      visible={isOpen}
      width='fit-content'
    >
      {!verificationResponse ? (
        <div ref={(node) => (printRef.current = node)}>
          <Form className='patient-update-forms'>
            <Row
              gutter={[8, 16]}
              className='row-margin'
            >
              <Col span={24}>
                <div className='group-title'>Patient</div>
              </Col>
              <Col span={10}>
                <div className='disabled-input-label'>Last</div>
                <div className='disabled-input-body'>
                  {patientResponse?.PAT_LAST_NAME}
                </div>
              </Col>
              <Col span={8}>
                <div className='disabled-input-label'>First</div>
                <div className='disabled-input-body'>
                  {patientResponse?.PAT_FIRST_NAME}
                </div>
              </Col>
              <Col span={6}>
                <div className='disabled-input-label'>Middle</div>
                <div className='disabled-input-body'>
                  {patientResponse?.PAT_MID_NAME}
                </div>
              </Col>
              <Col span={8}>
                <div className='disabled-input-label'>Suffix</div>
                <div className='disabled-input-body'>
                  {patientResponse?.PAT_SUFFIX}
                </div>
              </Col>
              <Col span={8}>
                <div className='disabled-input-label'>Birthdate</div>
                <div className='disabled-input-body'>
                  {patientResponse?.PAT_DOB}
                </div>
              </Col>
              <Col span={8}>
                <div className='disabled-input-label'>Gender</div>
                <div className='disabled-input-body'>{patientGender}</div>
              </Col>
            </Row>
            <Row
              gutter={[8, 16]}
              className='row-margin'
            >
              <Col span={24}>
                <div className='group-title'>Subscriber</div>
              </Col>
              <Col
                span={24}
                className='subscriber-row'
              >
                <div className='subscriber-label'>Patient is Subscriber</div>
                <FormSwitch
                  name='PT_IS_SUBSCRIBER'
                  checked={patientResponse?.PT_IS_SUBSCRIBER}
                  disabled
                />
              </Col>
              {!patientResponse?.PT_IS_SUBSCRIBER ? (
                <>
                  <Col span={10}>
                    <div className='disabled-input-label'>Last</div>
                    <div className='disabled-input-body'>
                      {patientResponse?.SUB_LAST_NAME}
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className='disabled-input-label'>First</div>
                    <div className='disabled-input-body'>
                      {patientResponse?.SUB_FIRST_NAME}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className='disabled-input-label'>Middle</div>
                    <div className='disabled-input-body'>
                      {patientResponse?.SUB_MID_NAME}
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className='disabled-input-label'>Suffix</div>
                    <div className='disabled-input-body'>
                      {patientResponse?.SUB_SUFFIX}
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className='disabled-input-label'>Birthdate</div>
                    <div className='disabled-input-body'>
                      {patientResponse?.SUB_DOB}
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className='disabled-input-label'>Gender</div>
                    <div className='disabled-input-body'>
                      {subscriberGender}
                    </div>
                  </Col>
                </>
              ) : null}
            </Row>
            <Row
              gutter={[8, 16]}
              className='row-margin'
            >
              <Col span={24}>
                <div className='group-title'>Insurance Company</div>
              </Col>
              <Col span={12}>
                <div className='disabled-input-label'>Carrier</div>

                {isApprovedCarrier ? (
                  <div className='disabled-input-body'>
                    {patientResponse?.CARRIER_NAME}
                  </div>
                ) : (
                  <Tooltip
                    placement='bottomLeft'
                    title='Carrier not yet supported'
                  >
                    <div className='disabled-input-body'>
                      {patientResponse?.CARRIER_NAME}
                    </div>
                    <div className='disabled-input-other-insurance'>
                      {patientResponse?.OTHER_INSURANCE}
                    </div>
                  </Tooltip>
                )}
              </Col>
              <Col span={6}>
                <div className='disabled-input-label'>Group #</div>
                <div className='disabled-input-body'>
                  {patientResponse?.GROUP_NUMBER}
                </div>
              </Col>
              <Col span={6}>
                <div className='disabled-input-label'>Subscriber ID</div>
                <div className='disabled-input-body'>
                  {patientResponse?.SUB_ID}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <EligibilityResponse response={verificationResponse} />
      )}
    </Modal>
  )
}

export default PatientUpdateResponseModal
