import { Col, Dropdown, Menu, Row } from 'antd'
import React, { FC } from 'react'

import { optionType } from './dropdownOptions'

import './imageTypeDropdown.scss'

import { DownOutlined } from '@ant-design/icons'

import { Labels } from '../../../../../constants/labels'

type ImageDropDownPropsBasic = {
  activeImageTypeOption: string
  imageTypeChanged: (type: optionType) => void
  imageOptions: optionType[]
  otherOptions: optionType[]
  dropdownLabel?: string
}

const ImageTypeDropDownBasic: FC<ImageDropDownPropsBasic> = ({
  activeImageTypeOption,
  imageTypeChanged,
  imageOptions,
  otherOptions,
  dropdownLabel,
}) => {
  const menuImageOptions = imageOptions.map((option) => ({
    key: option.imageTypeId,
    label: (
      <div onClick={() => imageTypeChanged(option)}>{option.imageTypeName}</div>
    ),
  }))

  const menuOtherOptions = otherOptions.map((option) => ({
    key: option.imageTypeId,
    label: (
      <a onClick={() => imageTypeChanged(option)}>{option.imageTypeName}</a>
    ),
  }))

  const menu = (
    <Menu
      className='image-type-menu'
      items={[
        {
          key: 'images',
          label: 'Images',
          children: menuImageOptions,
        },
        {
          key: 'otherType',
          label: 'Other Type',
          children: menuOtherOptions,
        },
      ]}
    />
  )

  const isDefaultOption =
    activeImageTypeOption === Labels.attachmentDefaultImageDropdownText
  return (
    <Row className='image-type-container'>
      <Col span={24}>
        <Dropdown
          overlayStyle={{ borderRadius: 0 }}
          overlay={menu}
        >
          <a
            className={`image-type__dropdown ${
              isDefaultOption ? `image-type__dropdown--default` : ''
            }`}
          >
            <span>{activeImageTypeOption} &nbsp;</span>
            <DownOutlined />
          </a>
        </Dropdown>
      </Col>
    </Row>
  )
}

export default ImageTypeDropDownBasic
