import { endOfDay, format, startOfDay, subDays } from 'date-fns'
import { Dispatch, SetStateAction } from 'react'

import {
  AttachmentListItem,
  SentAttachmentListItem,
  SentAttachmentsRequest,
} from '../../../api/attachment-standalone/attachment-standalone-client'
import {
  DeleteAttachment,
  GetAttachmentList,
  GetSentAttachmentList,
  HoldAttachment,
  SendingAttachment,
} from '../../../api/attachment-standalone/attachmentStandaloneApi'
import { IAuthenticatedApiModel } from '../../../utilities/api'
import { asyncForEach, showMessage } from '../../../utilities/general'

export const sendSelectedAttachments = async (
  authentication: IAuthenticatedApiModel,
  selectedAttachmentIds: number[],
) => {
  await asyncForEach(selectedAttachmentIds, async (id: number) => {
    try {
      await SendingAttachment(authentication, id)

      if (selectedAttachmentIds.length > 1)
        showMessage(
          'Attachments with a status of Ready were successfully sent',
          'success',
        )
      else showMessage('Selected attachment was successfully sent', 'success')
    } catch {
      showMessage(`Failed to send attachment with ID: ${id}`)
    }
  })
}

export const holdSelectedAttachments = async (
  authentication: IAuthenticatedApiModel,
  selectedAttachmentIds: number[],
) => {
  await asyncForEach(selectedAttachmentIds, async (id: number) => {
    try {
      await HoldAttachment(authentication, id)

      if (selectedAttachmentIds.length > 1)
        showMessage(
          'Attachments were successfully set with a status of Hold',
          'success',
        )
      else
        showMessage(
          'Selected attachment was successfully set with a status of Hold',
          'success',
        )
    } catch {
      showMessage(`Failed to hold attachment with ID: ${id}`)
    }
  })
}

export const deleteSelectedAttachments = async (
  authentication: IAuthenticatedApiModel,
  selectedAttachmentIds: number[],
) => {
  await asyncForEach(selectedAttachmentIds, async (id: number) => {
    try {
      await DeleteAttachment(authentication, id)

      if (selectedAttachmentIds.length > 1)
        showMessage('Attachments were successfully deleted', 'success')
      else
        showMessage('Selected attachment was successfully deleted', 'success')
    } catch {
      showMessage(`Failed to delete attachment with ID: ${id}`)
    }
  })
}

export const refreshUnsentAttachmentGrid = async (
  authentication: IAuthenticatedApiModel,
  facilityId: string,
  setAttachments: Dispatch<SetStateAction<AttachmentListItem[]>>,
  getSortedAttachments: (
    gridType: 'unsent' | 'sent',
    data: AttachmentListItem[] | SentAttachmentListItem[],
  ) => AttachmentListItem[] | SentAttachmentListItem[],
) => {
  try {
    const { data } = await GetAttachmentList(authentication, facilityId)
    if (data) {
      // Make sure any existing sort in the unsent grid is maintained
      // By default, the existing sort order is lost in some cases
      const sortedAttachments = getSortedAttachments(
        'unsent',
        data,
      ) as AttachmentListItem[]
      setAttachments(sortedAttachments)
    }
  } catch (e: any) {
    showMessage(
      'Failed to retreive unsent attachments. Please refresh the page or try again.',
    )
    throw new Error(e.message)
  }
}

export const refreshSentAttachmentGrid = async (
  authentication: IAuthenticatedApiModel,
  facilityId: string,
  setSentAttachments: Dispatch<SetStateAction<SentAttachmentListItem[]>>,
  sentDates: [Date, Date],
  getSortedAttachments: (
    gridType: 'unsent' | 'sent',
    data: AttachmentListItem[] | SentAttachmentListItem[],
  ) => AttachmentListItem[] | SentAttachmentListItem[],
) => {
  const request: SentAttachmentsRequest = {
    sentAfter: format(
      sentDates[0] || startOfDay(subDays(new Date(), 14)),
      'yyyy-MM-dd',
    ),
    sentBefore: format(sentDates[1] || endOfDay(new Date()), 'yyyy-MM-dd'),
  }

  try {
    const { data } = await GetSentAttachmentList(
      authentication,
      facilityId,
      request,
    )
    if (data) {
      // Make sure any existing sort in the unsent grid is maintained
      // By default, the existing sort order is lost in some cases
      const sortedAttachments = getSortedAttachments(
        'sent',
        data,
      ) as SentAttachmentListItem[]
      setSentAttachments(sortedAttachments)
    }
  } catch (e: any) {
    showMessage(
      'Failed to retreive sent attachments. Please refresh the page or try again.',
    )
    throw new Error(e.message)
  }
}
