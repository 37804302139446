import { Col, Row, Table } from 'antd'
import React, { FC, useEffect, useState } from 'react'

import { CarrierDto, Recipient } from 'trellis:api/carrier/carrier-client'
import { GetNeaCarrierRequirements } from 'trellis:api/carrier/carrierApi'
import { IAuthenticatedApiModel } from 'trellis:utilities/api'
import { useFuse } from 'trellis:utilities/fuzzySearch/useFuse'
import { showMessage } from 'trellis:utilities/general'

import CarrierAttachmentSearch from './_CarrierAttachmentSearch'
import { ResultsDisplayType, TableDataType } from './_carrierAttachmentTypes'
import {
  getColumns,
  transformRecipientsData,
} from './_carrierAttachmentUtilities'

import './CarrierAttachment.scss'

type CarrierAttachmentProps = {
  carrier: CarrierDto
  authentication: IAuthenticatedApiModel
}

const checkForRecipients = (Recipients: Recipient[]) => {
  return (
    Recipients.length > 0 &&
    Recipients[0]?.ProcedureCodeRequirements.length > 0 &&
    !(
      Recipients[0]?.ProcedureCodeRequirements.length === 1 &&
      Recipients[0]?.ProcedureCodeRequirements[0].ProcedureCode.charAt(
        0,
      ).toUpperCase() != 'D'
    )
  )
}

const CarrierAttachment: FC<CarrierAttachmentProps> = ({
  authentication,
  carrier,
}) => {
  const [tableData, setTableData] = useState<TableDataType[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [resultsDisplay, setResultsDisplay] =
    useState<ResultsDisplayType>('SearchResults')
  const [searchSelect, setSearchSelect] = useState<string | null>(null)

  const filterListCallBack = (data: TableDataType[]) => {
    let list: TableDataType[] = JSON.parse(JSON.stringify(data))

    if (searchSelect === 'All') {
      return list
    }

    if (searchSelect) {
      const searchRange: [number, number] = searchSelect
        .replaceAll('D', '')
        .split('-')
        .map((value) => parseInt(value)) as [number, number]

      list = list.filter((item) => {
        const dataNumber = parseInt(item.code.replace('D', ''))
        return dataNumber >= searchRange[0] && dataNumber <= searchRange[1]
      })
    }

    return list
  }

  const { hitItems, hits, onSearch } = useFuse<TableDataType>({
    data: tableData,
    filterCallBack: filterListCallBack,
    matchAllOnEmptyQuery: true,
    options: { keys: ['code', 'description', 'requirements', 'comments'] },
  })

  const getNeaCarrierRequirements = async (
    authentication: IAuthenticatedApiModel,
    neaMasterId: string,
  ) => {
    setLoading(true)

    await GetNeaCarrierRequirements(authentication, parseInt(neaMasterId))
      .then((res) => {
        const { Recipients } = res.data
        if (checkForRecipients(Recipients)) {
          const transformedTableData: TableDataType[] = transformRecipientsData(
            Recipients[0].ProcedureCodeRequirements,
          )
          setTableData(transformedTableData)
          setResultsDisplay('SearchResults')
        } else setResultsDisplay('NoAttachmentService')
      })
      .catch((err) => {
        showMessage('Issue Loading NEA Requirements')
        throw Error(err)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!carrier.NeaMasterId) setResultsDisplay('NoAttachmentService')
    else getNeaCarrierRequirements(authentication, carrier.NeaMasterId)
  }, [])

  return (
    <Col className='section ci-attachment'>
      <Row>
        <Col>
          <h2 className='ci-attachment__attachment-title'>
            Attachment Requirement Lookup
          </h2>
          <div className='ci-attachment__nea-number'>
            <p>NEA Master ID: {carrier.NeaMasterId ?? 'n/a'}</p>
          </div>
        </Col>
      </Row>
      {resultsDisplay !== 'NoAttachmentService' && (
        <Row>
          <Col span={16}>
            <CarrierAttachmentSearch
              data={tableData}
              filterListCallBack={filterListCallBack}
              loading={loading}
              onSearch={onSearch}
              searchSelect={searchSelect}
              setResultsDisplay={setResultsDisplay}
              setSearchSelect={setSearchSelect}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={16}>
          {resultsDisplay === 'SearchResults' && hitItems.length > 0 ? (
            <Table
              className='table--non-smart mt-050'
              columns={getColumns(hits)}
              dataSource={hitItems}
              loading={loading}
              pagination={false}
              rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
              rowKey='code'
            />
          ) : (
            <div
              className={`ci-attachment input-helper ${
                resultsDisplay === 'NoAttachmentService'
                  ? 'input-helper--no-attachments'
                  : ''
              }`}
            >
              {resultsDisplay === 'SearchResults' &&
              !loading &&
              hitItems.length === 0 ? (
                <p>No results found.</p>
              ) : resultsDisplay === 'NoAttachmentService' ? (
                <p>This Carrier has no attachment requirements.</p>
              ) : (
                resultsDisplay === 'NoRange' && (
                  <p>No results for specified range.</p>
                )
              )}
            </div>
          )}
        </Col>
      </Row>
    </Col>
  )
}

export { checkForRecipients }
export default CarrierAttachment
