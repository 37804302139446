import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Col, Form, Radio, Row, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'

import { FormInput } from '../../_siteWide/form/formInput'
import { FormSelect } from '../../_siteWide/form/formSelect'
import { FormSwitch } from '../../_siteWide/form/formSwitch'
import { Loading } from '../../_siteWide/loader/dataDisplay'
import { formatDateString, getPayDateOptions } from '../../../utilities/general'
import { validateZip } from '../../../utilities/validators/zipValidator'
import { useSubmitPaymentContext } from '../context/submitPaymentContext'

import './paymentForm.scss'

export const PaymentForm = () => {
  const {
    loading,
    loadingCards,
    payment,
    setPayment,
    usingSavedCard,
    selectedInvoice,
    savedCards,
    submitting,
    onSubmit,
    toggleSaveCard,
    onCardChange,
    onNewCardChange,
  } = useSubmitPaymentContext()
  const [form] = Form.useForm()

  const hasCards = savedCards?.length
  const [selectedWalletId, setSelectedWalletId] = useState('new')
  const [cardNumIsValid, setCardNumIsValid] = useState(true)
  const [cardDateIsValid, setCardDateIsValid] = useState(true)

  useEffect(() => {
    if (savedCards && usingSavedCard && hasCards) {
      setSelectedWalletId(savedCards[0].WalletID)
    }
  }, [savedCards])

  if (loading || loadingCards || !selectedInvoice) return <Loading />

  form.setFieldsValue({ ...payment })
  const changeCard = (e: any) => {
    const walletId = e.target.value
    setSelectedWalletId(walletId)
    onCardChange(walletId)
  }

  // Necessary because tsep fields can't be directly modified outside tsepHandler
  const resetForm = () => {
    window.localStorage.removeItem('tsep')
    window.location.reload()
  }

  // Necessary because tsep fields can't be directly modified outside tsepHandler
  window.addEventListener('storage', () => {
    setCardNumIsValid(
      window.localStorage.getItem('validCard') !== null
        ? JSON.parse(window.localStorage.getItem('validCard'))
        : true,
    )
    setCardDateIsValid(
      window.localStorage.getItem('validDate') !== null
        ? JSON.parse(window.localStorage.getItem('validDate'))
        : true,
    )
  })

  const savedCardContent = (
    <Radio.Group
      value={selectedWalletId}
      onChange={changeCard}
    >
      <>
        {hasCards &&
          savedCards.map((c: any, i: number) => {
            const display = `${c.Nickname} ending in ${
              c.LastFour
            }, exp ${formatDateString(c.Expiration, 'MM/yyyy')}`
            return (
              <Row key={i}>
                <Radio value={c.WalletID}>{display}</Radio>
              </Row>
            )
          })}
        <Row>
          <Radio
            onChange={onNewCardChange}
            value='new'
          >
            Use New Card
          </Radio>
        </Row>
      </>
    </Radio.Group>
  )

  const toggleVisibility = selectedWalletId !== 'new' ? 'tsep-not-visible' : ''

  return (
    <>
      <input
        id='tseptoken'
        type='hidden'
      />
      <Row
        justify='center'
        className='mt-200'
      >
        <Col
          span={9}
          className='form-detail-section'
        >
          <Form
            form={form}
            onFinish={onSubmit}
          >
            <Row>
              <Col span={24}>
                <div className='page-section'>
                  <div className='page-section__title'>
                    Payment Info
                    <Tooltip title={cardInfo}>
                      <InfoCircleOutlined className='fs-075 pl-050' />
                    </Tooltip>
                  </div>
                  <div className='pb-100'>{savedCardContent}</div>

                  <div
                    className={`tsep-control card ${toggleVisibility}`}
                    id='tsep-cardNumDiv'
                  ></div>
                  <div
                    className={cardNumIsValid ? 'card-valid' : 'card-invalid'}
                  >
                    Valid card and exp date are required
                  </div>
                  <div
                    className={`tsep-control date mt-100 ${toggleVisibility}`}
                    id='tsep-datepickerDiv'
                  ></div>
                  <div
                    className={cardDateIsValid ? 'card-valid' : 'card-invalid'}
                  >
                    Valid card and exp date are required
                  </div>
                  <Col
                    span={12}
                    className={`mt-100 ${toggleVisibility}`}
                  >
                    <FormInput
                      name='Zip'
                      label='Zip'
                      element={payment}
                      setElement={setPayment}
                      initialValue={payment.Zip}
                      value={payment.Zip}
                      section='payment'
                      required={payment.Zip}
                      validator={validateZip}
                    />
                  </Col>
                  <Row className={`save-card-row ${toggleVisibility}`}>
                    <Col>
                      <FormSwitch
                        name='SaveNewCard'
                        checked={payment.SaveNewCard}
                        onChange={toggleSaveCard}
                      />
                    </Col>
                    <Col className='pt-050 pl-100'>
                      Save card for future use?
                    </Col>
                  </Row>
                  <Row className={`${toggleVisibility}`}>
                    <Col span={12}>
                      <FormInput
                        name='CardNickname'
                        label='Nickname'
                        element={payment}
                        setElement={setPayment}
                        initialValue={payment.CardNickname}
                        value={payment.CardNickname}
                        section='payment'
                        required={payment.SaveNewCard}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={6}
                      className='pt-100'
                    >
                      <FormSelect
                        name='PaymentDay'
                        label='Payment Day'
                        element={payment}
                        setElement={setPayment}
                        initialValue={payment.PaymentDay}
                        value={payment.PaymentDay}
                        options={getPayDateOptions()}
                        section='payment'
                        required
                      />
                    </Col>
                    <Col
                      span={6}
                      className='pt-100 pl-150'
                    >
                      <Tooltip title={paymentDateInfo}>
                        <a
                          className='float-left pt-100'
                          href='#'
                        >
                          What's this?
                        </a>
                      </Tooltip>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <SummaryContent balance={selectedInvoice.FormattedBalance} />
            <Row
              justify='end'
              className='pt-200 mb-200'
            >
              <Button onClick={() => resetForm()}>Reset</Button>
              <Button
                type='primary'
                loading={submitting}
                className='ml-100'
                htmlType='submit'
              >
                Submit
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

const SummaryContent = ({ balance }: any) => (
  <>
    <Row>
      <Col span={24}>
        <Row>
          <Col flex={1}>
            <span className='page-section__title'>Payment Methods</span>
          </Col>
          <Col>
            <i className='credit fab fa-cc-visa'></i>
            <i className='credit fab fa-cc-mastercard ml-025'></i>
            <i className='credit fab fa-cc-discover ml-025'></i>
            <i className='credit fab fa-cc-amex ml-025'></i>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <p className='payment-note'>
          Note that it may take 3 - 5 business days for balance changes to be
          processed and to reflect in your balance on RPractice. Make sure and
          check the 'As Of' date if you have made a recent payment.
        </p>
      </Col>
    </Row>
    <Row className='pt-200'>
      <span className='page-section__title'>Summary</span>
    </Row>
    <Row>
      <Col span={24}>
        <Row className='payment-summary-row'>
          <Col flex={1}>Today's Total</Col>
          <Col>{balance ? <b>{balance}</b> : <b>$0.00</b>}</Col>
        </Row>
      </Col>
    </Row>
  </>
)

const paymentDateInfo = (
  <p>
    Payment will be made on the date you select or after the invoicing runs for
    the month, whichever is later (invoicing generally runs on the fifth of the
    month).
  </p>
)

const cardInfo = (
  <p>
    Payment method will be used to pay full balance each month. If there is a
    balance when the card is setup, a transaction for that amount will be
    initiated as part of setup.
  </p>
)
