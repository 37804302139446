import { Tabs } from 'antd'
import { FC, useEffect, useState } from 'react'

import { Button } from 'ui'
import { FormError } from 'utils'

import GlobalState from 'trellis:state/globalState'

import LegalBusinessInfo from './tabs/LegalBusinessInfo/LegalBusinessInfo'
import PracticeInfo from './tabs/PracticeInfo/PracticeInfo'

import './MyPractice.scss'

import { PMSCustomerToken } from './tabs/PMSCustomerToken/PmsCustomerToken'
import { observer, useObserve } from '@legendapp/state/react'

export const MyPractice: FC = () => {
  const legalBusinessStatus = GlobalState.LegalBusinessStatus.get()
  const practiceInfo = GlobalState.PracticeInfo.get()
  const decodedJWT = GlobalState.DecodedJwt.get()
  const [activeTab, setActiveTab] = useState<string>('Practice Info')
  const [formName, setFormName] = useState<string>('practiceInfoForm')
  const [legalBusinessInfoFormErrors, setLegalBusinessInfoFormErrors] =
    useState<FormError[]>([])
  const [practiceInfoFormErrors, setPracticeInfoFormErrors] = useState<
    FormError[]
  >([])
  const [resetPracticeInfoForm, setResetPracticeInfoForm] =
    useState<boolean>(false)
  const [saving, isSaving] = useState<boolean>(false)

  useEffect(() => {
    const hasValidLegalBusinessInfo: boolean =
      legalBusinessStatus?.verificationStatus === 1 ||
      legalBusinessStatus?.verificationStatus === 2
    const hasOperaCompanyId: boolean = !!decodedJWT?.opera_company_id
    const isDI: boolean = decodedJWT?.active_services.includes('DENTAL_INTEL')
    if (!isDI && hasOperaCompanyId && !hasValidLegalBusinessInfo) {
      setFormName('legalBusinessInfoForm')
      setActiveTab('Legal Business Info')
    }
  }, [legalBusinessStatus])

  const handleTabChange = (tabKey: string) => {
    switch (tabKey) {
      case 'Practice Info':
        setFormName('practiceInfoForm')
        break
      case 'Legal Business Info':
        setFormName('legalBusinessInfoForm')
        break
      default:
        break
    }
    setActiveTab(tabKey)
  }

  const items = [
    {
      label: `Practice Info`,
      key: 'Practice Info',
      children: (
        <PracticeInfo
          formErrors={practiceInfoFormErrors}
          isSaving={isSaving}
          resetForm={resetPracticeInfoForm}
          setFormErrors={setPracticeInfoFormErrors}
        />
      ),
    },
    !decodedJWT?.active_services.includes('DENTAL_INTEL') &&
      decodedJWT?.opera_company_id && {
        label: `Legal Business Info`,
        key: 'Legal Business Info',
        children: (
          <LegalBusinessInfo
            formErrors={legalBusinessInfoFormErrors}
            isSaving={isSaving}
            setFormErrors={setLegalBusinessInfoFormErrors}
          />
        ),
      },
      practiceInfo.partnerClaimsSupported && {
      label: 'PMS Customer Token',
      key: 'PMS Customer Token',
      children: (
        <PMSCustomerToken
          data-testId='pms-customer-token-tab'
          practiceInfo={practiceInfo}
        />
      ),
    },
  ]

  return (
    <article className='page-content__container page-content__container--with-page-header'>
      <section className='page-content-header__container'>
        <h1 className='page-content-header__title'>My Practice</h1>
        <div className='page-content-header__button-container'>
          {activeTab === 'Practice Info' && (
            <Button
              className='mr-100'
              label='Reset'
              onClick={() => setResetPracticeInfoForm(true)}
              size='large'
            />
          )}
          {activeTab !== 'PMS Customer Token' && (
            <Button
              form={formName}
              htmlType='submit'
              label='Submit'
              loading={saving}
              size='large'
              type='primary'
            />
          )}
        </div>
      </section>
      <Tabs
        className='pt-100'
        items={items}
        activeKey={activeTab}
        onChange={(key: string) => handleTabChange(key)}
      />
    </article>
  )
}
