import { LaptopOutlined } from '@ant-design/icons'
import { message, Modal } from 'antd'
import React, {
  ClipboardEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react'

import { Button } from 'ui'

import { ImageDataProps } from '../AttachmentsTypes'

import './KeyboardCapture.scss'

const acquisitionType = 'Keyboard-Capture-Trellis-SA'

type KeyboardCaptureProps = {
  setImageDataProps: Dispatch<SetStateAction<ImageDataProps>>
  imageData: ImageDataProps[]
  setImageData: Dispatch<SetStateAction<ImageDataProps[]>>
}

const KeyboardCapture: FC<KeyboardCaptureProps> = ({
  setImageDataProps,
  imageData,
  setImageData,
}) => {
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false)

  const defaultOrientation =
    localStorage.getItem('standalone-attachment-image-orientation') || 'Right'

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    if (visibleDialog && e.clipboardData.files.length) {
      const file = e.clipboardData.files[0]
      if (file.type.indexOf('image') > -1) {
        const rowKey = new Date().getTime().toString()
        const rowId = imageData.length + 1
        const dateTaken = new Date().toString()

        setImageDataProps({
          imageData: file,
          orientation: defaultOrientation,
          imageDataThumbnail: URL.createObjectURL(file),
          acquisitionType,
          rowKey,
          rowId,
          imageChanged: true,
          dateTaken: dateTaken,
        })

        setImageData([
          ...imageData,
          {
            imageData: file,
            orientation: defaultOrientation,
            imageDataThumbnail: URL.createObjectURL(file),
            acquisitionType,
            rowKey,
            rowId,
            imageChanged: true,
            dateTaken: dateTaken,
          },
        ])

        setVisibleDialog(false)
      } else {
        //File is not an image type. type = ', imageData.type
        message.error('File is not an image type: ' + file.name)
      }
    } else if (visibleDialog!) {
      //No file data was found in clipboard
      message.error('No file data was found in clipboard')
    }
  }

  useEffect(() => {
    window.addEventListener('paste', handlePaste as unknown as EventListener)

    return () => {
      window.removeEventListener(
        'paste',
        handlePaste as unknown as EventListener,
      )
    }
  }, [visibleDialog])

  return (
    <>
      <Button
        label='Keyboard Capture'
        icon={<LaptopOutlined />}
        onClick={() => setVisibleDialog(!visibleDialog)}
      />

      {visibleDialog && (
        <Modal
          title={
            <p className='sa-keyboard-capture-modal__title'>Keyboard Capture</p>
          }
          onCancel={() => setVisibleDialog(false)}
          destroyOnClose={true}
          visible={visibleDialog}
          footer={null}
          className={'sa-keyboard-capture-modal'}
        >
          <div className='sa-keyboard-capture-modal__acquisition'>
            <div className='sa-keyboard-capture-modal__acquisition-header'>
              <b>Capture an image using keyboard shortcuts.</b>
            </div>
            <div>
              Upload images using the following steps:
              <ul className='sa-keyboard-capture-modal__list'>
                <li>Click on the window you want to capture.</li>
                <li>
                  Press the buttons, <b>"alt" + "Print Screen."</b>
                </li>
                <li>Return to this window.</li>
                <li>
                  Press the <b>"ctrl" + "v"</b> to upload the image.
                </li>
              </ul>
            </div>
          </div>
          <div className='sa-keyboard-capture-modal__acquisition-note'>
            *Keep this window open when using the capture tool.
          </div>
        </Modal>
      )}
    </>
  )
}

export default KeyboardCapture
