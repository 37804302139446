import { Buffer } from 'buffer'

import axios from 'axios'

import { LogError } from 'utils'

import trellisConfiguration from 'trellis:utilities/config'

import { IAuthenticatedApiModel } from '../../utilities/api'
import { ConvertImageRequest } from '../attachment/attachment-client'
import {
  AttachmentApiFactory,
  AuthenticationApiFactory,
  Configuration,
} from './attachment-standalone-client'
import {
  ApplicationApiFactory,
  Attachment,
  AttachmentSentApiFactory,
  DocumentApiFactory,
  Patient,
  PatientApiFactory,
  PatientAssociationRequest,
  PatientInsuranceRequest,
  PatientsSearchVdsRequest,
  ProviderApiFactory,
  ProviderRequest,
  RecipientApiFactory,
  ScreenCaptureApiFactory,
  SentAttachmentsRequest,
  VdsPatientApiFactory,
} from './attachment-standalone-client/api'

const attachmentWrapperApi: string =
  trellisConfiguration.attachmentWrapper_apiUrl
// Authentication
export const GetToken = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
) => {
  return await AuthenticationApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).authenticationTrellisPost(customerId)
}

// Unsent attachments
export const GetAttachmentList = async (
  authentication: IAuthenticatedApiModel,
  facilityId: string,
) => {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).attachmentFacilityFacilityIdGet(facilityId)
}

export const GetAttachment = async (
  authentication: IAuthenticatedApiModel,
  attachmentId: number,
) => {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).attachmentAttachmentIdGet(attachmentId)
}

export const GetAttachmentImage = async (
  authentication: IAuthenticatedApiModel,
  attachmentId: number,
  documentId: number,
  facilityId: string,
) => {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).attachmentAttachmentIdDocumentsDocumentIdGet(
    attachmentId,
    documentId,
    facilityId,
  )
}

export const GetAttachmentRules = async (
  authentication: IAuthenticatedApiModel,
  masterId: number,
) => {
  return await RecipientApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).recipientMasterIdAttachmentRulesGet(masterId)
}

export const CreateAttachment = async (
  authentication: IAuthenticatedApiModel,
  ignoreRequirements: boolean,
  attachment: Attachment,
) => {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).attachmentPost(ignoreRequirements, attachment)
}

export const UpdateAttachment = async (
  authentication: IAuthenticatedApiModel,
  attachmentId: number,
  ignoreRequirements: boolean,
  attachment: Attachment,
) => {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).attachmentAttachmentIdPut(attachmentId, ignoreRequirements, attachment)
}

export const SendingAttachment = async (
  authentication: IAuthenticatedApiModel,
  attachmentId: number,
) => {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).attachmentAttachmentIdSendPost(attachmentId)
}

export const HoldAttachment = async (
  authentication: IAuthenticatedApiModel,
  attachmentId: number,
) => {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).attachmentAttachmentIdStatusPut(attachmentId, 'Hold')
}

export const DeleteAttachment = async (
  authentication: IAuthenticatedApiModel,
  attachmentId: number,
) => {
  return await AttachmentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).attachmentAttachmentIdDelete(attachmentId)
}

export const GetRecipientList = async (
  authentication: IAuthenticatedApiModel,
  facilityId: string,
  isNeaOnly: boolean,
) => {
  return await RecipientApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).recipientListGet(facilityId, isNeaOnly)
}

export const GetRecipient = async (
  authentication: IAuthenticatedApiModel,
  masterId: number,
) => {
  return await RecipientApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).recipientMasterIdGet(masterId)
}

export const GetRecipientMasterId = async (
  authentication: IAuthenticatedApiModel,
  carrierId: string,
) => {
  return await RecipientApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).recipientConvertCarrierIdGet(carrierId)
}

// Sent attachments
export const GetSentAttachmentList = async (
  authentication: IAuthenticatedApiModel,
  facilityId: string,
  request: SentAttachmentsRequest,
) => {
  return await AttachmentSentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).attachmentSentFacilityFacilityIdPost(facilityId, request)
}

export const GetSentAttachment = async (
  authentication: IAuthenticatedApiModel,
  hmfId: string,
) => {
  return await AttachmentSentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).attachmentSentHmfIdGet(parseInt(hmfId))
}

export const GetSentAttachmentImage = async (
  authentication: IAuthenticatedApiModel,
  hmfId: number,
  imageSequence: number,
) => {
  return await AttachmentSentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).attachmentSentHmfIdDocumentsImageSequenceGet(hmfId, imageSequence)
}

export const GetSentAttachmentReceipt = async (
  authentication: IAuthenticatedApiModel,
  hmfId: number,
) => {
  return await AttachmentSentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).attachmentSentHmfIdReceiptGet(hmfId)
}

// Attachment images
export const GetImageTypes = async function (
  authentication: IAuthenticatedApiModel,
) {
  return await ApplicationApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).applicationDocumentCodesGet()
}

export const ScreenCaptureStoragePath = async function (
  authentication: IAuthenticatedApiModel,
  customerId: number,
) {
  return await ScreenCaptureApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).screenCaptureStoragePathGet(customerId)
}

export const ScreenCaptureGetImage = async function (
  imageName: string,
  authentication: IAuthenticatedApiModel,
  customerId: number,
) {
  return (await ScreenCaptureApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).screenCaptureDocumentNameGet(customerId, imageName)) as any
}

export const GetImageFromS3 = async function (presignedS3Url: string) {
  return await axios
    .get(presignedS3Url, {
      responseType: 'arraybuffer',
    })
    .then((response) => Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => {
      LogError(error, 'Failed to download attachment image')
    })
}

export const UploadImageToS3 = async function (
  presignedS3Url: string,
  imageData?: File,
) {
  return await axios
    .put(presignedS3Url, imageData, {
      headers: { 'Content-Type': 'application/octet-stream' },
    })
    .catch((error) => {
      LogError(error, 'Failed to upload attachment image')
    })
}

export const ConvertImageS3Bytes = async (
  request: ConvertImageRequest,
  authentication: IAuthenticatedApiModel,
  customerId: number,
) => {
  return await DocumentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).documentConvertImageS3Post(customerId, request)
}

export const DocumentStoragePath = async function (
  authentication: IAuthenticatedApiModel,
  facilityId: string,
  customerId: number,
) {
  return await DocumentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).documentStoragePathGet(customerId, facilityId)
}

export const DocumentByIdStoragePath = async function (
  authentication: IAuthenticatedApiModel,
  facilityId: string,
  documentId: number,
) {
  return await DocumentApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).documentDocumentIdStoragePathGet(documentId, facilityId)
}

// VDS Patients
export const GetVDSPatients = async function (
  authentication: IAuthenticatedApiModel,
  patientSearchVdsRequest: PatientsSearchVdsRequest,
  customerId: number,
) {
  return await VdsPatientApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).vdsPatientsSearchPost(customerId, patientSearchVdsRequest)
}

// Fast Attach Provider
export const GetFastAttachProvider = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  providerId: number,
) => {
  return await ProviderApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).providerProviderIdGet(providerId, customerId)
}

export const CreateFastAttachProvider = async function (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  trellisProviderId: number,
  providerRequest: ProviderRequest,
) {
  return await ProviderApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).providerPost(customerId, trellisProviderId, providerRequest)
}

export const UpdateFastAttachProvider = async function (
  authentication: IAuthenticatedApiModel,
  providerId: number,
  providerRequest: ProviderRequest,
) {
  return await ProviderApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).providerProviderIdPut(providerId, providerRequest)
}

// Fast Attach Patient
export const GetFastAttachPatient = async (
  authentication: IAuthenticatedApiModel,
  patientId: number,
) => {
  return await PatientApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).patientPatientIdGet(patientId)
}

export const CreateFastAttachPatient = async function (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  facilityId: string,
  patientAssociationRequest: PatientAssociationRequest,
  vdsPatientId: string,
) {
  return await PatientApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).patientPost(customerId, facilityId, patientAssociationRequest, vdsPatientId)
}

export const UpdateFastAttachPatient = async function (
  authentication: IAuthenticatedApiModel,
  patientId: number,
  patient: Patient,
) {
  return await PatientApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).patientPatientIdPut(patientId, patient)
}

export const GetFastAttachPatientInsurance = async (
  authentication: IAuthenticatedApiModel,
  patientId: number,
  insuranceId: number,
) => {
  return await PatientApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).patientPatientIdInsuranceInsuranceIdGet(patientId, insuranceId)
}

export const CreateFastAttachPatientInsurance = async function (
  authentication: IAuthenticatedApiModel,
  patientId: number,
  patientInsuranceRequest: PatientInsuranceRequest,
) {
  return await PatientApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).patientPatientIdInsurancePost(patientId, patientInsuranceRequest)
}

export const UpdateFastAttachPatientInsurance = async function (
  authentication: IAuthenticatedApiModel,
  patientId: number,
  insuranceId: number,
  patientInsuranceRequest: PatientInsuranceRequest,
) {
  return await PatientApiFactory(
    new Configuration({
      basePath: attachmentWrapperApi,
      accessToken: authentication.AccessToken,
    }),
  ).patientPatientIdInsuranceInsuranceIdPut(
    patientId,
    insuranceId,
    patientInsuranceRequest,
  )
}
