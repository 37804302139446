import {
  CalendarOutlined,
  DollarOutlined,
  ScheduleOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Card, Col, Row, Select, Skeleton } from 'antd'
import React, { FC } from 'react'

import { formatDateString } from '../../../../utilities/general'

import '../payment.scss'

import { Invoice } from '../../../../api/billing/billing-client'

const { Option } = Select

interface InvoiceHeaderProps {
  invoice: Invoice
  invoices: Invoice[]
  selectedTin: string
  initialLoad: boolean
  updateSelectedTin: (tin: string) => void
}

export const InvoiceHeader: FC<InvoiceHeaderProps> = ({
  invoice,
  invoices,
  initialLoad,
  selectedTin,
  updateSelectedTin,
}) => {
  const date = formatDateString(invoice?.NextInvoiceDate, 'MM/dd/yyyy')
  const invoiceDate = date === 'N/A' ? 'N/A' : `${date}`
  return (
    <div className='invoice-header'>
      <Row>
        <Col span={6}>
          <Skeleton
            loading={initialLoad}
            avatar
            active
          >
            <Card
              size='small'
              title={
                <span>
                  <UserOutlined className='invoice-icon float-left pr-050' />
                  Account/Tax ID
                </span>
              }
            >
              <Row className='invoice-header-display'>
                <span className='invoice-value'>{invoice?.Tin}</span>
              </Row>
              <Row>
                <Col span={24}>
                  {invoices && invoices.length && (
                    <Select
                      className='full-width'
                      onChange={(tin: string) => updateSelectedTin(tin)}
                      defaultValue={selectedTin}
                    >
                      {invoices.map((t: Invoice, i: number) => (
                        <Option
                          key={i}
                          value={t.Tin}
                        >
                          {t.FormattedTin}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Col>
              </Row>
            </Card>
          </Skeleton>
        </Col>
        <Col
          span={6}
          className='invoice-header-col'
        >
          <Skeleton
            loading={initialLoad}
            avatar
            active
          >
            <Card
              size='small'
              title={
                <span>
                  <DollarOutlined className='invoice-icon float-left pr-050' />
                  Current Balance
                </span>
              }
            >
              <Row className='invoice-header-display'>
                <span className='invoice-value'>
                  {invoice?.FormattedBalance}
                </span>
              </Row>
              <Row className='invoice-detail'>{`As of ${invoiceDate}`}</Row>
            </Card>
          </Skeleton>
        </Col>
        <Col
          span={6}
          className='invoice-header-col'
        >
          <Skeleton
            loading={initialLoad}
            avatar
            active
          >
            <Card
              size='small'
              title={
                <span>
                  <ScheduleOutlined className='invoice-icon float-left pr-050' />
                  Payment Plan
                </span>
              }
            >
              <Row className='invoice-header-display'>
                <span className='invoice-value'>
                  {invoice?.PaymentPlan === 'Non-Recurring'
                    ? invoice?.PaymentPlan
                    : 'Recurring'}
                </span>
              </Row>
              <Row className='invoice-detail'>
                {invoice?.PaymentPlan === 'Non-Recurring' ? '' : 'Monthly'}
              </Row>
            </Card>
          </Skeleton>
        </Col>
        <Col
          span={6}
          className='invoice-header-col'
        >
          <Skeleton
            loading={initialLoad}
            avatar
            active
          >
            <Card
              size='small'
              title={
                <span>
                  <CalendarOutlined className='invoice-icon float-left pr-050' />
                  Next Bill Due Date
                </span>
              }
            >
              <Row className='invoice-header-display'>
                <span className='invoice-value'>{invoiceDate}</span>
              </Row>
              <Row className='invoice-detail'>{invoice?.ProductType}</Row>
            </Card>
          </Skeleton>
        </Col>
      </Row>
    </div>
  )
}
