import {
  IAttachment,
  IAttachmentImage,
  IAttachmentImageS3Save,
  ImageCaptureMethod,
  ImageOrientationType,
} from '../../components/attachment/attachment.interface'
import { IAuthenticatedApiModel } from '../../utilities/api'
import { dataURLtoFile } from '../../utilities/general'
import { AttachmentRequest, ImageResponse } from './attachment-client'
import {
  ClearAttachmentRequirements,
  ConvertImageS3,
  CreateClaimAttachment,
  CreateClaimAttachmentImage,
  DeleteClaimAttachment,
  DeleteClaimAttachmentImage,
  GetAttachmentImageStoragePath,
  GetClaimAttachmentImage,
  GetImageFromS3,
  GetSentImage,
  UpdateClaimAttachment,
  UploadImageToS3,
} from './attachmentApi'

export const GetClaimAttachmentImageBytes = async (
  attachmentId: number,
  image: ImageResponse,
  authentication: IAuthenticatedApiModel,
  customerId: number,
) => {
  const response = await GetClaimAttachmentImage(
    attachmentId,
    image.imageId,
    image.isLegacy,
    authentication,
    customerId,
  )
  const imageData = await GetImageFromS3(response.data.storagePath)
  const imageFile = dataURLtoFile(
    'data:image/jpeg;base64,' + imageData,
    image.imageId.toString(),
  )
  return ToClaimAttachmentImage(image, false, imageFile)
}

export const GetClaimAttachmentSentImageBytes = async (
  neaNumber: string,
  image: ImageResponse,
  authentication: IAuthenticatedApiModel,
  isLegacy: boolean,
  customerId: number,
) => {
  const response = await GetSentImage(
    neaNumber,
    image.imageId,
    authentication,
    isLegacy,
    customerId,
  )
  const imageData = await GetImageFromS3(response.data.storagePath)
  const imageFile = dataURLtoFile(
    'data:image/jpeg;base64,' + imageData,
    image.imageId.toString(),
  )
  return ToClaimAttachmentImage(image, false, imageFile)
}

export const ConvertImageS3Bytes = async (
  imageName: string,
  destType: string,
  authentication: IAuthenticatedApiModel,
) => {
  const response = await ConvertImageS3(authentication, imageName, destType)
  const imageData = await GetImageFromS3(response.data.storagePath)
  const imageFile = dataURLtoFile(
    'data:image/jpeg;base64,' + imageData,
    imageName,
  )
  return imageFile
}

export const ToClaimAttachmentImage = async (
  image: ImageResponse,
  isNewImage: boolean,
  imageFile: File,
): Promise<IAttachmentImage> => {
  return {
    isNewImage: isNewImage,
    isLegacyImage: image.isLegacy,
    imageId: image.imageId,
    imageDateTaken: image.imageDate
      ? new Date(Date.parse(image.imageDate))
      : null,
    imageOrientationType: image.orientation as ImageOrientationType,
    imageTypeId: image.imageTypeId,
    imageTypeName: image.imageType,
    imageCaptureMethod: image.acquisitionType
      ? (image.acquisitionType as ImageCaptureMethod)
      : null,
    imageData: imageFile,
  }
}

export const SaveClaimAttachment = async (
  claimId: string,
  attachment: IAttachment,
  authentication: IAuthenticatedApiModel,
  isLegacy: boolean = false,
  customerId: number,
) => {
  if (
    attachment?.narrative ||
    (attachment?.attachmentImages &&
      attachment.attachmentImages.length !==
        attachment.attachmentImages.filter((x) => x.isDeleted).length)
  ) {
    // save s3 images separately to handle client side upload errors
    const attachmentImageS3Save: Array<IAttachmentImageS3Save> = []
    if (attachment.attachmentImages) {
      for (const [index, image] of attachment.attachmentImages.entries()) {
        const imageName = await SaveClaimAttachmentImageS3Api(
          image,
          authentication,
          customerId,
        )
        attachmentImageS3Save.push({ imageIndex: index, imageName })
      }
    }

    const attachmentId = await SaveClaimAttachmentApi(
      claimId,
      attachment,
      authentication,
      isLegacy,
      customerId,
    )

    if (attachment.attachmentImages) {
      for (const [index, image] of attachment.attachmentImages.entries()) {
        const imageS3Save: IAttachmentImageS3Save = attachmentImageS3Save[index]
        await SaveClaimAttachmentImageApi(
          attachmentId,
          image,
          authentication,
          isLegacy,
          customerId,
          imageS3Save.imageName,
        )
      }
    }
  } else if (attachment?.attachmentId) {
    await DeleteClaimAttachment(
      attachment.attachmentId,
      authentication,
      isLegacy,
      customerId,
    )
  }
}

export const ClearAttachmentRequirementsApi = async (
  claimId: string,
  authentication: IAuthenticatedApiModel,
  customerId: number,
) => {
  await ClearAttachmentRequirements(
    parseInt(claimId),
    authentication,
    customerId,
  )
}

export const SaveClaimAttachmentApi = async (
  claimId: string,
  attachment: IAttachment,
  authentication: IAuthenticatedApiModel,
  isLegacy: boolean = false,
  customerId: number,
) => {
  let attachmentId = attachment.attachmentId

  const request = {
    narrative: attachment.narrative,
    payorRefId: attachment.payorRefId,
  } as AttachmentRequest

  if (attachment.attachmentId) {
    await UpdateClaimAttachment(
      attachment.attachmentId,
      request,
      authentication,
      isLegacy,
      customerId,
    )
  } else {
    const claimIdParsed = parseInt(claimId)
    const response = await CreateClaimAttachment(
      claimIdParsed,
      request,
      authentication,
      isLegacy,
      customerId,
    )
    attachmentId = response.data.attachmentId
  }

  return attachmentId
}

export const SaveClaimAttachmentImageS3Api = async (
  image: IAttachmentImage,
  authentication: IAuthenticatedApiModel,
  customerId: number,
): Promise<string> => {
  let imageName = {} as string
  if (!image.isDeleted) {
    const storageResponse = await GetAttachmentImageStoragePath(
      authentication,
      customerId,
    )
    const imageSavedToS3: boolean = await UploadImageToS3(
      storageResponse.data.storagePath,
      image.imageData,
    )
    if (!imageSavedToS3) {
      throw new Error('Failed to save attachment image.')
    }
    imageName = storageResponse.data.imageName
  }
  return imageName
}

export const SaveClaimAttachmentImageApi = async (
  attachmentId: number,
  image: IAttachmentImage,
  authentication: IAuthenticatedApiModel,
  isLegacy: boolean = false,
  customerId: number,
  imageName: string,
) => {
  const orientation = image.imageOrientationType?.toString() ?? 'NONE'
  if (image.isDeleted) {
    await DeleteClaimAttachmentImage(
      attachmentId,
      image.imageId,
      authentication,
      isLegacy,
      customerId,
    )
  } else if (image.isNewImage) {
    await CreateClaimAttachmentImage(
      attachmentId,
      image.imageTypeName,
      image.imageDateTaken?.toISOString(),
      orientation,
      image.imageCaptureMethod,
      imageName,
      authentication,
      isLegacy,
      customerId,
    )
  } else {
    await DeleteClaimAttachmentImage(
      attachmentId,
      image.imageId,
      authentication,
      isLegacy,
      customerId,
    )
    await CreateClaimAttachmentImage(
      attachmentId,
      image.imageTypeName,
      image.imageDateTaken?.toISOString(),
      orientation,
      image.imageCaptureMethod,
      imageName,
      authentication,
      isLegacy,
      customerId,
    )
  }
}
