import DateFnsDatePicker, { DateFnsPickerProps } from "./DateFnsDatePicker";
import { FC, useEffect } from "react";
import { addListenerToPickerInput, formatPanelYears, formatPickerInputDate, removeListenerFromPickerInput } from "../RangePicker";
import './DatePicker.scss'

export const DatePicker:FC<DateFnsPickerProps> = (props) => {

  useEffect(() => {
    addListenerToPickerInput(formatPickerInputDate)

    return () => {
      removeListenerFromPickerInput(formatPickerInputDate)
    }
  }, [])

  const datePickerProps: DateFnsPickerProps = { ...props }

  // Force CSS
  datePickerProps.className = `date-fns-date-picker ${props.className ?? ''}`

  const handleDateRender: DateFnsPickerProps['dateRender'] = (currentDate) => {

    const baseStyle: React.CSSProperties = {
      borderRadius: '16px',
      transition: 'none',
    }
  
    if (typeof currentDate === 'number' || typeof currentDate === 'string') {
      return <div className='ant-picker-cell-inner'>{currentDate}</div>
    }

    return (
      <div
        className={`date-fns-date-picker-cell ant-picker-cell-inner`}
        style={{ ...baseStyle }}
      >
        {currentDate.getDate()}
      </div>
    )
  }

  return (
    <DateFnsDatePicker 
      className='date-fns-date-picker'
      dateRender={handleDateRender}
      panelRender={(panelNode) => {
        formatPanelYears()
        return panelNode
      }}
      popupClassName='date-fns-date-picker-popup'
      {...props}
    />
  )
}