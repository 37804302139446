import { CheckCircleFilled } from '@ant-design/icons'
import { Modal } from 'antd'
import {
  endOfDay,
  format,
  intervalToDuration,
  startOfDay,
  subDays,
} from 'date-fns'
import { Dispatch, FC, SetStateAction } from 'react'

import { Button } from 'ui'

import { DateRange } from 'ui'
import { Loading } from '../../_siteWide/loader/dataDisplay'
import {
  SentAttachment,
  SentAttachmentListItem,
  SentAttachmentsRequest,
} from '../../../api/attachment-standalone/attachment-standalone-client'
import { GetSentAttachmentList } from '../../../api/attachment-standalone/attachmentStandaloneApi'
import { IAuthenticatedApiModel } from '../../../utilities/api'
import CopyAttachmentIdButton from '../Common/_copyAttachmentIdButton'
import { useAttachmentsContext } from '../Context/AttachmentsContext'

type SendProgressModalProps = {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  sendingAttachment: boolean
  setSendingAttachment: Dispatch<SetStateAction<boolean>>
  sentResponse: SentAttachment
  setDateRange: Dispatch<
    SetStateAction<{ dates: [Date, Date]; numberOfDays: number }>
  >
  setSentAttachments: Dispatch<React.SetStateAction<SentAttachmentListItem[]>>
  setAttachmentId: Dispatch<SetStateAction<number>>
}

const SendProgressModal: FC<SendProgressModalProps> = ({
  loading,
  setLoading,
  sendingAttachment,
  setSendingAttachment,
  sentResponse,
  setDateRange,
  setSentAttachments,
  setAttachmentId,
}) => {
  const {
    authentication,
    facilityId,
    setActiveTab,
    patientInfo,
    setPatientInfo,
  } = useAttachmentsContext()

  // Use for Sent attachments search when triggered manually
  const refreshSentAttachments = async (
    authentication: IAuthenticatedApiModel,
    facilityId: string,
    date: DateRange['dates'],
    setDateRange: Dispatch<
      SetStateAction<{ dates: [Date, Date]; numberOfDays: number }>
    >,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setSentAttachments: Dispatch<SetStateAction<SentAttachmentListItem[]>>,
  ) => {
    const durationAsDays: number = intervalToDuration({
      start: date[0],
      end: date[1],
    }).days
    setDateRange({
      dates: [startOfDay(date[0]), endOfDay(date[1])],
      numberOfDays: durationAsDays,
    })

    const request: SentAttachmentsRequest = {
      sentAfter: format(date[0], 'yyyy-MM-dd'),
      sentBefore: format(date[1], 'yyyy-MM-dd'),
    }

    setLoading(true)

    await GetSentAttachmentList({ ...authentication }, facilityId, request)
      .then(({ data }) => {
        setSentAttachments(data)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }

  const handleCloseModal = () => {
    setSendingAttachment(false)
    setPatientInfo(null)
    setAttachmentId(null)
    refreshSentAttachments(
      authentication,
      facilityId,
      [startOfDay(subDays(new Date(), 14)), endOfDay(new Date())],
      setDateRange,
      setLoading,
      setSentAttachments,
    )
  }

  return (
    <Modal
      title={<header className='sa-edit__send-header' />}
      closable={true}
      visible={sendingAttachment}
      width='375px'
      onCancel={handleCloseModal}
      footer={[
        <Button
          key='back'
          label='Close'
          onClick={handleCloseModal}
        />,
      ]}
    >
      <main className='sa-edit__send'>
        <p>
          <b>Patient Name</b>
        </p>
        <p>{`${patientInfo?.patientFirstName} ${patientInfo?.patientLastName}`}</p>
        <section className='sa-edit__send-content'>
          <p className='fs-125'>
            <b>{loading ? 'Sending Attachment...' : 'Sent!'}</b>
          </p>
          <div className='fs-350'>
            {loading ? (
              <Loading />
            ) : (
              <CheckCircleFilled style={{ color: '#91C73D' }} />
            )}
          </div>
          <p>
            <b>{!loading && 'Attachment ID'}</b>
          </p>
          {!loading && (
            <div className='sa-edit__send-content__sent'>
              <p className='sa-edit__send-content__sent-text'>{`NEA#${sentResponse?.hmfId}`}</p>
              <CopyAttachmentIdButton attachmentId={sentResponse?.hmfId} />
            </div>
          )}
        </section>
      </main>
    </Modal>
  )
}

export default SendProgressModal
