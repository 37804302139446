import {
  Button as AntButton,
  ButtonProps as AntButtonProps,
  Tooltip,
} from 'antd'
import React, { FC, ReactNode } from 'react'

import { setTheme } from '../../styles/theming'

import './Button.scss'

export interface ButtonProps
  extends AntButtonProps,
    React.RefAttributes<HTMLElement> {
  fullWidth?: boolean
  isTrellis?: boolean
  label?: string
  tooltip?: string
}

export const Button: FC<ButtonProps> = ({
  children,
  className,
  disabled = false,
  fullWidth = false,
  isTrellis = null,
  label,
  loading = false,
  size = 'middle',
  type = 'default',
  tooltip = null,
  ...props
}) => {
  // NOTE: we don't want setTheme to run inside trellis, but we need to be able to test theming in Storybook
  isTrellis !== null && setTheme(isTrellis)

  return (
    <Tooltip title={tooltip}>
      <AntButton
        className={`${className} ${fullWidth ? 'ant-btn--full-width' : ''}`}
        disabled={disabled}
        loading={loading}
        size={size}
        type={type}
        {...props}
      >
        {label}
        {children}
      </AntButton>
    </Tooltip>
  )
}
