import {
  DownOutlined,
  InfoCircleFilled,
  WarningOutlined,
} from '@ant-design/icons'
import {
  Col,
  Dropdown,
  Menu,
  MenuProps,
  Modal,
  Row,
  Space,
  Tooltip,
} from 'antd'
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'

import { Button } from 'ui'

import { DocumentType } from '../../../api/attachment-standalone/attachment-standalone-client'
import { ImageDataProps, isXrayImageType } from '../AttachmentsTypes'
import ImageDateTaken from '../Common/ImageDateTaken'

import './AttachmentImage.scss'

import { formatDateFromString, showMessage } from '../../../utilities/general'
import { IImageOperation } from '../../attachment/attachment.interface'
import AttachmentImageDisplay from '../../Attachments/AttachmentImage/attachmentImageDisplay'
import { useAttachmentsContext } from '../Context/AttachmentsContext'
import EditImageControls from './editImageControls'

const { confirm } = Modal

type AttachmentImageProps = {
  imageTypes: DocumentType[]
  imageDataProps: ImageDataProps
  setImageDataProps: Dispatch<SetStateAction<ImageDataProps>>
  imageData: ImageDataProps[]
  setImageData: Dispatch<SetStateAction<ImageDataProps[]>>
  displayEditImage: boolean
  setDisplayEditImage: Dispatch<SetStateAction<boolean>>
}

type MenuItem = Required<MenuProps>['items'][number]

const AttachmentImage: FC<AttachmentImageProps> = ({
  imageTypes,
  imageDataProps,
  setImageDataProps,
  imageData,
  setImageData,
  displayEditImage,
  setDisplayEditImage,
}) => {
  const [imgForCanvas, setImgForCanvas] = useState<HTMLImageElement>()
  const [loading, setLoading] = useState<boolean>(false)
  const [activeImageType, setActiveImageType] = useState<DocumentType>(null)
  const [originalImg, setOriginalImg] = useState<HTMLImageElement>()
  const [operations, setOperations] = useState<IImageOperation>()
  const [orientationText, setOrientationText] = useState<string>(null)

  const keyId = useRef<number>(0)
  const croppedImageRef = useRef<HTMLImageElement>()
  const currentCanvasBlobRef = useRef<Blob>()

  const { patientInfo } = useAttachmentsContext()
  const defaultOrientation =
    localStorage.getItem('standalone-attachment-image-orientation') || 'Right'

  function updateCurrentCanvasImageRef(newCanvasImage: Blob) {
    currentCanvasBlobRef.current = newCanvasImage
  }

  function updateCroppedImg(newCroppedImg: HTMLImageElement) {
    croppedImageRef.current = newCroppedImg
  }

  useEffect(() => {
    setLoading(true)
    updateImageDataProps(
      'ImageType',
      imageDataProps.imageType?.documentDescription,
    )
    setActiveImageType(imageDataProps.imageType)
    updateImageDataProps(
      'Orientation',
      imageDataProps.orientation ?? defaultOrientation,
    )
    setLoading(false)

    const setInitalSetup = async () => {
      if (!originalImg) {
        const origImg = new Image()
        origImg.src = URL.createObjectURL(imageDataProps.imageData)
        origImg.onload = () => {
          croppedImageRef.current = origImg
          setOriginalImg(origImg)
          setImgForCanvas(origImg)
        }
      }

      keyId.current = (await (keyId.current === 0)) ? 1 : 0
    }

    setInitalSetup()
  }, [])

  const updateAttachmentDocument = () => {
    // Clone image data props
    const imageDataPropsCopy = { ...imageDataProps }

    // Verify image type is set
    if (!imageDataPropsCopy.imageType) {
      showMessage('Image Type is required.')
      return
    }

    // Verify xray images have date taken supplied
    const isXray = isXrayImageType(
      imageDataPropsCopy.imageType.documentTypeGroupDescription,
    )
    if (!imageDataPropsCopy.dateTaken && isXray) {
      showMessage('Date Taken is required.')
      return
    }
    if (!imageDataPropsCopy.orientation && isXray) {
      // If they haven't chosen one, default to their last selection, or 'Right' if they don't have one
      imageDataPropsCopy['orientation'] =
        localStorage.getItem('standalone-attachment-image-orientation') ||
        'Right'
    }
    imageDataPropsCopy.imageChanged = true

    if (currentCanvasBlobRef.current) {
      const file = new File(
        [currentCanvasBlobRef.current],
        imageDataPropsCopy.imageData.name,
      )
      imageDataPropsCopy.imageData = file
      imageDataPropsCopy.imageDataThumbnail = URL.createObjectURL(file)
    }

    const imageDataCopy = [...imageData]
    const imageArrayIndex = imageDataCopy.findIndex(
      (image) => image.rowKey === imageDataPropsCopy.rowKey,
    )
    if (imageArrayIndex == null) {
      imageDataCopy.push(imageDataPropsCopy)
    } else {
      imageDataCopy[imageArrayIndex] = imageDataPropsCopy
    }

    setImageData(imageDataCopy)
    setDisplayEditImage(false)
    showMessage('Changes have been saved', 'success')
  }

  const updateImageDataProps = (column: string, newValue: string) => {
    const imageDataPropsCopy = { ...imageDataProps }
    switch (column) {
      case 'ImageType':
        imageDataPropsCopy.imageType = imageTypes.find((item) =>
          item.documentDescription === newValue ? item : null,
        )
        break
      case 'Orientation':
        localStorage.setItem(
          'standalone-attachment-image-orientation',
          newValue,
        )
        imageDataPropsCopy.orientation = newValue
        setOrientationText(
          orientationItems.find((item) => item.key === newValue)?.label,
        )
        break
      case 'DateTaken':
        imageDataPropsCopy.dateTaken = newValue
        break
      default:
    }
    setImageDataProps(imageDataPropsCopy)
  }

  const imgTypes: any = []
  const otherTypes: any = []

  imageTypes.forEach((type) => {
    if (type.documentTypeGroupDescription === '-- X-Ray Types --') {
      imgTypes.push({
        key: `${type.documentTypeId} ${type.documentCode}`,
        label: (
          <a onClick={() => handleUpdateImageType(type)}>
            {type.documentDescription}
          </a>
        ),
      })
    } else if (type.documentTypeGroupDescription === '-- Non X-Ray Types --') {
      otherTypes.push({
        key: `${type.documentTypeId} ${type.documentCode}`,
        label: (
          <a onClick={() => handleUpdateImageType(type)}>
            {type.documentDescription}
          </a>
        ),
      })
    }
  })

  const handleUpdateImageType = (type: DocumentType) => {
    updateImageDataProps('ImageType', type.documentDescription)
    setActiveImageType(type)
  }

  const showCancelConfirm = () => {
    confirm({
      title: 'Unsaved Changes',
      icon: <WarningOutlined className='sa-edit-image__modal-cancel-icon' />,
      content: 'Would you like to save your changes?',
      okText: 'Save and Exit',
      okType: 'primary',
      cancelText: 'Exit without Saving',
      closable: true,
      onOk() {
        updateAttachmentDocument()
      },
      onCancel() {
        setDisplayEditImage(false)
      },
    })
  }

  const items: MenuItem[] = [
    {
      key: 'imageType',
      label: 'Images',
      children: imgTypes,
    },
    {
      key: 'otherType',
      label: 'Other Type',
      children: otherTypes,
    },
  ]

  const orientationItems = [
    {
      key: 'Left',
      label: 'Patient Left',
    },
    {
      key: 'Right',
      label: 'Patient Right',
    },
  ]

  const imageTypeMenu = (
    <Menu
      className='sa-edit-image__modal-menu'
      items={items}
    />
  )

  const orientationMenu = (
    <Menu
      className='sa-edit-image__modal-menu'
      items={orientationItems}
      onClick={({ key }) => {
        updateImageDataProps('Orientation', key)
      }}
    />
  )

  const orientationTooltipContent = (
    <>
      <p>
        Right = Left side of the x-ray is the patient's right side (most common)
      </p>
      <p>Left = Left side of the x-ray is the patient's left side</p>
    </>
  )

  return (
    <Modal
      closable={false}
      destroyOnClose
      wrapClassName={'sa-edit-image__modal'}
      visible={displayEditImage}
      footer={null}
      mask={false}
    >
      <Row gutter={24}>
        <Col span={4}></Col>
        <Col
          span={20}
          className='sa-edit-image__modal__header-actions'
        >
          <EditImageControls
            attachmentImage={imageDataProps.imageData}
            keyId={keyId}
            operations={operations}
            setOperations={setOperations}
            setImgForCanvas={setImgForCanvas}
            croppedImageRef={croppedImageRef}
          />
          <Button
            disabled={loading}
            label='Cancel'
            onClick={showCancelConfirm}
          />
          <Button
            disabled={loading}
            label='Save'
            onClick={() => updateAttachmentDocument()}
            type='primary'
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={4}>
          <Space
            direction={'vertical'}
            size={40}
            style={{ display: 'flex' }}
          >
            <div>
              <p className='fs-150'>
                <b>{`${patientInfo?.patientFirstName} ${patientInfo?.patientLastName}`}</b>
              </p>
              <p className='fs-100'>
                {patientInfo?.patientDateOfBirth &&
                  formatDateFromString(
                    patientInfo.patientDateOfBirth,
                    'MM/dd/yyy',
                  )}
              </p>
            </div>
            <div>
              <p className='fs-100'>
                <b>Image Type</b>
              </p>
              <Dropdown
                overlay={imageTypeMenu}
                overlayStyle={{ borderRadius: 0 }}
              >
                <a className={`sa-edit-image__modal-dropdown`}>
                  <span>
                    {activeImageType?.documentDescription || undefined}&nbsp;
                  </span>
                  <DownOutlined />
                </a>
              </Dropdown>
            </div>
            {imageDataProps?.imageType?.documentTypeId &&
              imageDataProps.imageType.documentTypeId !== 3 && (
                <div>
                  <p className='fs-100'>
                    <b>Orientation </b>
                    <Tooltip title={orientationTooltipContent}>
                      <InfoCircleFilled />
                    </Tooltip>
                  </p>
                  <Dropdown
                    overlay={orientationMenu}
                    overlayStyle={{ borderRadius: 0 }}
                  >
                    <a className={`sa-edit__grid-dropdown`}>
                      <span>{orientationText} &nbsp;</span>
                      <DownOutlined />
                    </a>
                  </Dropdown>
                </div>
              )}
            <div>
              <p className='fs-100'>
                <b>Date Taken</b>
              </p>
              <ImageDateTaken
                record={imageDataProps}
                updateImageData={updateImageDataProps}
              />
            </div>
          </Space>
        </Col>
        <Col span={20}>
          <div id='attachment-container'>
            <AttachmentImageDisplay
              key={keyId.current.toString()}
              showPreviewText={false}
              imageFile={imageDataProps.imageData}
              performOperations={operations}
              imgForCanvas={imgForCanvas}
              setImgForCanvas={setImgForCanvas}
              cropImgCallback={updateCroppedImg}
              currentCanvasToBlobCallback={updateCurrentCanvasImageRef}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default AttachmentImage
