import { FC, ReactNode, useEffect } from 'react'
import { Outlet, useLoaderData } from 'react-router-dom'

import { ClearSentryUser } from 'utils'

import { appTheme$, AppThemeType } from 'trellis:state/globalState'
import { setTheme } from 'trellis:theme/setTheme'
import { isDiSite } from 'trellis:utilities/general'

import Footer from './Footer/Footer'

import './PreAuthLayout.scss'

const PreAuthLayout: FC = (): ReactNode => {
  const appTheme = useLoaderData() as AppThemeType

  useEffect(() => {
    // reset the ld scope
    ClearSentryUser()
  }, [])

  return (
    <div className={`pre-auth-layout ${appTheme}`}>
      <Outlet />
    </div>
  )
}

export const PublicLayoutWrapper: FC<{ children: ReactNode }> = ({
  children,
}) => (
  <main className='main__wrapper'>
    <div className='main__content-wrapper'>{children}</div>
    <Footer />
  </main>
)

export const loaderPreAuthLayout = async (): Promise<AppThemeType> => {
  const isDI = isDiSite()

  let appTheme: AppThemeType
  if (isDI) appTheme = 'dental-intel-theme'
  else appTheme = 'trellis-theme'

  appTheme$.set(appTheme)
  setTheme(appTheme)

  return appTheme
}

export default PreAuthLayout
