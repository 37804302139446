import { Input, Row, Tabs } from 'antd'
import { FC, useEffect, useState } from 'react'

import { SmartTable, SmartTableState } from '../_siteWide/table/SmartTable'
import { formatDateString, showMessage } from '../../utilities/general'

import './transactions.scss'

import { getUnixTime } from 'date-fns'
import { useNavigate } from 'react-router-dom'

import { LogError } from 'utils'

import { LDFlags$ } from 'trellis:state/globalState'

import { Transaction } from '../../api/billing/billing-client'
import { GetTransactions } from '../../api/billing/billingApi'
import { useGlobalContext } from '../../context/GlobalContextProvider'

declare const $: any

const { TabPane } = Tabs
const { Search } = Input

export const Transactions: FC = () => {
  //TODO: Remove this page after 01/2024
  const legacyPaymentsDisabled = LDFlags$.trellisDisableLegacyPayments.use()
  const navigate = useNavigate()
  if (legacyPaymentsDisabled) {
    navigate('/', {
      replace: true,
    })
  }

  const { authentication } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [initialLoad, setInitialLoad] = useState(true)
  const [state, setState] = useState<TransactionsState>({
    Key: 0,
    Data: [],
    OriginalData: [],
    Total: 0,
    Filters: {
      CurrentPage: 1,
      PageSize: 50,
      Config: new Map<string, any>(),
    },
  })

  interface TransactionsState extends SmartTableState<TransactionGridItem> {
    OriginalData: TransactionGridItem[]
  }

  const searchTable = (value: string) => {
    const copy = { ...state }

    if (value) {
      copy.Data = copy.OriginalData.filter(
        (d: TransactionGridItem) =>
          (d.TransactionDate &&
            d.TransactionDate.toLowerCase().includes(value.toLowerCase())) ||
          (d.LastFour &&
            d.LastFour.toLowerCase().includes(value.toLowerCase())) ||
          (d.GlobalCustomerId &&
            d.GlobalCustomerId.toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())) ||
          (d.Tin && d.Tin.toLowerCase().includes(value.toLowerCase())) ||
          (d.CustomerName &&
            d.CustomerName.toLowerCase().includes(value.toLowerCase())) ||
          (d.ScheduleCode &&
            d.ScheduleCode.toLowerCase().includes(value.toLowerCase())) ||
          (d.TransactionAmount &&
            d.TransactionAmount.toString().includes(value.toString())) ||
          (d.Credits && d.Credits.toString().includes(value.toString())) ||
          (d.CardType &&
            d.CardType.toLowerCase().includes(value.toLowerCase())) ||
          (d.TransactionId &&
            d.TransactionId.toString().includes(value.toString())) ||
          (d.TransactionStatus &&
            d.TransactionStatus.toLowerCase().includes(value.toLowerCase())),
      )
    } else {
      copy.Data = copy.OriginalData
    }

    setState(copy)
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (!initialLoad) searchTable(search)
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [search])

  const getTransactions = () => {
    if (authentication?.AccessToken) {
      setLoading(true)
      const copy = { ...state }
      GetTransactions(authentication, authentication.CustomerIdNumber)
        .then(({ data }) => {
          const gridData = mapTransactionRecords(data)
          copy.Data = gridData
          copy.OriginalData = gridData
          copy.Total = gridData?.length
          setState(copy)
          setLoading(false)
        })
        .catch((ex) => {
          showMessage(
            'There was an error getting your transations. Please try again later.',
          )
          LogError(ex)
          setLoading(false)
        })
    }
  }

  interface TransactionGridItem extends Transaction {
    UnixDate: number
    FormattedDate: string
  }

  const mapTransactionRecords = (
    records: Transaction[],
  ): TransactionGridItem[] => {
    return records.map((record) => {
      return {
        ...record,
        UnixDate: getUnixTime(Date.parse(record.TransactionDate)),
        FormattedDate: formatDateString(record.TransactionDate, 'MM/dd/yyyy'),
      }
    })
  }

  const onSearch = (e: any) => {
    setInitialLoad(false)
    setSearch(e.target.value || '')
  }

  const columns = [
    {
      title: 'Transaction Date',
      dataIndex: 'FormattedDate',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: TransactionGridItem, b: TransactionGridItem) =>
        a.UnixDate - b.UnixDate,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Last Four',
      dataIndex: 'LastFour',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: TransactionGridItem, b: TransactionGridItem) => {
        return a.LastFour && b.LastFour
          ? parseInt(a.LastFour) - parseInt(b.LastFour)
          : 0
      },
    },
    {
      title: 'Customer ID',
      dataIndex: 'GlobalCustomerId',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: TransactionGridItem, b: TransactionGridItem) => {
        return a.GlobalCustomerId && b.GlobalCustomerId
          ? a.GlobalCustomerId - b.GlobalCustomerId
          : 0
      },
    },
    {
      title: 'Account/Tax ID',
      dataIndex: 'Tin',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: TransactionGridItem, b: TransactionGridItem) =>
        a.Tin.localeCompare(b.Tin),
    },
    {
      title: 'Customer Name',
      dataIndex: 'CustomerName',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: TransactionGridItem, b: TransactionGridItem) =>
        a.CustomerName.localeCompare(b.CustomerName),
    },
    {
      title: 'Bill Type',
      dataIndex: 'ScheduleCode',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: TransactionGridItem, b: TransactionGridItem) =>
        a.ScheduleCode.localeCompare(b.ScheduleCode),
    },
    {
      title: 'Amount',
      dataIndex: 'TransactionAmount',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: TransactionGridItem, b: TransactionGridItem) => {
        return a.TransactionAmount && b.TransactionAmount
          ? a.TransactionAmount - b.TransactionAmount
          : 0
      },
      render: (value: any) => value && `$${value.toFixed(2)}`,
    },
    {
      title: 'Credits',
      dataIndex: 'Credits',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: TransactionGridItem, b: TransactionGridItem) => {
        return a.Credits && b.Credits ? a.Credits - b.Credits : 0
      },
    },
    {
      title: 'CCType',
      dataIndex: 'CardType',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: TransactionGridItem, b: TransactionGridItem) =>
        a.CardType.localeCompare(b.CardType),
    },
    {
      title: 'Transaction ID',
      dataIndex: 'TransactionId',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: TransactionGridItem, b: TransactionGridItem) => {
        return a.TransactionId && b.TransactionId
          ? a.TransactionId - b.TransactionId
          : 0
      },
    },
    {
      title: 'Status',
      dataIndex: 'TransactionStatus',
      noFilter: true,
      noSort: true,
      width: 170,
      sorter: (a: TransactionGridItem, b: TransactionGridItem) =>
        a.TransactionStatus.localeCompare(b.TransactionStatus),
    },
  ]

  return (
    <article className='page-content__container page-content__container--with-page-header'>
      <Row
        justify='space-between'
        align='middle'
      >
        <h1 className='page-content-header__title'>Transactions</h1>
        <Search
          placeholder='Search'
          onChange={onSearch}
          value={search}
          className='transaction-history-search'
        />
      </Row>
      <Tabs className='pt-100'>
        <TabPane
          key='Transactions'
          tab='Your Current Transactions'
        >
          <SmartTable
            columns={columns}
            getData={getTransactions}
            isFullViewport={true}
            loading={loading}
            noFilter
            rowKey='TransactionID'
            setState={setState}
            state={state}
            storageKey='transactions-grid-filters'
            tableClass='transaction-table'
          />
        </TabPane>
      </Tabs>
    </article>
  )
}

const content = <Transactions />
