import { useObservable } from '@legendapp/state/react'
import { endOfDay, startOfDay } from 'date-fns'
import { createContext, FC, useContext, useState } from 'react'

import { KeyType } from 'utils'

import { LDFlags$ } from 'trellis:state/globalState'

import { OnetimeEligibility } from '../../../../api/eligibility/eligibility-client'
import { EligibilityResponseProps } from '../EligibilityResponse'
import {
  ParticipatingCarrier,
  ProviderDetails,
} from '../utilities/eligibilityTyping'

export type RequestStateType = {
  Data: any[]
  Filters: {
    Config?: { [key: KeyType]: any }
    CurrentPage: number
    Name?: string
    Options?: { [key: KeyType]: any }
    PageSize: number
    Search?: string
    SortColumn: string
    SortDirection: string
  }
  Key: number
  Summary?: {
    // NOTE: only for appointments
    Appointments: number
    Eligible: number
    NeedsAttention: number
    PendingPatientResponse: number
  }
  Total: number
}

export type OneTimeRequestStateType = {
  Data: OnetimeEligibility[]
  Filters: {
    Config?: { [key: KeyType]: any }
    CurrentPage: number
    Name?: string
    PageSize: number
  }
  Key: number
  OriginalData: OnetimeEligibility[]
  Total: number
}

export type DateRangeState = {
  dates: [Date | null, Date | null]
  numberOfDays: number
}

export type VerificationResponseHeader = {
  carrierFax: string
  carrierId: string
  carrierName: string
  carrierPhone: string
  carrierWebsite: string
  patientBirthdate: string
  patientName: string
  patientStatus: string
}

const EligibilityContext = createContext(null)

const EligibilityContextProvider: FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const flags = LDFlags$.use()

  const getActiveTab = () => {
    let tab: string =
      flags?.eligibilityUsevynedentalsync ||
      flags?.operaVynesyncAutomatedeligibility
        ? 'appointments'
        : 'patients'

    let hash: string = window.location.hash
      ? window.location.hash.substring(1)
      : ''
    switch (hash) {
      case 'patients':
        if (
          flags?.eligibilityUsevynedentalsync ||
          flags?.operaVynesyncAutomatedeligibility
        )
          hash = 'appointments'
        break
      case 'appointments':
      case 'requests':
        if (
          !flags?.eligibilityUsevynedentalsync &&
          !flags?.operaVynesyncAutomatedeligibility
        )
          hash = 'patients'
    }

    if (
      hash &&
      hash !== 'patients' &&
      hash !== 'appointments' &&
      hash !== 'requests'
    ) {
      hash = ''
      window.location.hash = ''
    }

    if (hash) {
      window.location.hash = `#${hash}`
      tab = hash
    }

    return tab
  }

  const [activeTab, setActiveTab] = useState<string>(getActiveTab())
  const [loading, setLoading] = useState<boolean>(false)
  const [participatingCarriers, setParticipatingCarriers] = useState<
    ParticipatingCarrier[]
  >([])
  const [providers, setProviders] = useState<ProviderDetails[]>([])
  const [showAppointmentsSummary, setShowAppointmentsSummary] =
    useState<boolean>(true)
  const [showEligibilityResponseModal, setShowEligibilityResponseModal] =
    useState<boolean>(false)
  const [showPatientUpdateFormModal, setShowPatientUpdateFormModal] =
    useState(false)
  const [verificationResponse, setVerificationResponse] =
    useState<EligibilityResponseProps>(null)
  const [requiresTableRefresh, setRequiresTableRefresh] =
    useState<boolean>(false)

  // state for patients
  const [patientState, setPatientState] = useState<RequestStateType>({
    Data: [],
    Filters: {
      Config: {},
      CurrentPage: 1,
      Name: '',
      Options: {},
      PageSize: 50,
      Search: '',
      SortColumn: '',
      SortDirection: '',
    },
    Key: 0,
    Total: 0,
  })
  const [patientDateRange, setPatientDateRange] = useState<DateRangeState>({
    dates: [null, null],
    numberOfDays: 0,
  })

  // state for appointments
  const [appointmentState, setAppointmentState] = useState<RequestStateType>({
    Data: [],
    Filters: {
      Config: {},
      CurrentPage: 1,
      Name: '',
      Options: {},
      PageSize: 50,
      SortColumn: 'AppointmentDate',
      SortDirection: 'desc',
    },
    Key: 0,
    Summary: {
      Appointments: 0,
      Eligible: 0,
      NeedsAttention: 0,
      PendingPatientResponse: 0,
    },
    Total: 0,
  })

  const [appointmentDateRange, setAppointmentDateRange] =
    useState<DateRangeState>({
      dates: [startOfDay(new Date()), endOfDay(new Date())],
      numberOfDays: 0,
    })

  // state for one-time history
  const [oneTimeRequestState, setOneTimeRequestState] =
    useState<OneTimeRequestStateType>({
      Data: [],
      Filters: {
        Config: {},
        CurrentPage: 1,
        Name: '',
        PageSize: 50,
      },
      Key: 0,
      OriginalData: [],
      Total: 0,
    })

  const isActionCalledFromTable$ = useObservable<boolean>(false)

  return (
    <EligibilityContext.Provider
      value={{
        activeTab,
        appointmentDateRange,
        appointmentState,
        loading,
        isActionCalledFromTable$,
        oneTimeRequestState,
        participatingCarriers,
        patientDateRange,
        patientState,
        providers,
        requiresTableRefresh,
        setActiveTab,
        setAppointmentDateRange,
        setAppointmentState,
        setLoading,
        setOneTimeRequestState,
        setParticipatingCarriers,
        setPatientDateRange,
        setPatientState,
        setProviders,
        setRequiresTableRefresh,
        setShowAppointmentsSummary,
        setShowEligibilityResponseModal,
        setShowPatientUpdateFormModal,
        setVerificationResponse,
        showAppointmentsSummary,
        showEligibilityResponseModal,
        showPatientUpdateFormModal,
        verificationResponse,
      }}
    >
      {children}
    </EligibilityContext.Provider>
  )
}

export const useEligibilityContext = () => {
  const context = useContext(EligibilityContext)
  if (context === undefined)
    throw new Error('Context must be used within a Provider')

  return context
}

export default EligibilityContextProvider
