import { Observable } from '@legendapp/state'
import { useObservable } from '@legendapp/state/react'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'

import { LogError } from 'utils'

import { Errors } from 'trellis:constants/errors'
import GlobalState from 'trellis:state/globalState'
import { useSocketContext } from 'trellis:utilities/webSocketClient'

import { ClaimsApiFactory } from '../../../api/claim/claimsApiFactory'
import { NotifyText } from '../../../constants/notifyText'
import {
  FormatDynamicStringResource,
  showMessage,
} from '../../../utilities/general'
import { BatchActions, RowActions } from '../util/claimConstants'
import { SendAllNotificationData } from '../util/claimTypes'
import { useClaimGridContext } from './claimGridContext'

type ClaimActionContextType = {
  showingConfirmDelete: boolean
  setShowingConfirmDelete: Dispatch<SetStateAction<boolean>>
  sendingAll: boolean
  setSendingAll: Dispatch<SetStateAction<boolean>>
  handleAction: (value: string) => Promise<void>
  handleDelete: () => void
  handleRowAction: (rowAction: string, currentClaimId: string) => Promise<void>
  handleSend: (sendAll?: boolean, currentClaimId?: string) => Promise<void>
  sendComplete: () => Promise<void>
  unselectAll: (triggerRefresh?: boolean) => void
  sendAllMsgData: Observable<SendAllNotificationData>
}

const ClaimActionContext = createContext<ClaimActionContextType>(null)
const store = window.localStorage

export const ClaimActionContextProvider = ({ children }: any) => {
  const {
    state,
    setState,
    setInitialLoad,
    setLoading,
    claimRequest,
    getClaims,
    setClaimId,
    ClaimState$,
  } = useClaimGridContext()

  const InitialProcessAllState = {
    Status: null,
    IsComplete: false,
    MessageType: '',
    SuccessCount: 0,
    FailureCount: 0,
    Total: 0,
  }

  const { socket, verifyConnection } = useSocketContext()
  const [sendingAll, setSendingAll] = useState(false)
  const [showingConfirmDelete, setShowingConfirmDelete] = useState(false)
  const sendAllMsgData = useObservable<SendAllNotificationData>(
    InitialProcessAllState,
  )

  const handleRowAction = async (rowAction: string, currentClaimId: string) => {
    switch (rowAction) {
      case RowActions.SEND:
        try {
          setLoading(true)
          await handleSend(false, currentClaimId)
        } catch (error) {
          showMessage(Errors.updateClaimError)
          LogError(error, 'Error while sending claim.')
        } finally {
          setLoading(false)
        }
        break
      case RowActions.ADD_ATTACHMENT:
        setClaimId(currentClaimId)
        ClaimState$.showClaimDetail.set(true)
        ClaimState$.forceShowAttachmentTab.set(true)
        break
      case RowActions.IGNORE_REQUIREMENT:
        try {
          setLoading(true)
          await ClaimsApiFactory(
            GlobalState.Auth.peek(),
          ).UpdateClaimsIgnoreAttachmentRequirements(
            claimRequest(false, state.Install, new Array(currentClaimId)),
          )
          showMessage(
            'Attachment requirements successfully ignored for claim.',
            'success',
          )
          unselectAll()
        } catch (error) {
          showMessage(Errors.updateClaimError)
          LogError(error, 'Error ignoring attachment requirements for claim.')
        } finally {
          setLoading(false)
        }
        break
      case RowActions.HOLD:
      case RowActions.RELEASE:
        try {
          setLoading(true)
          const request = claimRequest(
            false,
            state.Install,
            new Array(currentClaimId),
          )
          request['HoldClaims'] = rowAction === BatchActions.HOLD
          await ClaimsApiFactory(GlobalState.Auth.peek()).UpdateClaimsStatus(
            request,
          )
          showMessage('Claim status was successfully updated.', 'success')
          getClaims()
        } catch (error) {
          showMessage(Errors.updateClaimError)
          LogError(error, 'Error updating claim status.')
        } finally {
          setLoading(false)
        }
        break
      case RowActions.DELETE:
        ClaimState$.currentClaimIDToDelete.set(currentClaimId)
        setShowingConfirmDelete(true)
        break
      default:
        break
    }
  }

  const handleAction = async (value: string) => {
    let request
    switch (value) {
      case BatchActions.IGNORE_WARNINGS:
        setLoading(true)
        await ClaimsApiFactory(
          GlobalState.Auth.peek(),
        ).UpdateClaimsIgnoreAttachmentRequirements(claimRequest())
        unselectAll()
        setLoading(false)
        break
      case BatchActions.HIDE:
        setLoading(true)
        request = claimRequest()
        request['MarkAsVisible'] = false
        await ClaimsApiFactory(GlobalState.Auth.peek()).IgnoreClaims(request)
        unselectAll()
        setLoading(false)
        break
      case BatchActions.UNHIDE:
        setLoading(true)
        request = claimRequest()
        request['MarkAsVisible'] = true
        await ClaimsApiFactory(GlobalState.Auth.peek()).IgnoreClaims(request)
        unselectAll()
        setLoading(false)
        break
      case BatchActions.SEND:
        await handleSend()
        break
      case BatchActions.HOLD:
      case BatchActions.RELEASE:
        setLoading(true)
        request = claimRequest()
        request['HoldClaims'] = value === BatchActions.HOLD
        await ClaimsApiFactory(GlobalState.Auth.peek()).UpdateClaimsStatus(
          request,
        )
        unselectAll()
        setLoading(false)
        break
      case BatchActions.DELETE:
        !state.SelectedCount || state.SelectedCount === 0
          ? showMessage(NotifyText.noClaimsToDelete)
          : setShowingConfirmDelete(true)
        break
    }
  }

  // This gets called twice, on initial API response and when the webhook receives a response
  const sendComplete = async () => {
    setSendingAll(false)
    getClaims()
  }

  const handleSend = async (
    sendAll: boolean = false,
    currentClaimId?: string,
  ) => {
    console.debug('initial connection status: ' + socket?.current?.readyState)
    sendAllMsgData.set(InitialProcessAllState)
    setSendingAll(true)
    sendAllMsgData.Status.set('Claim processing started')

    const validConnection = await verifyConnection()

    const rowActionClaimId = currentClaimId && new Array(currentClaimId)

    const resp = await ClaimsApiFactory(
      GlobalState.Auth.peek(),
      true,
    ).ProcessClaims(claimRequest(sendAll, state.Install, rowActionClaimId))

    const data = resp && resp.response && resp.response.data

    if (!data) {
      showMessage(NotifyText.processClaimsError)
      setSendingAll(false)
    } else if (!validConnection) {
      startWebsocketFallback()
    }

    // Row actions, which pass in currentClaimId, don't need to trigger a grid refresh in unselectAll
    const triggerRefresh = currentClaimId ? false : true
    unselectAll(triggerRefresh)
  }

  const handleDelete = () => {
    const currentClaimID = ClaimState$.currentClaimIDToDelete.peek()

    setLoading(true)
    ClaimsApiFactory(GlobalState.Auth.peek())
      .DeleteClaims(
        claimRequest(
          false,
          state.Install,
          currentClaimID && new Array(currentClaimID),
        ),
      )
      .then(({ data }) => {
        const successCount = data.claimResponses.length
        const message = FormatDynamicStringResource(
          NotifyText.deleteClaimsSuccess,
          successCount,
        )
        showMessage(message, 'success')
        setShowingConfirmDelete(false)
        setInitialLoad(true)
        unselectAll()
      })
      .catch((error) => {
        let message = ''
        if (error.responseJSON !== null && error.responseJSON !== undefined) {
          const successCount = error.responseJSON.claimResponses.filter(
            (item: any) => item.result === 1,
          ).length
          const errorCount = error.responseJSON.claimResponses.filter(
            (item: any) => item.result === -1,
          ).length
          if (successCount > 0) {
            message = `${FormatDynamicStringResource(
              NotifyText.deleteClaimsSuccess,
              successCount,
            )} ${FormatDynamicStringResource(
              NotifyText.deleteClaimsError,
              errorCount,
            )}`
          } else {
            message = FormatDynamicStringResource(
              NotifyText.deleteClaimsError,
              errorCount,
            )
          }
        } else {
          message = NotifyText.deleteAllClaimsError
        }

        showMessage(message)
      })
  }

  const startWebsocketFallback = () => {
    LogError(
      new Error('Websocket Fallback'),
      'Customer was not able to complete a websocket Send All.',
      { connectionStatus: socket?.current?.readyState },
    )

    const timer = setTimeout(() => {
      websocketFallback()
    }, 30000) //30 seconds

    return () => {
      clearTimeout(timer) // Clean up the timer on component unmount
    }
  }

  const websocketFallback = () => {
    console.debug('fallback connection status: ' + socket?.current?.readyState)

    sendAllMsgData.Status.set('BadWebsocketConnection')
    const timer2 = setTimeout(() => {
      sendAllMsgData.Status.set(null)
      sendComplete()
    }, 8000) // 8000 milliseconds = 8 seconds

    return () => {
      clearTimeout(timer2) // Clean up the timer on component unmount
    }
  }

  const unselectAll = (triggerRefresh: boolean = true) => {
    const copy = { ...state }
    // Set triggerRefresh to false when returning from claim detail
    // The grid gets refreshed through other means
    if (triggerRefresh) copy.Key = ++copy.Key

    copy.SelectAll = false
    copy.SelectedIds = []
    copy.SelectedGridKeys = []
    setState(copy)
    store.removeItem('grid-filter-select-all')
  }

  return (
    <ClaimActionContext.Provider
      value={{
        showingConfirmDelete,
        setShowingConfirmDelete,
        sendingAll,
        setSendingAll,
        handleSend,
        handleDelete,
        handleAction,
        handleRowAction,
        sendComplete,
        unselectAll,
        sendAllMsgData,
      }}
    >
      {children}
    </ClaimActionContext.Provider>
  )
}

export const useClaimActionContext = () => {
  const context = useContext(ClaimActionContext)
  if (!context) throw new Error('Context must be used within a Provider')
  return context
}
