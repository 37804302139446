import { enableReactUse } from '@legendapp/state/config/enableReactUse'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

// import reportWebVitals from 'reportWebVitals';
import { trellisRoutes } from 'trellis:routes/routes'

import 'trellis:theme/_dental-intel-theme.scss'
import 'trellis:theme/_rpractice-theme.scss'
import 'trellis:theme/_trellis-theme.scss'

enableReactUse()
// TODO: return and finish reportWebVitals for better insight
// reportWebVitals(console.log);

const queryClient = new QueryClient()

const App = () => {
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  const router = sentryCreateBrowserRouter(trellisRoutes)

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}

export default App
