import { add, endOfDay, format, startOfDay, sub } from "date-fns"
import { DateRange } from "../RangePicker"
import { RangeValue } from "rc-picker/lib/interface"

export const formatPanelYears = () => {
  const displayYears = document.getElementsByClassName('ant-picker-year-btn')
  for (let i = 0; i < displayYears.length; i++) {
    const displayYear = displayYears[i].innerHTML
    displayYears[i].innerHTML = parseInt(displayYear).toString().padEnd(4, '0')
  }
}

export const formatPickerInputDate = (event: KeyboardEvent) => {
  const val = event.target['value']
  const strippedInput = val.replaceAll("/", "")
  let newInput = "";
  for (let i = 0; i < strippedInput.length; i += 1) {
    if (i === 2 || i === 4) newInput += "/"

    newInput += strippedInput.charAt(i)
  }
  event.target['value'] = newInput
}

export const addListenerToPickerInput = (
  callback: (event: KeyboardEvent) => void,
) => {
  const pickerInputs = document.getElementsByClassName('ant-picker-input')
  for (let i = 0; i < pickerInputs.length; i++) {
    pickerInputs[i].children[0].addEventListener('keyup', callback)
  }
}

export const removeListenerFromPickerInput = (
  callback: (event: KeyboardEvent) => void,
) => {
  const pickerInputs = document.getElementsByClassName('ant-picker-input')
  for (let i = 0; i < pickerInputs.length; i++) {
    pickerInputs[i].children[0].removeEventListener('keyup', callback)
  }
}

export const setSelectedDateRange = (dateRange: DateRange) => {
  const today = format(new Date(), 'MM/dd/yyyy')
  const startDate = format(new Date(dateRange.dates[0]), 'MM/dd/yyyy')
  const endDate = format(new Date(dateRange.dates[1]), 'MM/dd/yyyy')
  const days = dateRange.numberOfDays

  setTimeout(() => {
    const ranges = document.querySelectorAll('.ant-picker-preset')
    if (!ranges) return
    ranges.forEach((range: HTMLElement) => {
      const text: string = range.innerText
      range.classList.remove('selected')
      switch (text) {
        case 'Today':
          if (startDate === today && endDate === today && days === 0)
            range.classList.add('selected')
          break
        case 'Tomorrow':
          if (
            startDate ===
              format(add(new Date(today), { days: 1 }), 'MM/dd/yyyy') &&
            days === 0
          )
            range.classList.add('selected')
          break
        case '3 Days':
          if (startDate === today && days === 3) range.classList.add('selected')
          break
        case '1 Week':
          if (startDate === today && days === 7) range.classList.add('selected')
          break
        case '7 Days':
          if (endDate === today && days === 7) range.classList.add('selected')
          break
        case '15 Days':
          if (endDate === today && days === 15) range.classList.add('selected')
          break
        case '30 Days':
          if (endDate === today && days === 30) range.classList.add('selected')
          break
        case '60 Days':
          if (endDate === today && days === 60) range.classList.add('selected')
          break
        case '90 Days':
          if (endDate === today && days === 90) range.classList.add('selected')
          break
        case 'Last 6 Months':
          if (endDate === today && days > 180 && days < 365)
            range.classList.add('selected')
          break
        case 'Last Year':
          if (endDate === today && days === 365) range.classList.add('selected')
          break
        case 'Last 2 Years':
          if (endDate === today && days > 365) range.classList.add('selected')
          break
      }
    })
  }, 100)
}

export const getDateRanges = () => {
  return {
    Today: [startOfDay(new Date()), endOfDay(new Date())],
    '7 Days': [startOfDay(sub(new Date(), { days: 7 })), endOfDay(new Date())],
    '30 Days': [
      startOfDay(sub(new Date(), { days: 30 })),
      endOfDay(new Date()),
    ],
    '60 Days': [
      startOfDay(sub(new Date(), { days: 60 })),
      endOfDay(new Date()),
    ],
    '90 Days': [
      startOfDay(sub(new Date(), { days: 90 })),
      endOfDay(new Date()),
    ],
    '3-6 mos.': [
      startOfDay(sub(new Date(), { days: 180 })),
      endOfDay(sub(new Date(), { days: 90 })),
    ],
  } as Record<string, RangeValue<Date> | (() => RangeValue<Date>)>
}

export const getAppoinmentDateRanges = () => {
  return {
    Today: [startOfDay(new Date()), endOfDay(new Date())],
    Tomorrow: [
      startOfDay(add(new Date(), { days: 1 })),
      endOfDay(add(new Date(), { days: 1 })),
    ],
    '3 Days': [startOfDay(new Date()), endOfDay(add(new Date(), { days: 3 }))],
    '1 Week': [startOfDay(new Date()), endOfDay(add(new Date(), { days: 7 }))],
  } as Record<string, RangeValue<Date> | (() => RangeValue<Date>)>
}

export const getArchiveDateRanges = () => {
  return {
    'Last 6 Months': [
      startOfDay(sub(new Date(), { months: 6 })),
      endOfDay(new Date()),
    ],
    'Last Year': [
      startOfDay(sub(new Date(), { years: 1 })),
      endOfDay(new Date()),
    ],
    'Last 2 Years': [
      startOfDay(sub(new Date(), { years: 2 })),
      endOfDay(new Date()),
    ],
  } as Record<string, RangeValue<Date> | (() => RangeValue<Date>)>
}

export const getSentAttachmentDateRanges = () => {
  return {
    Today: [startOfDay(new Date()), endOfDay(new Date())],
    '7 Days': [startOfDay(sub(new Date(), { days: 7 })), endOfDay(new Date())],
    '30 Days': [
      startOfDay(sub(new Date(), { days: 30 })),
      endOfDay(new Date()),
    ],
    '30-60 Days': [
      startOfDay(sub(new Date(), { days: 60 })),
      endOfDay(sub(new Date(), { days: 30 })),
    ],
    '60-90 Days': [
      startOfDay(sub(new Date(), { days: 90 })),
      endOfDay(sub(new Date(), { days: 60 })),
    ],
  } as Record<string, RangeValue<Date> | (() => RangeValue<Date>)>
}