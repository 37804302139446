import {
  CheckCircleFilled,
  CloseCircleFilled,
  HourglassTwoTone,
} from '@ant-design/icons'
import { useObservable } from '@legendapp/state/react'
import { notification, Progress } from 'antd'
import React, { ReactNode, useEffect } from 'react'

import { SendAllNotificationData } from 'trellis:components/claims/util/claimTypes'

const useSendAllNotification = (data: SendAllNotificationData) => {
  const state$ = useObservable({
    titleContent: (<></>) as ReactNode,
    progress: null as number | null,
    hideProgressBar: false,
    icon: null,
  })
  const { Status, IsComplete, SuccessCount, FailureCount } = data

  useEffect(() => {
    let newContent = true
    let match: RegExpMatchArray
    let processedClaims: number
    let totalClaims: number
    let progressStatus = 'active' as
      | 'normal'
      | 'exception'
      | 'active'
      | 'success'

    switch (true) {
      case IsComplete && FailureCount === 0:
        state$.titleContent.set(
          ` Success! ${SuccessCount} claim${
            SuccessCount === 1 ? '' : 's'
          } sent`,
        )
        state$.icon.set(
          <CheckCircleFilled style={{ color: 'var(--color-success)' }} />,
        )
        state$.progress.set(100)
        state$.hideProgressBar.set(false)
        progressStatus = 'success'
        newContent = true
        break

      case IsComplete && Status != null:
        state$.titleContent.set(`${SuccessCount} claim${
          SuccessCount === 1 ? ' was' : 's were'
        } processed.  
          ${FailureCount} claim${
            FailureCount === 1 ? '' : 's'
          } will remain unsent until
          validation is passed or the hold is released.`)
        state$.hideProgressBar.set(true)
        state$.icon.set(
          <CloseCircleFilled style={{ color: 'var(--error-100)' }} />,
        )
        newContent = true
        break

      case Status?.includes('BadWebsocketConnection'):
        state$.titleContent.set(
          <>
            {'Claims processed. '}
            <a
              href='javascript:void(0);'
              onClick={() => {
                location.reload()
              }}
            >
              Refresh
            </a>
            {'  to see updates.'}
          </>,
        )
        state$.hideProgressBar.set(true)
        state$.icon.set(
          <CheckCircleFilled style={{ color: 'var(--color-success)' }} />,
        )
        newContent = true
        break

      case Status?.includes('Claim processing started'):
        state$.titleContent.set('Sending Claims...')
        state$.hideProgressBar.set(true)
        state$.icon.set(
          <HourglassTwoTone
            style={{ color: 'var(--color-primary)' }}
            spin={true}
          />,
        )
        newContent = true
        break

      case /Processing claim (\d+) of (\d+)/.test(Status):
        match = Status.match(/(\d+) of (\d+)/) as RegExpMatchArray
        processedClaims = parseInt(match[1])
        totalClaims = parseInt(match[2])
        state$.progress.set((processedClaims / totalClaims) * 100)
        state$.titleContent.set('Processing Claims...')
        state$.icon.set(
          <HourglassTwoTone
            style={{ color: 'var(--color-primary)' }}
            spin={true}
          />,
        )
        state$.hideProgressBar.set(false)
        newContent = true
        break

      default:
        newContent = false
        break
    }
    if (Status === null) {
      notification.destroy()
    } else {
      if (newContent) {
        notification.open({
          key: `unique-notification-key`,
          message: state$.titleContent.get(),
          description: state$.hideProgressBar.get() ? null : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Progress
                percent={Math.round(state$.progress.get())}
                status={progressStatus}
                style={{ flex: 1 }}
              />
            </div>
          ),
          icon: state$.icon.get(),
          placement: 'top',
          style: {
            maxWidth: '600px',
            margin: '0 auto',
          },
          duration: 0,
          closeIcon: null,
          className: 'send-all-notification',
        })
      }
    }
  })

  return state$
}

export const SendAllNotification = ({
  data,
}: {
  data: SendAllNotificationData
}) => {
  useSendAllNotification(data)

  return null
}
