import { FC } from 'react'

import { SmartTable } from 'trellis:components/_siteWide/table/SmartTable'

import { useClaimArchiveContext } from '../context/ClaimArchiveContext'
import { getClaimArchiveGridColumns } from '../utils/get-claim-archive-grid-columns'
import { DateRange, RangePicker } from 'ui'
import { getArchiveDateRanges, setSelectedDateRange } from 'ui'
import { endOfDay, intervalToDuration, startOfDay } from 'date-fns'

export const ClaimArchiveGrid: FC<{ toolbar: JSX.Element }> = ({ toolbar }) => {
  const { dateRange, getClaims, loading, setDateRange, setState, state } =
    useClaimArchiveContext()

  const setDateFilter = (date: DateRange['dates']) => {
    const durationAsDays: number = intervalToDuration({
      start: date[0],
      end: date[1],
    }).days
    setDateRange({
      dates: [startOfDay(date[0]), endOfDay(date[1])],
      numberOfDays: durationAsDays,
    })

    if (state) {
      const stateCopy = { ...state }
      stateCopy.Key = ++stateCopy.Key
      stateCopy.Filters.CurrentPage = 1
      setState(stateCopy)
    }
  }

  const handleDateRangeSelect = (
    dates: DateRange['dates'],
    info: { range: 'start' | 'end' } = null,
  ) => {
    if (info?.range === 'end') setDateFilter(dates)
  }

  const dateRangePicker = (
    <RangePicker 
      allowClear={false}
      format='MM/dd/y'
      onCalendarChange={(date, _dateString, info) =>
        date[0] && handleDateRangeSelect(date, info)
      }
      onOpenChange={() => setSelectedDateRange(dateRange)}
      ranges={getArchiveDateRanges()}
      value={dateRange?.dates}
    />
  )

  const defaultSearch = { Name: '' }

  return (
    <SmartTable
      columns={getClaimArchiveGridColumns(state)}
      datePicker={dateRangePicker}
      defaultSearch={defaultSearch}
      getData={getClaims}
      isFullViewport={true}
      loading={loading}
      rowKey='CLAIM_ID'
      setState={setState}
      state={state}
      tableClass='archive-table'
      toolbar={toolbar}
    />
  )
}
