import { Col, Row } from 'antd'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'

import { FormAutoComplete, FormInput } from 'ui'
import { validateNPI } from 'utils'

import { LDFlags$ } from 'trellis:state/globalState'

import { EligibilityPatient } from '../../../../../api/eligibility/eligibility-client'
import { useEligibilityContext } from '../../context/EligibilityContext'
import { FilteredOption } from '../../form/utilities/eligibilityFormTyping'
import { ProviderDetails } from '../../utilities/eligibilityTyping'

interface ProviderControlsProps {
  isModal?: boolean
  patient: EligibilityPatient
  setPatient: Dispatch<SetStateAction<EligibilityPatient>>
}

const ProviderControls: FC<ProviderControlsProps> = ({
  isModal = false,
  patient,
  setPatient,
}) => {
  const flags = LDFlags$.use()
  const { providers } = useEligibilityContext()

  const options: FilteredOption[] = providers.map(
    (provider: ProviderDetails, index: number) => ({
      key: index,
      value: `${provider.ProviderLastName}, ${provider.ProviderFirstName}`,
    }),
  )
  const [filteredOptions, setFilteredOptions] =
    useState<FilteredOption[]>(options)

  const updateProviderControls = (value: string) => {
    if (!flags.operaVynesyncAutomatedeligibility || !value) return

    const patientCopy = { ...patient }
    if (value.includes(',')) {
      const values = value.split(',')
      const match = providers.find(
        (provider: ProviderDetails) =>
          provider.ProviderLastName?.toLowerCase()?.trim() ===
            values[0]?.toLowerCase()?.trim() &&
          provider.ProviderFirstName?.toLowerCase()?.trim() ===
            values[1]?.toLowerCase()?.trim(),
      )

      if (match) {
        patientCopy.ProviderFirstName = match.ProviderFirstName
        patientCopy.ProviderLastName = match.ProviderLastName
        patientCopy.IndividualNpi = match.ProviderNPI
      } else {
        patientCopy.ProviderFirstName = values[1]?.toLowerCase()?.trim()
        patientCopy.ProviderLastName = values[0]?.toLowerCase()?.trim()
      }
    }

    setPatient({ ...patientCopy })
  }

  const setFiltersOnSearch = (value: string) => {
    if (value) {
      value = value?.toLowerCase()
      const filteredOptions: FilteredOption[] = options.filter(
        (option: FilteredOption) => option.value?.toLowerCase().includes(value),
      )
      setFilteredOptions(filteredOptions)
    } else setFilteredOptions(options)
  }

  return (
    <Col span={24}>
      {(flags.eligibilityUsevynedentalsync ||
        flags.operaVynesyncAutomatedeligibility) &&
      patient.PatientId !== '0' &&
      !isModal ? (
        <Row>
          <Col span={24}>
            <FormAutoComplete
              customHandler={updateProviderControls}
              label='Provider'
              name='ProviderName'
              onSearchHandler={setFiltersOnSearch}
              options={filteredOptions}
              required
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row gutter={8}>
            <Col
              span={12}
              className='gutter-row'
            >
              <FormInput
                label='Last or Billing Name'
                name='ProviderLastName'
                required
              />
            </Col>
            <Col
              span={12}
              className='pl-150'
            >
              <FormInput
                label='First Name'
                name='ProviderFirstName'
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormInput
                label='Provider NPI'
                name='IndividualNpi'
                required
                validator={validateNPI}
              />
            </Col>
          </Row>
        </>
      )}
    </Col>
  )
}

export default ProviderControls
