import GlobalState from 'trellis:state/globalState'
import trellisConfiguration from 'trellis:utilities/config'

import { IAuthenticatedApiModel } from '../../utilities/api'
import {
  BillingStatementsApiFactory,
  Configuration,
  StatementSettings,
  StatementsQueryRequest,
} from './statements-client'

const trellisApi: string = trellisConfiguration.trellis_apiUrl

const getSettingsConfig = () =>
  new Configuration({
    basePath: trellisApi + 'trellis-billing',
    accessToken: GlobalState.Auth.AccessToken.peek(),
  })

export const GetStatements = async (
  authentication: IAuthenticatedApiModel,
  request: StatementsQueryRequest,
) => {
  return await BillingStatementsApiFactory(
    getSettingsConfig(),
  ).billingStatementsGetStatements(parseInt(authentication.CustomerId), request)
}

export const GetStatementFiles = async (
  authentication: IAuthenticatedApiModel,
  request: StatementsQueryRequest,
) => {
  return await BillingStatementsApiFactory(
    getSettingsConfig(),
  ).billingStatementsGetStatementFiles(
    parseInt(authentication.CustomerId),
    request,
  )
}

export const GetFilesCsv = async (
  authentication: IAuthenticatedApiModel,
  request: StatementsQueryRequest,
) => {
  return await BillingStatementsApiFactory(
    getSettingsConfig(),
  ).billingStatementsGetDownloadStatementFiless(
    parseInt(authentication.CustomerId),
    request,
  )
}

export const GetStatementsCsv = async (
  authentication: IAuthenticatedApiModel,
  request: StatementsQueryRequest,
) => {
  return await BillingStatementsApiFactory(
    getSettingsConfig(),
  ).billingStatementsGetDownloadStatements(
    parseInt(authentication.CustomerId),
    request,
  )
}

export const GetStatementSettings = async (
  authentication: IAuthenticatedApiModel,
) => {
  return await BillingStatementsApiFactory(
    getSettingsConfig(),
  ).billingStatementsGetSettings(parseInt(authentication.CustomerId))
}

export const UpdateStatementSettings = async (
  authentication: IAuthenticatedApiModel,
  statementSettings: StatementSettings,
) => {
  return await BillingStatementsApiFactory(
    getSettingsConfig(),
  ).billingStatementsUpdateSettings(
    parseInt(authentication.CustomerId),
    statementSettings,
  )
}

export const GetPaymentsStatus = async () => {
  return await BillingStatementsApiFactory(
    getSettingsConfig(),
  ).billingStatementsHasStatements(
    parseInt(GlobalState.Auth.CustomerId.peek()),
  )
} 
