import { LeftCircleOutlined } from '@ant-design/icons'
import { Checkbox, Col, Modal, Row } from 'antd'
import { isValid } from 'date-fns'
import { FC, useEffect, useState } from 'react'

import { Button } from 'ui'

import { formatDateFromString } from '../../../../utilities/general'
import { handleDateOfServiceDisplay } from '../../shared/sharedFunctions'
import { ImageDetailModalProps } from '../imageSync.interface'

import './imageDetailsModal.scss'

const ImageDetailsModal: FC<ImageDetailModalProps> = ({
  attachmentInformation,
  viewImageSyncImage,
  selectedRowKeys,
  handleRowSelection,
  isImageDetailsVisible,
  setImageDetailsVisible,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [imageToDisplay, setImageToDisplay] = useState<string>(null)

  useEffect(() => {
    setLoading(true)

    const selectedRow = selectedRowKeys.find(
      (selectedRowKey) => viewImageSyncImage.key === selectedRowKey,
    )
    if (selectedRow) {
      setIsChecked(!isChecked)
    }

    setImageToDisplay(URL.createObjectURL(viewImageSyncImage.imageData))

    setLoading(false)
  }, [])

  const handleSelected = () => {
    const checkboxChecked = !isChecked
    const selectedRowKeysCopy = [...selectedRowKeys]

    const selectedRow = selectedRowKeysCopy.find(
      (selectedRowKey) => viewImageSyncImage.key === selectedRowKey,
    )
    if (checkboxChecked && !selectedRow) {
      selectedRowKeysCopy.push(viewImageSyncImage.key)
      handleRowSelection(selectedRowKeysCopy)
    } else if (!checkboxChecked && selectedRow) {
      const index = selectedRowKeysCopy.indexOf(viewImageSyncImage.key)
      if (index !== -1) {
        selectedRowKeysCopy.splice(index, 1)
        handleRowSelection(selectedRowKeysCopy)
      }
    }
    setIsChecked(checkboxChecked)
  }

  return (
    <Modal
      className='image-details-modal'
      title={
        <Row>
          <Col span={12}>
            <Button
              className='back-to-all'
              disabled={loading}
              icon={<LeftCircleOutlined className='fs-125' />}
              label='Back to List'
              onClick={() => setImageDetailsVisible(!isImageDetailsVisible)}
              type='text'
            />
          </Col>
          <Col
            span={12}
            className='image-details-modal__header-actions'
          >
            <Button
              label='Select Image'
              type={isChecked ? 'primary' : 'default'}
              onClick={() => handleSelected()}
            >
              <div className='image-details-modal__header-actions-checkbox'>
                <Checkbox
                  disabled={loading}
                  checked={isChecked}
                  onChange={() => handleSelected()}
                />
              </div>
            </Button>
          </Col>
        </Row>
      }
      visible={isImageDetailsVisible}
      closable={false}
      maskClosable={false}
      onCancel={() => setImageDetailsVisible(!isImageDetailsVisible)}
      width={1000}
      footer={null}
    >
      <Row className='image-details-modal__patient-info'>
        <Col span={12}>
          <p className='fs-125'>
            <b>
              {attachmentInformation.patientFirstName}{' '}
              {attachmentInformation.patientLastName}
            </b>
          </p>
        </Col>
        <Col span={4}>
          <p className='fs-125'>
            <b>Image Type</b>
          </p>
        </Col>
        <Col span={4}>
          <p className='fs-125'>
            <b>Orientation</b>
          </p>
        </Col>
        <Col span={4}>
          <p className='fs-125'>
            <b>Date Taken</b>
          </p>
        </Col>
      </Row>
      <Row className='image-details-modal__patient-info'>
        <Col span={12}>
          <p className='fs-075 image-details-modal__patient-dob'>
            Date of Service:{' '}
            {handleDateOfServiceDisplay(
              attachmentInformation.dateOfServiceFrom,
              attachmentInformation.dateOfServiceTo,
            )}
          </p>
        </Col>
        <Col span={4}>
          <p className='fs-075 image-details-modal__patient-dob'>
            {viewImageSyncImage.imageType}
          </p>
        </Col>
        <Col span={4}>
          <p className='fs-075 image-details-modal__patient-dob'>
            {viewImageSyncImage.orientation}
          </p>
        </Col>
        <Col span={4}>
          <p className='fs-075 image-details-modal__patient-dob'>
            {isValid(new Date(viewImageSyncImage.imageDateTaken))
              ? formatDateFromString(
                  viewImageSyncImage.imageDateTaken,
                  'MM/dd/yyy',
                )
              : ''}
          </p>
        </Col>
      </Row>
      <Row className='image-details-modal__image'>
        <Col
          span={24}
          className='image-details-modal__image-container'
        >
          <img src={imageToDisplay} />
        </Col>
      </Row>
    </Modal>
  )
}

export default ImageDetailsModal
