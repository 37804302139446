import trellisConfiguration from 'trellis:utilities/config'

import { IAuthenticatedApiModel } from '../../utilities/api'
import { CarrierDirectoryApiFactory, Configuration } from './carrier-client'

const trellisApi: string = trellisConfiguration.trellis_apiUrl

export const GetCarrierList = async (
  authentication: IAuthenticatedApiModel,
) => {
  return await CarrierDirectoryApiFactory(
    new Configuration({
      basePath: trellisApi + 'carrier-directory',
      accessToken: authentication.AccessToken,
    }),
  ).carrierDirectoryCarriers()
}

export const ToggleCarrierPreference = async (
  authentication: IAuthenticatedApiModel,
  carrierId: string,
) => {
  return await CarrierDirectoryApiFactory(
    new Configuration({
      basePath: trellisApi + 'carrier-directory',
      accessToken: authentication.AccessToken,
    }),
  ).carrierDirectoryToggleCarrierPreference(carrierId)
}

export const GetNeaCarrierRequirements = async (
  authentication: IAuthenticatedApiModel,
  neaMasterId: number,
) => {
  return await CarrierDirectoryApiFactory(
    new Configuration({
      basePath: trellisApi + 'carrier-directory',
      accessToken: authentication.AccessToken,
    }),
  ).carrierDirectoryCarrierRequirements(neaMasterId)
}
