import { EditOutlined, SendOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { isValid } from 'date-fns'
import { FC } from 'react'

import { Button } from 'ui'

import { formatDateFromString } from '../../../utilities/general'
import { PatientInfo } from '../AttachmentsTypes'

import './AttachmentView.scss'

interface AttachmentViewHeaderProps {
  loading: boolean
  patientInfo: PatientInfo
  carrier: { carrierReferenceNumber: string; carrierName: string }
  handleOpenPatientModal: () => void
  handleSendConfirm: () => void
  handleCancelConfirm: () => void
  handleSaveAndClose: (isHold: boolean) => Promise<void>
}

const AttachmentViewHeader: FC<AttachmentViewHeaderProps> = ({
  loading,
  patientInfo,
  carrier,
  handleOpenPatientModal,
  handleSendConfirm,
  handleCancelConfirm,
  handleSaveAndClose,
}) => {
  return (
    <>
      <Row
        className='mb-100'
        justify={'space-between'}
      >
        <p className='fs-125'>New Attachment</p>
        <div className='sa-edit__header-actions'>
          <Button
            disabled={loading}
            label='Cancel'
            onClick={handleCancelConfirm}
          />
          <Button
            disabled={loading}
            label='Hold'
            onClick={() => handleSaveAndClose(true)}
          />
          <Button
            disabled={loading}
            label='Save & Exit'
            onClick={() => handleSaveAndClose(false)}
          />
          <Button
            disabled={loading}
            label='Send'
            icon={<SendOutlined />}
            type='primary'
            onClick={handleSendConfirm}
          />
        </div>
      </Row>
      <Row className='sa-edit__patient-info'>
        <Col span={6}>
          <p className='fs-125'>
            <b>
              {patientInfo?.patientFirstName &&
                `${patientInfo.patientFirstName}`}{' '}
              {patientInfo?.patientLastName &&
                ` ${patientInfo.patientLastName}`}
            </b>
          </p>
        </Col>
        <Col span={6}>
          <p className='fs-075'>
            {patientInfo ? patientInfo.carrierName?.toUpperCase() : ''}
          </p>
        </Col>
      </Row>
      <Row className='sa-edit__patient-info'>
        <Col span={6}>
          <p className='fs-075 sa-edit__patient-dob'>
            {patientInfo && isValid(new Date(patientInfo?.patientDateOfBirth))
              ? formatDateFromString(
                  patientInfo.patientDateOfBirth,
                  'MM/dd/yyy',
                )
              : ''}
          </p>
        </Col>
        <Col span={6}>
          <p className='fs-075'>
            <b>{patientInfo ? `Carrier ID: ${patientInfo.carrierId}` : ''}</b>
            <Button
              disabled={loading}
              type='text'
              label='Edit'
              icon={<EditOutlined />}
              className='fs-075 sa-edit__patient-edit'
              onClick={handleOpenPatientModal}
            />
          </p>
        </Col>
      </Row>
    </>
  )
}

export default AttachmentViewHeader
