import { FC } from 'react'

import { ImageDataProps } from '../AttachmentsTypes'

import { DatePicker } from 'ui'
import { endOfDay, isBefore } from 'date-fns'
import { AttachmentDatePickerConstants } from 'trellis:components/attachmentsShared/shared/sharedConstants'

import './ImageDateTaken.scss'

type ImageDateTakenProps = {
  record: ImageDataProps
  updateImageData: any
  index?: number
  rowId?: number
}
const ImageDateTaken: FC<ImageDateTakenProps> = ({
  record,
  updateImageData,
  index = 0,
}) => {
  return (
    <DatePicker 
      value={record.dateTaken ? new Date(record.dateTaken) : null}
      format='MM/DD/YYYY'
      placeholder='MM/DD/YYYY'
      onChange={(_: Date, dateString: string) =>
        updateImageData('DateTaken', dateString, index)
      }
      disabledDate={(date) =>
        date &&
        (isBefore(
          date,
          new Date(AttachmentDatePickerConstants.MINIMUM_DISABLED_DATE),
        ) ||
          date > endOfDay(new Date()))
      }
    />
  )
}

export default ImageDateTaken
