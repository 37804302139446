import { Button, Modal } from 'antd'
import React from 'react'

import { useClaimGridContext } from '../../context/claimGridContext'

export const TinHoldModal = () => {
  const { tinHolds, setTinHolds } = useClaimGridContext()

  const handleConfirm = () => {
    setTinHolds([])
  }

  return (
    <Modal
      title={<b className='fs-150'>New TIN(s) Detected</b>}
      visible={tinHolds}
      closable={false}
      footer={[
        <Button
          key='confirm'
          type='primary'
          onClick={handleConfirm}
        >
          Confirm
        </Button>,
      ]}
    >
      <div className='fs-100'>
        We've identified one or more new Tax ID(s) in your latest batch, which
        are not registered to your account.
      </div>
      <div className='fs-100 mt-050'>
        {' '}
        Please click
        <a
          href='https://calendly.com/rcmconsult/practice-revenue-consultation-vyne?utm_source=inapp'
          target='_blank'
          className='ml-025 mr-025'
          rel='noreferrer'
        >
          <strong>here</strong>
        </a>
        to schedule an account review with your Vyne Dental Practice Revenue
        Advisor, or call us at
        <a
          href='tel:866-712-9584'
          className='ml-025 mr-025'
        >
          866-712-9584
        </a>
        .
      </div>
      <div className='fs-100 mt-050'>Thank you!</div>
      <div className='mt-100 mb-100'>
        {tinHolds.map((id: string) => {
          return (
            <div
              key={id}
              className='fs-100'
            >
              {id}
            </div>
          )
        })}
      </div>
      <div className='fs-100'>
        All claims with the Tax ID(s) listed above will be rejected.
      </div>
    </Modal>
  )
}
