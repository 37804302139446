import GlobalState from 'trellis:state/globalState'
import trellisConfiguration from 'trellis:utilities/config'

import { IAuthenticatedApiModel } from '../../utilities/api'
import {
  Configuration,
  EligibilityApiFactory,
  EligibilityPatient,
  EligibilityPatientsRequest,
  EligibilitySettings,
} from './eligibility-client'

const trellisApi: string = trellisConfiguration.trellis_apiUrl
export const GetAppointments = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  eligibilityPatientsRequest: EligibilityPatientsRequest,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: authentication.AccessToken,
    }),
  ).eligibilityGetAppointments(customerId, eligibilityPatientsRequest)
}

export const GetPatients = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  eligibilityPatientsRequest: EligibilityPatientsRequest,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: authentication.AccessToken,
    }),
  ).eligibilityGetEligibilityPatients(customerId, eligibilityPatientsRequest)
}

export const GetPatient = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  patientId: string,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: authentication.AccessToken,
    }),
  ).eligibilityGetPatientDetails(customerId, patientId)
}

export const CreatePatient = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  patient: EligibilityPatient,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: authentication.AccessToken,
    }),
  ).eligibilityCreatePatientDetails(customerId, patient)
}

export const UpdatePatient = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  patientId: string,
  patient: EligibilityPatient,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: authentication.AccessToken,
    }),
  ).eligibilityUpdatePatientDetails(customerId, patientId, patient)
}

export const VerifyPatient = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  patientId: string,
  patient: EligibilityPatient,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: authentication.AccessToken,
    }),
  ).eligibilityVerifyPatientEligibility(customerId, patientId, patient)
}

export const GetProviders = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: authentication.AccessToken,
    }),
  ).eligibilityGetProviders(customerId)
}

export const Get271Response = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  historyId: number,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: authentication.AccessToken,
    }),
  ).eligibilityGet271Response(customerId, historyId)
}

export const GetOnetimeHistories = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: authentication.AccessToken,
    }),
  ).eligibilityGetOnetimeHistories(customerId)
}

export const GetInsuranceFormResponse = async (
  authentication: IAuthenticatedApiModel,
  customerId: number,
  formId: number,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: authentication.AccessToken,
    }),
  ).eligibilityGetPatientFormResponse(customerId, formId)
}

export const SaveSettings = async (
  customerId: number,
  eligibilitySettings: EligibilitySettings,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityUpdateEligibilitySettings(customerId, eligibilitySettings)
}

export const GetSettings = async (
  customerId: number,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityGetEligibilitySettings(customerId)
}

export const GetStructuredView = async (
  customerId: number,
  historyId: number,
) => {
  return await EligibilityApiFactory(
    new Configuration({
      basePath: trellisApi + 'trellis-eligibility',
      accessToken: GlobalState.Auth.AccessToken.peek(),
    }),
  ).eligibilityGetStructuredView(customerId, historyId)
}
