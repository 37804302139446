import { Tabs } from 'antd'
import React, { useEffect } from 'react'

import { InvoiceHeader } from '../_siteWide/payment/invoice/invoiceHeader'
import { InvoiceSummary } from '../_siteWide/payment/invoice/invoiceSummary'
import { formatDateString, showMessage } from '../../utilities/general'
import {
  SubmitPaymentContextProvider,
  SubmitPaymentModel,
  useSubmitPaymentContext,
} from './context/submitPaymentContext'
import { PaymentForm } from './form/paymentForm'

import './submitPayment.scss'

import { useNavigate } from 'react-router-dom'

import { LogError } from 'utils'

import { LDFlags$ } from 'trellis:state/globalState'

import { useInvoiceContext } from '../_siteWide/payment/context/invoiceContext'
import { GetTsysPaymentInfo } from '../../api/billing/billingApi'
import { useGlobalContext } from '../../context/GlobalContextProvider'

const { TabPane } = Tabs

const SubmitPayment = () => {
  //TODO: Remove this page after 01/2024
  const legacyPaymentsDisabled = LDFlags$.trellisDisableLegacyPayments.use()
  const navigate = useNavigate()
  if (legacyPaymentsDisabled) {
    navigate('/', {
      replace: true,
    })
  }

  const {
    payment,
    setPayment,
    setSavedCards,
    setUsingSavedCard,
    setLoadingCards,
  } = useSubmitPaymentContext()

  const { authentication } = useGlobalContext()

  const {
    selectedTin,
    updateSelectedTin,
    selectedInvoice,
    invoices,
    invoicesLoading,
  } = useInvoiceContext()

  useEffect(() => {
    if (authentication?.AccessToken) {
      loadTsys()
    }
  }, [])

  const loadTsys = () => {
    GetTsysPaymentInfo(authentication)
      .then(({ data }) => {
        const paymentGuid = crypto.randomUUID()
        const copy = { ...payment, InternalPaymentGuid: paymentGuid }
        const savedCard = data?.SavedCards
        if (savedCard?.length) {
          copy.SelectedSavedCard = savedCard[0].WalletID
          setPayment(copy)
        } else {
          copy.SelectedSavedCard = 'new'
          setUsingSavedCard(false)
          setPayment(copy)
        }
        const model: SubmitPaymentModel = data?.PaymentViewModel

        // Using localStorage because React state cannot be used inside tsepHandler
        function tsepHandler(eventType: any, event: any) {
          //no need to reset the card if they accidentally click in/out of the field
          if (
            eventType !== 'FieldValidationErrorEvent' &&
            eventType !== 'TokenEvent'
          ) {
            return
          }

          window.localStorage.removeItem('tsep')

          if (event.responseCode === 'TSEPERR105') {
            window.localStorage.setItem('validCard', 'false')
            window.dispatchEvent(new Event('storage'))
          } else if (
            event.responseCode === 'TSEPERR102' ||
            event.responseCode === 'TSEPERR103'
          ) {
            window.localStorage.setItem('validDate', 'false')
            window.dispatchEvent(new Event('storage'))
          }

          if (eventType === 'TokenEvent') {
            window.localStorage.setItem('tsep', JSON.stringify(event))
            window.localStorage.setItem('validCard', 'true')
            window.localStorage.setItem('validDate', 'true')
            window.dispatchEvent(new Event('storage'))
          }
        }

        if (model) {
          const copy: string = tsepHandler.toString()
          let tsepHandlerText = ''
          if (!copy.includes('tsepHandler')) {
            const insertLocation = copy.indexOf('(')
            //remove letter function name (ie function b()) and replace with correct tsepHandler name
            tsepHandlerText = copy.replace(
              /function [a-z]/,
              'function tsepHandler',
            )
          } else {
            tsepHandlerText = copy
          }
          model.PaymentDay = formatDateString(
            model.CustomRecurringStartDate,
            'd',
          )
          model.InternalPaymentGuid = paymentGuid
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.src = `${model.TsepBaseUrl}${model.DeviceId}?${model.Manifest}`
          script.async = true
          document.head.appendChild(script)

          const handler = document.createElement('script')
          handler.setAttribute('type', 'text/javascript')
          handler.setAttribute('id', 'TsepHandler')
          handler.text = tsepHandlerText
          document.head.appendChild(handler)

          if (savedCard?.length) {
            model.SelectedSavedCard = savedCard[0].WalletID?.toString()
          } else {
            model.SelectedSavedCard = 'new'
          }
        }

        setPayment(model)
        setSavedCards(savedCard)
        setLoadingCards(false)
      })
      .catch((e) => {
        showMessage(
          'There was an error loading wallets. Please use a new card.',
        )
        setLoadingCards(false)
        setUsingSavedCard(false)
        LogError(e, 'Failed to get payment model')
      })
  }

  return (
    <article className='page-content__container page-content__container--with-page-header'>
      <InvoiceHeader
        invoice={selectedInvoice}
        invoices={invoices}
        selectedTin={selectedTin}
        initialLoad={invoicesLoading}
        updateSelectedTin={updateSelectedTin}
      />
      <Tabs className='pt-100'>
        <TabPane
          key='SetupPayment'
          tab='Payment'
        >
          <PaymentForm />
        </TabPane>
        <TabPane
          key='RecentInvoices'
          tab='Recent Invoices'
        >
          <InvoiceSummary
            loading={invoicesLoading}
            invoice={selectedInvoice}
          />
        </TabPane>
      </Tabs>
    </article>
  )
}

export default () => (
  <SubmitPaymentContextProvider>
    <SubmitPayment />
  </SubmitPaymentContextProvider>
)
