import { ConfigProvider } from 'antd'

import { AppThemeType } from 'trellis:state/globalState'

// theme will be set on main layouts
export const setTheme = (appTheme: AppThemeType) => {
  const root = document.querySelector(':root') as HTMLElement

  // themeColor is for ant design config
  let themeColor: string
  switch (appTheme) {
    case 'dental-intel-theme':
      themeColor = '#0060C6'
      break
    case 'rpractice-theme':
      themeColor = '#307596'
      break
    case 'trellis-theme':
    default:
      themeColor = '#91C73D'
      break
  }

  ConfigProvider.config({
    theme: {
      primaryColor: themeColor,
    },
  })

  // this is for our internal css variables
  // color is handled with hsl
  if (appTheme === 'dental-intel-theme') {
    root?.style?.setProperty('--color', '211, 100%')
    root?.style?.setProperty('--lightness', '39%')
  } else if (appTheme === 'rpractice-theme') {
    root?.style?.setProperty('--color', '199, 52%')
    root?.style?.setProperty('--lightness', '39%')
  } else if (appTheme === 'trellis-theme') {
    root?.style?.setProperty('--color', '83, 55%')
    root?.style?.setProperty('--lightness', '51%')
  }
}
