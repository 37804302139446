import React from 'react'

import styles from './ContactUs.module.scss'

export interface ContactUsProps {
  phone: string | undefined
  email: string | undefined
  liveChat: string | undefined
}

const ContactUs = ({ phone, liveChat }: ContactUsProps) => {
  return (
    <div className='contact-us'>
      <p>
        <b>Contact Us</b>
      </p>
      <table className={styles['contact-us-table']}>
        <tr style={{ color: 'grey' }}>
          <td>{'Live Chat'}</td>
          <td>{phone ? 'Phone' : ''}</td>
        </tr>
        <tr>
          <td>
            {liveChat && (
              <a
                href={liveChat}
                target='_blank'
                rel='noopener noreferrer'
              >
                Chat Now
              </a>
            )}
          </td>
          <td>{phone ? phone : ''}</td>
        </tr>
      </table>
    </div>
  )
}

export default ContactUs
