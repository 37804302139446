import { AxiosResponse } from 'axios'
import { endOfDay, format, startOfDay, sub } from 'date-fns'
import { render } from 'json2html'
import { createContext, useContext, useState } from 'react'

import { SearchArchivedClaimsResponse } from 'trellis:api/claim/claim-client'
import { GetArchiveClaims } from 'trellis:api/claim/claimApi'
import GlobalState from 'trellis:state/globalState'
import { printHtmlContent, showMessage } from 'trellis:utilities/general'

import { claimArchiveColumns } from '../utils/claim-archive-column-selector-list'

const ClaimArchiveContext = createContext(null)
const store = window.localStorage

export const ClaimArchiveContextProvider = ({ children }: any) => {
  const auth = GlobalState.Auth.use()

  const storageColumns = JSON.parse(store.getItem('claim-archive-columns'))
  const defaultColumns = [
    'Actions',
    'TRANSMIT_DATE',
    'PAT_NAME',
    'SUB_NAME',
    'CARRIER',
    'STATUS',
    'Status_Description',
  ]

  const [loading, setLoading] = useState(false)
  const [ignoreClaim, setIgnoreClaim] = useState(false)
  const [state, setState] = useState({
    Key: 0,
    Data: [],
    OriginalData: [],
    Total: 0,
    Search: null,
    Ignored: false,
    VisibleColumns: storageColumns || defaultColumns,
    Filters: {
      CurrentPage: 1,
      PageSize: 50,
      Config: {},
    },
  })
  const [dateRange, setDateRange] = useState({
    dates: [startOfDay(sub(new Date(), { months: 6 })), endOfDay(new Date())],
    numberOfDays: 183,
  })

  const getClaims = async () => {
    setLoading(true)
    const copy = { ...state }
    const columns: any = copy.VisibleColumns
    const response: AxiosResponse<SearchArchivedClaimsResponse> =
      await GetArchiveClaims(
        dateRange.dates[0].toISOString(),
        dateRange.dates[1].toISOString(),
        state.Search,
        auth,
      )

    if (response?.data) {
      const data = response.data
      copy.Data = data.Data
        ? data.Data.filter((d: any) => d.Ignored === copy.Ignored)
        : []
      copy.Total = copy.Data.length || 0
      copy.VisibleColumns = columns
    }

    setState(copy)
    setLoading(false)
  }

  const toggleIgnore = () => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.Ignored = !state.Ignored
    setState(copy)
  }

  const print = () => {
    if (!state.Data.length) {
      showMessage('There are no claims to print.', 'error')
      return
    }

    printHtmlContent(render(getDownloadRows(state.Data)))
  }

  const getDownloadRows = (data: any) => {
    const exportedData: any = []
    data.map((d: any) => {
      const item = {}
      Object.keys(d).map((k: string) => {
        const match: any =
          claimArchiveColumns.filter((c: any) => c.property === k)[0] || k
        if (match && state.VisibleColumns.includes(k)) {
          const label = match.label
          let value = d[k]
          if (
            label === 'Transmit Date' ||
            label === 'Service Date' ||
            label === 'Last Update'
          )
            value = format(new Date(), 'MM/dd/yyyy')

          item[label] = value
        }
      })

      exportedData.push(item)
    })

    return exportedData
  }

  const searchTable = (value: string) => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.Search = value
    setState(copy)
  }

  const claimRequest = () => {
    const data = new FormData()
    data.append('draw', '1')
    data.append('columns', '')
    data.append('full', 'true')
    data.append('minDate', format(dateRange.dates[0], 'MMMM dd, yyyy'))
    data.append('maxDate', format(dateRange.dates[1], 'MMMM dd, yyyy'))
    data.append('archiveText', state.Search)
    return data
  }

  return (
    <ClaimArchiveContext.Provider
      value={{
        // state,
        loading,
        setLoading,

        state,
        setState,
        dateRange,
        setDateRange,
        ignoreClaim,
        setIgnoreClaim,

        // functions
        getClaims,
        claimRequest,
        toggleIgnore,
        print,
        getDownloadRows,
        searchTable,

        // columns
        defaultColumns,
        storageColumns,
      }}
    >
      {children}
    </ClaimArchiveContext.Provider>
  )
}

export const useClaimArchiveContext = () => {
  const context = useContext(ClaimArchiveContext)
  if (!context) throw new Error('Context must be used within a Provider')
  return context
}
