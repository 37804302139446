import { LayoutOutlined } from '@ant-design/icons'
import { FC, useEffect, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useLocation } from 'react-router-dom'

import { ActiveServicesObject, LogError, useTimeout } from 'utils'

import DentalIntelligenceDotLogo from '../../assets/di-dot-white.png'
import DentalIntelligenceInsuranceLogo from '../../assets/di-insurance-light.png'
import NavMenuCategory from './NavMenuCategory/NavMenuCategory'
import { getNavMenuOptions, NavMenuOptions } from './utilities/navOptions'

import './SidebarNavigation.scss'

import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'

export type UserType = 'DI' | 'RPractice' | 'Trellis'

export type ProductType =
  | 'SSO'
  | 'Trellis'
  | 'Opera'
  | 'FastAttach'
  | 'DentalIntel'
  | 'Simplifeye'

export interface SsoTokenUrlRequest {
  destinationProduct: ProductType
  returnPath: string
}

export interface SsoOutboundUrlResponse {
  ssoUrl?: string | null
}

export const ProductTypeValues = {
  Sso: 'SSO',
  Trellis: 'Trellis',
  Opera: 'Opera',
  FastAttach: 'FastAttach',
  DentalIntel: 'DentalIntel',
  Simplifeye: 'Simplifeye',
} as const

interface SidebarNavigationProps {
  activeServices: ActiveServicesObject | null
  appTheme: 'dental-intel-theme' | 'rpractice-theme' | 'trellis-theme'
  getSsoUrl: (
    ssoTokenUrlRequest: SsoTokenUrlRequest,
  ) => Promise<AxiosResponse<SsoOutboundUrlResponse, any>>
  flags: { [key: string]: any }
  userInfo: any // TODO: update type
  facilityID: string | null
  pmsSoftware: string
}

// NOTE: goal is for next iteration to use active services and not flags as well as not be relient on api and userInfo since those are Trellis specific
export const SidebarNavigation: FC<SidebarNavigationProps> = ({
  activeServices,
  appTheme,
  facilityID,
  flags,
  getSsoUrl,
  pmsSoftware,
  userInfo,
}) => {
  const storedSidebarCollapseSetting = localStorage.getItem(
    'vyne-sidebar-collapse',
  )
  const location = useLocation()

  const [collapsedLogo, setCollapsedLogo] = useState<string>()
  const [currentMenuCategory, setCurrentMenuCategory] = useState<string>()
  const [currentPage, setCurrentPage] = useState<string>()
  const [mainLogo, setMainLogo] = useState<string>()
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false)
  const [menuScrollDelay, setMenuScrollDelay] = useState<number | null>(null)
  const [navMenuOptions, setNavMenuOptions] = useState<NavMenuOptions[] | null>(
    null,
  )
  const [userType, setUserType] = useState<UserType>()
  const [tooltipDelay, setTooltipDelay] = useState<number | null>(null)

  const isSidebarCollapsedRef = useRef<boolean>(
    storedSidebarCollapseSetting === 'true',
  )
  const menuScrollCallbackRef = useRef<() => void | null>(null)
  const tooltipCallbackRef = useRef<() => void | null>(null)

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) {
      newWindow.opener = null
    }
  }

  const openOutboundUrl = async (
    e: any,
    link: string,
    product: ProductType,
    returnPath: string,
  ) => {
    e.preventDefault()

    if (e.nativeEvent.button === 0 || e.nativeEvent.button === 1) {
      if (userInfo.isSso) {
        await getSsoUrl({ destinationProduct: product, returnPath })
          .then(({ data }: any) => {
            openInNewTab(data.ssoUrl)
          })
          .catch(() => {
            Sentry.withScope(function (scope) {
              scope.setTag('Path', location?.pathname)
              scope.setTag('product', product)
              scope.setTag('returnPath', returnPath)
              LogError(
                e,
                'There was an error retrieving sso url. Please try again.',
              )
            })
          })
      } else {
        openInNewTab(link)
      }
    }
  }

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      ) ||
      document.body.clientWidth <= 1024 ||
      (storedSidebarCollapseSetting && storedSidebarCollapseSetting === 'true')
    )
      setIsSidebarCollapsed(true)
    setSidebarTheme()
    mapCurrentPage(window.location.pathname)

    menuScrollCallbackRef.current = scrollToActiveMenuItem
    setMenuScrollDelay(0)

    window.addEventListener('resize', handleWindowResize)
    window.addEventListener('hashchange', handleHashChange)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [location, flags])

  useEffect(() => {
    if (activeServices && userType) handleNavMenuOptions()
  }, [activeServices, userType, userInfo, flags, pmsSoftware])

  useEffect(() => {
    if (!isSidebarCollapsedRef.current) {
      const tooltips = document.querySelectorAll('.menu-category__item-tooltip')
      tooltips.forEach((tooltip) => tooltip.remove())
    }
  }, [isSidebarCollapsedRef.current])

  useTimeout(menuScrollCallbackRef.current, menuScrollDelay)
  useTimeout(tooltipCallbackRef.current, tooltipDelay)

  const setSidebarTheme = () => {
    const root = document.querySelector(':root') as HTMLElement
    if (appTheme === 'dental-intel-theme') {
      setUserType('DI')
      if (!collapsedLogo) setCollapsedLogo(DentalIntelligenceDotLogo)
      if (!mainLogo) setMainLogo(DentalIntelligenceInsuranceLogo)
      root?.style?.setProperty(
        '--sidebar-background-color',
        'var(--dental-intel-dark-blue)',
      )
      root?.style?.setProperty(
        '--sidebar-font-color',
        'var(--dental-intel-white)',
      )
      root?.style?.setProperty(
        '--sidebar-font-color-secondary',
        'var(--dental-intel-white)',
      )
      root?.style?.setProperty('--sidebar-hover-font-color', 'var(--white-000)')
      root?.style?.setProperty(
        '--sidebar-menu-item-hover-background-color',
        'var(--dental-intel-light-blue)',
      )
      root?.style?.setProperty(
        '--sidebar-menu-item-active-background-color',
        'var(--dental-intel-light-blue)',
      )
      root?.style?.setProperty(
        '--sidebar-menu-item-active-border-color',
        'var(--dental-intel-dark-blue)',
      )
    } else if (appTheme === 'rpractice-theme') {
      setUserType('RPractice')
      root?.style?.setProperty(
        '--sidebar-background-color',
        'var(--rpractice-dark-blue)',
      )
      root?.style?.setProperty('--sidebar-font-color', 'var(--white-100)')
      root?.style?.setProperty(
        '--sidebar-font-color-secondary',
        'var(--white-100)',
      )
      root?.style?.setProperty('--sidebar-hover-font-color', 'var(--white-000)')
      root?.style?.setProperty(
        '--sidebar-menu-item-hover-background-color',
        'var(--rpractice-light-blue)',
      )
      root?.style?.setProperty(
        '--sidebar-menu-item-active-background-color',
        'var(--rpractice-light-blue)',
      )
      root?.style?.setProperty(
        '--sidebar-menu-item-active-border-color',
        'var(--rpractice-dark-blue)',
      )
    } else if (appTheme === 'trellis-theme') {
      setUserType('Trellis')
      root?.style?.setProperty('--sidebar-background-color', 'var(--white-000)')
      root?.style?.setProperty('--sidebar-font-color', 'var(--charcoal-400)')
      root?.style?.setProperty(
        '--sidebar-font-color-secondary',
        'var(--charcoal-200)',
      )
      root?.style?.setProperty(
        '--sidebar-hover-font-color',
        'var(--charcoal-400)',
      )
      root?.style?.setProperty(
        '--sidebar-menu-item-hover-background-color',
        'var(--white-100)',
      )
      root?.style?.setProperty(
        '--sidebar-menu-item-active-background-color',
        'var(--color-primary-hover)',
      )
      root?.style?.setProperty(
        '--sidebar-menu-item-active-border-color',
        'var(--color-primary)',
      )
    }
  }

  const handleNavMenuOptions = async () => {
    const options = await getNavMenuOptions(
      activeServices,
      flags,
      openOutboundUrl,
      userType,
      facilityID,
      pmsSoftware,
    )
    if (options) setNavMenuOptions(options)
  }

  const handleWindowResize = () => {
    if (document.body.clientWidth <= 1024) setIsSidebarCollapsed(true)
    else
      setIsSidebarCollapsed(
        localStorage.getItem('vyne-sidebar-collapse') === 'true',
      )
  }

  const handleHashChange = () => {
    if (window.location.hash) setCurrentPage(window.location.hash.substring(1))
  }

  const handleCollapse = () => {
    localStorage.setItem(
      'vyne-sidebar-collapse',
      (!isSidebarCollapsed).toString(),
    )
    isSidebarCollapsedRef.current = !isSidebarCollapsed
    setIsSidebarCollapsed(!isSidebarCollapsed)
  }

  const mapCurrentPage = (pathname: string) => {
    switch (pathname) {
      case '/AdditionalServices/Forms':
        setCurrentMenuCategory('ENGAGEMENT')
        setCurrentPage('Forms')
        break
      case '/AdditionalServices/PatientMessaging':
        setCurrentMenuCategory('ENGAGEMENT')
        setCurrentPage('PatientMessaging')
        break
      case '/AdditionalServices/Reminders':
        setCurrentMenuCategory('ENGAGEMENT')
        setCurrentPage('Reminders')
        break
      case '/AdditionalServices/Reviews':
        setCurrentMenuCategory('ENGAGEMENT')
        setCurrentPage('Reviews')
        break
      case '/AdditionalServices/SecureEmail':
        setCurrentMenuCategory('PRACTICE TOOLS')
        setCurrentPage('SecureEmail')
        break
      case '/AdditionalServices/TeamChat':
        setCurrentMenuCategory('PRACTICE TOOLS')
        setCurrentPage('TeamChat')
        break
      case '/Archive/ClaimArchive':
        setCurrentMenuCategory('INSURANCE')
        setCurrentPage('ClaimArchive')
        break
      case '/Attachments':
        setCurrentMenuCategory('INSURANCE')
        setCurrentPage('Attachments')
        break
      case '/CarrierList':
        setCurrentMenuCategory('INSURANCE')
        setCurrentPage('CarrierList')
        break
      case '/Claims':
        setCurrentMenuCategory('INSURANCE')
        setCurrentPage('Claims')
        break
      case '/Dashboard':
        setCurrentMenuCategory('MY PRACTICE')
        setCurrentPage('Dashboard')
        break
      case '/Eligibility':
        setCurrentMenuCategory('INSURANCE')
        setCurrentPage('Eligibility')
        break
      case '/Payment':
        setCurrentMenuCategory('MY PRACTICE')
        setCurrentPage('Pay My Bill')
        break
      case '/Payment/ManageRecurring':
        setCurrentMenuCategory('MY PRACTICE')
        setCurrentPage('Recurring Payments')
        break
      case '/Payment/PaymentsPortal':
        setCurrentMenuCategory('PAYMENTS')
        setCurrentPage('Payments')
        break
      case '/Payment/Receipt':
        setCurrentMenuCategory('MY PRACTICE')
        setCurrentPage('Pay My Bill')
        break
      case '/Payment/Transactions':
        setCurrentMenuCategory('MY PRACTICE')
        setCurrentPage('Payment History')
        break
      case '/Practice/BillingDetails':
        setCurrentMenuCategory('MY PRACTICE')
        setCurrentPage('Billing Details')
        break
      case '/Practice/Downloads':
        setCurrentMenuCategory('MY PRACTICE')
        setCurrentPage('Downloads')
        break
      case '/Practice/PracticeInfo':
        setCurrentMenuCategory('MY PRACTICE')
        setCurrentPage('Practice Info')
        break
      case '/Practice/UserManagement':
        setCurrentMenuCategory('MY PRACTICE')
        setCurrentPage('Account')
        break
      case '/Practice/UserManagementSso':
        setCurrentPage('UserManagement')
        break
      case '/Rlo':
        setCurrentMenuCategory('INSURANCE')
        setCurrentPage('Rlo')
        break
      case '/Rlo/Settings':
        setCurrentMenuCategory('INSURANCE')
        setCurrentPage('Settings')
        break
    }
  }

  const scrollToActiveMenuItem = () => {
    const sidebar: HTMLElement = document.querySelector('.sidebar-navigation')
    let activeMenuItem: HTMLElement = document.querySelector(
      '.menu-category__item--active',
    )
    if (!activeMenuItem) activeMenuItem = sidebar
    sidebar.scrollTop =
      activeMenuItem.offsetTop -
      (activeMenuItem.offsetHeight + sidebar.offsetTop)
  }

  const addTooltip = () => {
    const existingTooltip = document.getElementById(
      'menu-category__item-tooltip--collapse',
    )
    if (existingTooltip) return

    const bodyRect = document.body.getBoundingClientRect()
    const collapseElementRect = document
      .getElementById('menu-category__item--collapse')
      .getBoundingClientRect()
    const tooltipContainer = document.createElement('div')
    tooltipContainer.setAttribute('id', `menu-category__item-tooltip--collapse`)

    if (isSidebarCollapsedRef.current === true) {
      document.body.appendChild(tooltipContainer)

      const tooltip = (
        <section
          className='menu-category__item-tooltip'
          style={{
            display: 'block',
            position: 'absolute',
            bottom:
              collapseElementRect.height / 2 +
              (bodyRect.height - collapseElementRect.bottom),
            left: collapseElementRect.right + 16,
            top: 'auto',
            transform: 'translateY(50%)',
          }}
        >
          <p>Expand</p>
        </section>
      )

      const root = createRoot(tooltipContainer)
      root.render(tooltip)
    }
  }

  const removeTooltip = () => {
    const tooltip = document.getElementById(
      'menu-category__item-tooltip--collapse',
    )
    if (tooltip) tooltip.remove()
  }

  return (
    <nav
      className={`sidebar-navigation sidebar-navigation--${
        isSidebarCollapsed ? 'collapsed' : 'default'
      }`}
      onMouseOver={() => {
        menuScrollCallbackRef.current = (): null => null
        setMenuScrollDelay(0)
      }}
      onMouseLeave={() => {
        menuScrollCallbackRef.current = scrollToActiveMenuItem
        setMenuScrollDelay(1500)
      }}
    >
      <section className='flex-column sidebar-navigation__container'>
        {(mainLogo || collapsedLogo) && (
          <>
            {mainLogo && !isSidebarCollapsed ? (
              <img
                alt={`${userType} logo`}
                className='sidebar-navigation__logo'
                src={mainLogo}
              />
            ) : (
              <img
                alt={`${userType} logo`}
                className='sidebar-navigation__logo sidebar-navigation__logo--collapsed'
                src={collapsedLogo}
              />
            )}
          </>
        )}
        <ul className='sidebar-navigation__menu'>
          {navMenuOptions &&
            navMenuOptions.map((option) => (
              <NavMenuCategory
                currentMenuCategory={currentMenuCategory}
                currentPage={currentPage}
                category={option}
                isSidebarCollapsed={isSidebarCollapsed}
                key={option?.id}
              />
            ))}
        </ul>
      </section>

      <section
        className={`menu-category__item menu-category__item--inactive ${
          isSidebarCollapsed ? 'menu-category__item--collapsed' : ''
        }`}
        id='menu-category__item--collapse'
        onClick={handleCollapse}
        onMouseLeave={() => {
          tooltipCallbackRef.current = removeTooltip
          setTooltipDelay(250)
        }}
        onMouseOver={() => {
          if (isSidebarCollapsed) {
            tooltipCallbackRef.current = addTooltip
            setTooltipDelay(1000)
          }
        }}
      >
        <section
          className={`menu-category__item-title-container menu-category__item-title-container--${
            isSidebarCollapsed ? 'collapsed' : 'default'
          }`}
        >
          <LayoutOutlined
            aria-label={
              isSidebarCollapsed
                ? 'expand sidebar navigation'
                : 'collapse sidebar navigation'
            }
            className={`menu-category__item-icon--${
              isSidebarCollapsed ? 'collapsed' : 'default'
            }`}
          />
          {!isSidebarCollapsed && (
            <p className='menu-category__item-text'>Collapse</p>
          )}
        </section>
      </section>
    </nav>
  )
}
