import { RightOutlined } from '@ant-design/icons'
import { FC, useEffect, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { NavLink } from 'react-router-dom'

import { useTimeout } from 'utils'

import { NavMenuItem } from '../utilities/navOptions'

interface NavCategory extends NavMenuItem {
  menu?: NavMenuItem[]
}

interface NavMenuCategoryProps {
  category: NavCategory
  currentMenuCategory: string
  currentPage: string
  isSidebarCollapsed: boolean
}

const NavMenuCategory: FC<NavMenuCategoryProps> = ({
  category,
  currentMenuCategory,
  currentPage,
  isSidebarCollapsed,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

  useEffect(() => {
    const storedMenuDropdownSetting = localStorage.getItem(
      `sidebar-menu-dropdown--${category.label}`,
    )
    if (storedMenuDropdownSetting === 'true') setIsDropdownOpen(true)
  }, [])

  useEffect(() => {
    if (currentMenuCategory === category.label) setIsDropdownOpen(true)
  }, [currentMenuCategory])

  return (
    <section className='menu-category'>
      {category.link ? (
        <NavMenuCategoryItem
          currentPage={currentMenuCategory}
          isCategoryHeader={true}
          isSidebarCollapsed={isSidebarCollapsed}
          item={category}
          key={category.label}
        />
      ) : (
        <li
          className={`
            menu-category__header 
            menu-category__header--${
              isSidebarCollapsed ? 'collapsed' : 'default'
            } 
          `}
          onClick={() => {
            localStorage.setItem(
              `sidebar-menu-dropdown--${category.label}`,
              JSON.stringify(!isDropdownOpen),
            )
            setIsDropdownOpen(!isDropdownOpen)
          }}
        >
          {!isSidebarCollapsed && (
            <RightOutlined
              className={`menu-category__arrow ${
                isDropdownOpen ? 'menu-category__arrow--down' : ''
              }`}
            />
          )}
          <p
            className={`menu-category__header-text menu-category__header-text--${
              isSidebarCollapsed ? 'collapsed' : 'default'
            }`}
          >
            {category.label}
          </p>
        </li>
      )}
      {category.menu && (
        <ul
          className={`menu-category__item-list ${
            isDropdownOpen || isSidebarCollapsed
              ? 'menu-category__item-list--open'
              : ''
          }`}
        >
          {category.menu.map((item: NavMenuItem) => (
            <NavMenuCategoryItem
              currentPage={currentPage}
              isSidebarCollapsed={isSidebarCollapsed}
              item={item}
              key={item.id}
            />
          ))}
        </ul>
      )}
    </section>
  )
}

interface NavMenuItemProps {
  currentPage: string
  isCategoryHeader?: boolean
  isSidebarCollapsed: boolean
  item: NavMenuItem
}

const NavMenuCategoryItem: FC<NavMenuItemProps> = ({
  currentPage,
  isCategoryHeader = false,
  isSidebarCollapsed,
  item,
}) => {
  const [tooltipDelay, setTooltipDelay] = useState<number>(null)

  const tooltipCallbackRef = useRef(null)

  useTimeout(tooltipCallbackRef.current, tooltipDelay)

  const addTooltip = () => {
    const existingTooltip = document.getElementById(
      `menu-category__item-tooltip--${item.id}`,
    )
    if (existingTooltip) return

    const bodyRect = document.body.getBoundingClientRect()
    const menuItemElementRect = document
      .getElementById(`menu-category__item--${item.id}`)
      .getBoundingClientRect()
    const tooltipContainer = document.createElement('div')
    tooltipContainer.setAttribute(
      'id',
      `menu-category__item-tooltip--${item.id}`,
    )

    if (isSidebarCollapsed) {
      document.body.appendChild(tooltipContainer)

      const tooltip = (
        <section
          className='menu-category__item-tooltip'
          style={{
            display: 'block',
            position: 'absolute',
            bottom:
              menuItemElementRect.height / 2 +
              (bodyRect.height - menuItemElementRect.bottom),
            left: menuItemElementRect.right + 16,
            top: 'auto',
            transform: 'translateY(50%)',
          }}
          id={`menu-category__item-tooltip--${item.id}`}
        >
          <p>{item.label}</p>
        </section>
      )

      const root = createRoot(tooltipContainer)
      root.render(tooltip)
    }
  }

  const removeTooltip = () => {
    const tooltip = document.getElementById(
      `menu-category__item-tooltip--${item.id}`,
    )
    if (tooltip) tooltip.remove()
  }

  return (
    <li>
      {isCategoryHeader && isSidebarCollapsed && (
        <section className='menu-category__header menu-category__header--collapsed'>
          <p
            className={`menu-category__header-text menu-category__header-text--${
              isSidebarCollapsed ? 'collapsed' : 'default'
            }`}
          >
            {item.label}
          </p>
        </section>
      )}
      <NavLink
        className={({ isActive }) =>
          `menu-category__item ${
            isSidebarCollapsed ? 'menu-category__item--collapsed' : ''
          } ${
            item.isExternalLink
              ? `menu-category__item--inactive`
              : `menu-category__item--${isActive ? 'active' : 'inactive'}`
          }`
        }
        id={`menu-category__item--${item.id}`}
        onMouseLeave={() => {
          tooltipCallbackRef.current = removeTooltip
          setTooltipDelay(250)
        }}
        onMouseOver={() => {
          if (isSidebarCollapsed) {
            tooltipCallbackRef.current = addTooltip
            setTooltipDelay(1000)
          }
        }}
        to={!item.ssoCallback ? item.link : location.pathname}
        {...(item.ssoCallback && {
          onAuxClick: (e) =>
            item.ssoCallback(e, item.link, item.product, item.returnPath),
        })}
        {...(item.ssoCallback && {
          onClick: (e) =>
            item.ssoCallback(e, item.link, item.product, item.returnPath),
        })}
        {...(item.reloadOnClick && { reloadDocument: true })}
        {...(item.newTab && { target: '_blank', rel: 'noopener' })}
      >
        <section className='menu-category__item-title-container'>
          <div
            className={`menu-category__item-icon--${
              isSidebarCollapsed
                ? `collapsed${item.hasCustomIcon ? '-custom' : ''}`
                : `default${item.hasCustomIcon ? '-custom' : ''}`
            }`}
          >
            {item.icon}
          </div>
          {isCategoryHeader && !isSidebarCollapsed && (
            <p
              className={`menu-category__header-text menu-category__header-text--${
                isSidebarCollapsed ? 'collapsed' : 'default'
              }`}
            >
              {item.label}
            </p>
          )}
          {!isCategoryHeader && !isSidebarCollapsed && (
            <p className='menu-category__item-text'>{item.label}</p>
          )}
        </section>
        {item.isExternalLinkDI && !isSidebarCollapsed && (
          <svg
            width='1em'
            height='1em'
            viewBox='0 0 16 16'
            className='menu-category__item-action-icon bi bi-box-arrow-up-right'
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'
            />
            <path
              fillRule='evenodd'
              d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'
            />
          </svg>
        )}
      </NavLink>
    </li>
  )
}

export default NavMenuCategory
