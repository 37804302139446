import { Col, Row } from 'antd'
import { FC } from 'react'

import { FormInput, FormMaskInput, FormSelect } from 'ui'
import { validateBirthday } from 'utils'

import { LDFlags$ } from 'trellis:state/globalState'

import { EligibilityPatient } from '../../../../../api/eligibility/eligibility-client'

interface PatientControlsProps {
  isModal?: boolean
  patient: EligibilityPatient
  prefix?: 'Patient' | 'Subscriber'
}

const PatientControls: FC<PatientControlsProps> = ({
  isModal = false,
  patient,
  prefix = 'Patient',
}) => {
  const flags = LDFlags$.use()

  const existingSubscriber = prefix === 'Subscriber' && patient.PatientIsSub
  let disabled = existingSubscriber
  if (!isModal) {
    const existingVdsPatient =
      (flags.eligibilityUsevynedentalsync ||
        flags.operaVynesyncAutomatedeligibility) &&
      patient.PatientId !== '0'
    disabled = existingVdsPatient || existingSubscriber
  }

  return (
    <Col span={24}>
      <Row gutter={8}>
        <Col
          span={9}
          className='gutter-row'
        >
          <FormInput
            disabled={disabled}
            label='Last Name'
            name={`${prefix}LastName`}
            required={!disabled}
          />
        </Col>
        <Col
          span={9}
          className='gutter-row'
        >
          <FormInput
            disabled={disabled}
            label='First Name'
            name={`${prefix}FirstName`}
            required={!disabled}
          />
        </Col>
        <Col
          span={6}
          className='gutter-row'
        >
          <FormInput
            disabled={disabled}
            label='Middle Name'
            name={`${prefix}MiddleName`}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col
          span={8}
          className='gutter-row'
        >
          <FormMaskInput
            disabled={disabled}
            format='##/##/####'
            label='DOB'
            mask='_'
            name={`${prefix}Birthdate`}
            required={!disabled}
            validator={validateBirthday}
          />
        </Col>
        <Col
          span={8}
          className='gutter-row'
        >
          <FormSelect
            disabled={disabled}
            label='Gender'
            name={`${prefix}Gender`}
            options={[
              { value: 'M', text: 'Male' },
              { value: 'F', text: 'Female' },
              { value: 'U', text: 'Unknown' },
            ]}
            required={!disabled}
          />
        </Col>
        <Col
          span={8}
          className='gutter-row'
        >
          <FormInput
            disabled={disabled}
            label='Suffix'
            name={`${prefix}Suffix`}
          />
        </Col>
      </Row>
    </Col>
  )
}

export default PatientControls
