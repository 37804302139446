import { Form } from 'antd'
import { useEffect } from 'react'
import { Navigate, useLoaderData } from 'react-router-dom'

import { LogError } from 'utils'

import { PublicLayoutWrapper } from 'trellis:components/layouts/PreAuthLayout/PreAuthLayout'
import GlobalState from 'trellis:state/globalState'

import { BillingContent } from '../_siteWide/payment/billing/billingContent'
import { InvoiceContextProvider } from '../_siteWide/payment/context/invoiceContext'
import {
  PaymentContextProvider,
  usePaymentContext,
} from '../_siteWide/payment/context/paymentContext'

const BillingInfo = () => {
  const { payment, setIsNew } = usePaymentContext()

  const [form] = Form.useForm()
  form.setFieldsValue({ ...payment })

  useEffect(() => {
    setIsNew(true)
  }, [])

  return (
    <PublicLayoutWrapper>
      <div className='page'>
        <Form form={form}>
          <BillingContent />
        </Form>
      </div>
    </PublicLayoutWrapper>
  )
}

// PaymentContextProvider gets flags from GlobalContextProvider
// PaymentContextProvider is also used for both billingInfo and billingDetails
// BillingDetails needs PostAuthLayout
// We should do refactoring, but for sake of time for a release GlobalContextProvider was added here
const BillingInfoWrapper = () => {
  const hasBillingInfo = useLoaderData()
  if (hasBillingInfo) return <Navigate to='/' />

  return (
    <InvoiceContextProvider>
      <PaymentContextProvider>
        <BillingInfo />
      </PaymentContextProvider>
    </InvoiceContextProvider>
  )
}

export const billingInfoLoader = () => {
  const response = GlobalState.BillingDetails.paymentType.get()
  if (response == undefined) {
    LogError(new Error('Error getting billingInfoLoader flag'))
    return null
  }

  return response
}

export default BillingInfoWrapper
