import { Col, Row } from 'antd'
import React, { useState } from 'react'

import { LDFlags$ } from 'trellis:state/globalState'
import { ClaimCarrierData } from 'trellis:utilities/api'

import { AddressControls } from '../../../../_siteWide/address/addressControls'
import { FormAutocomplete } from '../../../../_siteWide/form/formAutocomplete'
import { AttachmentCarrier } from '../../../../../api/attachment/attachment-client/api'
import { useClaimDetailContext } from '../../../context/claimDetailContext'
import { useClaimGridContext } from '../../../context/claimGridContext'

type CarrierOption = {
  key: number
  data: ClaimCarrierData | AttachmentCarrier
  value: string
}
type CarrierOptions = CarrierOption[]

export const CarrierControls = (props: {
  displayAttachmentCarrier: boolean
}) => {
  const flags = LDFlags$.use()
  const {
    claimCarrierData$,
    claim,
    setClaim,
    updateCarrier,
    updateAttachmentCarrier,
    attachmentCarriers,
    isLegacyGlobal,
    setIsCreatedFromSentAttachment,
    attachment,
  } = useClaimDetailContext()
  const { carriers } = useClaimGridContext()

  const isResendAttachment = !isLegacyGlobal && claim.Resend
  const populateCarriers = (): CarrierOptions => {
    if (props.displayAttachmentCarrier) {
      return attachmentCarriers?.map((c: AttachmentCarrier, i: number) => ({
        key: i,
        value: attachmentCarrierDisplay(c),
        data: c,
      }))
    } else {
      return carriers.map((c: any, i: number) => ({
        key: i,
        value: carrierDisplay(c),
        data: c,
      }))
    }
  }

  const options: CarrierOptions = populateCarriers()
  const [filteredOptions, setFilteredOptions] = useState(options)

  function carrierDisplay(c: any) {
    return `${c?.Carrier?.trim()} | ${c?.CarrierAddress?.trim()} | ${c?.CarrierId?.trim()}`
  }

  function attachmentCarrierDisplay(c: AttachmentCarrier): string {
    return c?.carrierName?.trim() + ' - ' + c?.carrierId?.trim()
  }

  const onCarrierChange = (value: string) => {
    //This only works on changes to the carrier on the patient tab, attachment tab is ignored
    const carrier = value?.includes('|') ? value.split('|')[0].trim() : value
    const carrierMatch: CarrierOption = options.find((item) => {
      const data = item.data as ClaimCarrierData
      return data !== undefined && data.Carrier?.trim() === carrier
    })

    if (carrierMatch)
      claimCarrierData$.set(carrierMatch.data as ClaimCarrierData)
    if (isResendAttachment) setIsCreatedFromSentAttachment(true)

    return carrier
  }

  const onCarrierSearch = (value: string) => {
    if (value) {
      value = value.toLowerCase()
      const newFilteredOptions: any = options.filter((o: any) =>
        o.value.toLowerCase().includes(value),
      )
      setFilteredOptions(newFilteredOptions)
    } else {
      setFilteredOptions(options)
    }
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <FormAutocomplete
            name='Carrier'
            element={claim}
            setElement={setClaim}
            initialValue={
              props.displayAttachmentCarrier
                ? attachment?.Carrier
                : claim?.Carrier
            }
            options={filteredOptions}
            section='patient'
            onSearch={onCarrierSearch}
            customHandler={
              props.displayAttachmentCarrier
                ? updateAttachmentCarrier
                : updateCarrier
            }
            valueHandler={onCarrierChange}
            required
          />
        </Col>
      </Row>
      {props.displayAttachmentCarrier == false && (
        <AddressControls
          prefix='Carrier'
          readyOnly={true}
          state={claim}
          setState={setClaim}
          section='patient'
        />
      )}
    </>
  )
}
