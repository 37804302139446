import { observer, useObservable } from '@legendapp/state/react'

import { TabItem, Tabs } from 'ui'

import { StatementsQueryRequest } from 'trellis:api/statements/statements-client'
import { BillingDownloadButton } from 'trellis:features/payments/components/BillingDownloadButton'
import { BillingFilesTable } from 'trellis:features/payments/components/BillingFilesTable'
import { BillingStatementSettings } from 'trellis:features/payments/components/BillingStatementSettings'
import { BillingStatementsTable } from 'trellis:features/payments/components/BillingStatementsTable'
import { LDFlags$ } from 'trellis:state/globalState'

export const BillingStatements = observer(() => {
  // TODO: add tabs and routes for each tab
  // TODO: maybe retrieve each tab data via a loader

  const downloadTableRequest$ = useObservable<StatementsQueryRequest>()

  const tabItems: TabItem[] = [
    {
      title: 'Batch Files',
      testId: 'files',
      content: (
        <BillingFilesTable downloadTableRequest$={downloadTableRequest$} />
      ),
    },
    {
      title: 'Statements',
      testId: 'statements',
      content: (
        <BillingStatementsTable downloadTableRequest$={downloadTableRequest$} />
      ),
    },
  ]

  const currentTab$ = useObservable<string>(tabItems[0].title)
  const flags = LDFlags$.get()

  const updateCurrentTabTitle = (title: string) => currentTab$.set(title)

  return (
    <section className='page-content__container page-content__container--with-page-header'>
      <h1 className='page-content-header__title mb-100'>Billing Statements</h1>
      <Tabs
        items={tabItems}
        onChange={updateCurrentTabTitle}
        extraContent={
          <>
            <BillingDownloadButton
              currentTab$={currentTab$}
              downloadTableRequest$={downloadTableRequest$}
            />
            {flags.billingStatementsSettings && <BillingStatementSettings />}
          </>
        }
      />
    </section>
  )
})
