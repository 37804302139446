import api, { IAuthenticatedApiModel } from '../../utilities/api'
import {
  BuildQueryRequest,
  ClaimsCustomerApiFactory,
  ClaimsDeleteRequest,
  ClaimsExportRequest,
  ClaimsIgnoreAttachmentRequirementsRequest,
  ClaimsIgnoreRequest,
  ClaimsPrintRequest,
  ClaimsStatusRequest,
} from './claim-client'
import { AbstractClaimApi, IClaimsApi, SendResult } from './claimApi'
import { ApiTypes } from './claimsApiFactory'

export class CustomerApi extends AbstractClaimApi implements IClaimsApi {
  declare customerId: number
  declare accessToken: string

  constructor(authentication: IAuthenticatedApiModel, apiType: ApiTypes) {
    super(authentication, apiType)
    this.customerId = Number(authentication.CustomerId)
    this.accessToken = authentication.AccessToken
  }

  public async GetClaims(buildQueryRequest: BuildQueryRequest) {
    return await ClaimsCustomerApiFactory(
      this.configuration,
    ).claimsCustomerGetClaims(this.customerId, buildQueryRequest)
  }

  public async GetFilterDropdowns(buildQueryRequest: BuildQueryRequest) {
    return await ClaimsCustomerApiFactory(
      this.configuration,
    ).claimsCustomerGetFilterDropdowns(this.customerId, buildQueryRequest)
  }

  public async UpdateClaimsStatus(claimsStatusRequest: ClaimsStatusRequest) {
    return await ClaimsCustomerApiFactory(
      this.configuration,
    ).claimsCustomerUpdateClaimsStatus(this.customerId, claimsStatusRequest)
  }

  public async UpdateClaimsIgnoreAttachmentRequirements(
    claimsIgnoreAttachmentRequirementsRequest: ClaimsIgnoreAttachmentRequirementsRequest,
  ) {
    return await ClaimsCustomerApiFactory(
      this.configuration,
    ).claimsCustomerUpdateClaimsIgnoreAttachmentRequirements(
      this.customerId,
      claimsIgnoreAttachmentRequirementsRequest,
    )
  }

  public async DeleteClaims(claimsDeleteRequest: ClaimsDeleteRequest) {
    return await ClaimsCustomerApiFactory(
      this.configuration,
    ).claimsCustomerDeleteClaims(this.customerId, claimsDeleteRequest)
  }

  public async ExportClaims(claimsExportRequest: ClaimsExportRequest) {
    return await ClaimsCustomerApiFactory(
      this.configuration,
    ).claimsCustomerCreateClaimsExport(this.customerId, claimsExportRequest)
  }

  public async IgnoreClaims(claimsIgnoreRequest: ClaimsIgnoreRequest) {
    return await ClaimsCustomerApiFactory(
      this.configuration,
    ).claimsCustomerUpdateClaimsIgnore(this.customerId, claimsIgnoreRequest)
  }

  public async PrintClaims(claimsPrintRequest: ClaimsPrintRequest) {
    return await ClaimsCustomerApiFactory(
      this.configuration,
    ).claimsCustomerCreateClaimsPrint(this.customerId, claimsPrintRequest)
  }

  public async ProcessClaims(claimRequest: any) {
    const result = {} as SendResult
    result.response = await api.processClaims(claimRequest, this.accessToken)
    result.isQueuedSend = false
    return result
  }
}
