import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Observable } from '@legendapp/state'
import { enableReactUse } from '@legendapp/state/config/enableReactUse'
import { FC } from 'react'

enableReactUse()

interface TextFinderNavProps {
  currentMatchIndex: Observable<number>
  matchesTotal: Observable<number>
  textMatches: Observable<HTMLCollectionOf<Element>>
}

const TextFinderNav: FC<TextFinderNavProps> = ({
  currentMatchIndex,
  matchesTotal,
  textMatches,
}) => {
  const matchIndex = currentMatchIndex.use()
  const total = matchesTotal.use()
  const matches = textMatches.use()

  // navigate through match highlights
  const handleMatchNav = (value: 'up' | 'down') => {
    const currentMatch = matches[matchIndex - 1]

    let nextMatch: HTMLElement
    switch (value) {
      case 'up': {
        if (matchIndex > 1 && matchIndex <= total) {
          currentMatchIndex.set((i) => i - 1)
          nextMatch = matches[matchIndex - 2] as HTMLElement
        } else if (matchIndex === 1) {
          currentMatchIndex.set(total)
          nextMatch = matches[total - 1] as HTMLElement
        }
        break
      }
      case 'down': {
        if (matchIndex > 0 && matchIndex < total) {
          currentMatchIndex.set((i) => i + 1)
          nextMatch = matches[matchIndex] as HTMLElement
        } else if (matchIndex === total) {
          currentMatchIndex.set(1)
          nextMatch = matches[0] as HTMLElement
        }
        break
      }
    }

    currentMatch?.classList.remove('search-text-highlight--current')
    currentMatch.removeAttribute('id')
    nextMatch?.classList.add('search-text-highlight--current')
    nextMatch.setAttribute('id', 'current-result')

    nextMatch.focus()
  }

  return (
    <>
      <UpOutlined
        aria-label='next match up'
        onClick={() => handleMatchNav('up')}
      />
      <DownOutlined
        aria-label='next match down'
        onClick={() => handleMatchNav('down')}
      />
    </>
  )
}

export default TextFinderNav
