import { UserAddOutlined } from '@ant-design/icons'
import { Form, Input, List, Modal } from 'antd'
import { Dispatch, FC, SetStateAction, useState } from 'react'

import { Button, SearchBar, SearchStates } from 'ui'

import { GetPatient, SearchPatients } from 'trellis:api/patients/patientsApi'
import { useGlobalContext } from 'trellis:context/GlobalContextProvider'
import { IAuthenticatedApiModel } from 'trellis:utilities/api'
import {
  formatDateFromString,
  showMessage,
} from 'trellis:utilities/general'
import { DatePicker } from 'ui'
import { useAttachmentsContext } from '../../Context/AttachmentsContext'

import { isSafeApiError, LogError } from 'utils'

import {
  EligibilityPatients,
  EligibilityPatientsRequest,
} from 'trellis:api/patients/patients-client'
import { Errors } from 'trellis:constants/errors'

import './PatientSearchModal.scss'

type PatientSearchModalProps = {
  authentication: IAuthenticatedApiModel
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
  setAttachmentId: Dispatch<SetStateAction<number>>
}

const PatientSearchModal: FC<PatientSearchModalProps> = ({
  isVisible,
  setIsVisible,
  setAttachmentId,
}) => {
  const [patientName, setPatientName] = useState<SearchStates>('')
  const [memberId, setMemberId] = useState<string>('')
  const [patientDOB, setPatientDOB] = useState<string>('')
  const [patients, setPatients] = useState<EligibilityPatients[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [patientSearchForm] = Form.useForm()

  const { setVdsPatientResponse } = useAttachmentsContext()

  const { authentication: trellisAuthentication } = useGlobalContext()

  const openAddPatient = () => {
    setIsVisible(false)
    // Opens attachment view but is not connected to an existing attachment
    setAttachmentId(-1)
  }

  const handleGetPatients = async () => {
    setLoading(true)
    const request: EligibilityPatientsRequest = {
      CurrentPage: 1,
      Filters: {},
      PageSize: 100,
      SortColumn: { Column: 'Name', Sort: 'asc' },
    }
    if (patientName) {
      request.Filters['Name'] = patientName.toString()
    }
    if (patientDOB) {
      request.Filters['dateofbirth'] = patientDOB.toString()
    }
    if (memberId) {
      request.Filters['memberid'] = memberId.toString()
    }

    await SearchPatients(trellisAuthentication, request)
      .then(({ data }) => {
        setPatients(data.Data)
      })
      .catch((e) => {
        // Returns a 404 error response if no results are found
        if (!isSafeApiError(e, [404])) {
          LogError(e, 'Failed to get patients')
          showMessage(Errors.getPatientsError)
        }
        setPatients([])
      })
      .finally(() => setLoading(false))
  }

  const handleRowClick = (patient: any) => {
    setLoading(true)
    GetPatient(trellisAuthentication, patient.PatientId)
      .then(({ data }) => {
        setVdsPatientResponse(data)
        // Opens attachment view but is not connected to an existing attachment
      })
      .catch((e) => {
        // Returns a 404 error response if no results are found
        setVdsPatientResponse({})
      })
      .finally(() => {
        setLoading(false)
        setAttachmentId(-1)
      })
  }

  return (
    <Modal
      title='New Attachment'
      open={isVisible}
      closable
      onCancel={() => setIsVisible(false)}
      width={600}
      footer={[
        <Button
          label='Cancel'
          key='cancel'
          onClick={() => setIsVisible(false)}
        />,
      ]}
    >
      <main className='sa-patient-search__modal'>
        <Form
          colon={false}
          name='patientSearchForm'
          form={patientSearchForm}
          onFinish={handleGetPatients}
        >
          <section className='sa-patient-search__modal--instructions'>
            <p>Create and send an attachment without a claim.</p>
            <Button
              type='text'
              icon={<UserAddOutlined />}
              onClick={openAddPatient}
            >
              <span className='sa-patient-search__modal--instructions-add'>
                Add Patient
              </span>
            </Button>
          </section>
          <section className='sa-patient-search__modal--search'>
            <SearchBar
              autoFocus={true}
              delaySearch={false}
              setState={setPatientName}
              state={patientName}
              tabIndex={1}
            />
          </section>
          <section className='sa-patient-search__modal--filter'>
            <DatePicker 
              tabIndex={2}
              format='MM/dd/y'
              placeholder='MM/DD/YYYY'
              onChange={(date: Date) => setPatientDOB(date?.toISOString())}
            />
            <Input
              tabIndex={3}
              name='memberID'
              placeholder='Member ID'
              value={memberId}
              onChange={(e) => setMemberId(e.target.value)}
            />
            <Button
              tabIndex={4}
              type='primary'
              label='Search'
              htmlType='submit'
            />
          </section>
        </Form>
        <section className='sa-patient-search__modal--results'>
          <List
            dataSource={patients}
            itemLayout='horizontal'
            loading={loading}
            renderItem={(patient) => (
              <List.Item onClick={() => handleRowClick(patient)}>
                <List.Item.Meta
                  description={
                    patient?.PatientBirthdate &&
                    formatDateFromString(patient.PatientBirthdate, 'MM/dd/yyy')
                  }
                  title={
                    <div>
                      <b>{`${patient.PatientFirstName} ${patient.PatientLastName}`}</b>
                    </div>
                  }
                />
                <div>{patient?.CarrierName}</div>
              </List.Item>
            )}
          />
        </section>
      </main>
    </Modal>
  )
}

export default PatientSearchModal
