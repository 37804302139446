import generatePicker, { PickerProps, RangePickerProps } from 'antd/es/date-picker/generatePicker'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'

const DateFnsDatePicker = generatePicker<Date>(dateFnsGenerateConfig)

export type DateFnsPickerProps = PickerProps<Date>

export type DateFnsRangePickerProps = RangePickerProps<Date>

export default DateFnsDatePicker
