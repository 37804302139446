import { Avatar, Form, Tabs } from 'antd'
import { FC, useEffect } from 'react'

import { Loading } from '../_siteWide/loader/dataDisplay'
import {
  BillingContent,
  NoBillingContent,
} from '../_siteWide/payment/billing/billingContent'
import {
  PaymentContextProvider,
  usePaymentContext,
} from '../_siteWide/payment/context/paymentContext'

import '../_siteWide/payment/payment.scss'

import { useNavigate } from 'react-router-dom'

import { LogError } from 'utils'

import { GetBillingDetails } from 'trellis:api/practice/practiceApi'
import GlobalState, { LDFlags$ } from 'trellis:state/globalState'
import { showMessage } from 'trellis:utilities/general'
import { RoleHelper$ } from 'trellis:utilities/roleHelper'

import { InvoiceContextProvider } from '../_siteWide/payment/context/invoiceContext'

const { TabPane } = Tabs

const BillingDetails: FC = () => {
  const flags = LDFlags$.use()
  const { loading, payment, setIsNew, setLoading, setPayment } =
    usePaymentContext()

  const paymentType = GlobalState.BillingDetails.paymentType.use()

  const [form] = Form.useForm()

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)

    if (RoleHelper$.isDentalIntel.peek()) {
      navigate('/Claims')
      return
    }

    GetBillingDetails()
      .then(({ data }) => {
        setIsNew(false)
        setLoading(false)
        setPayment({ ...payment, ...data })
      })
      .catch((e) => {
        LogError(e, 'Failed to get billing details')
        showMessage(
          'There was an error loading Billing Details. Please try again later.',
        )
        setLoading(false)
      })
  }, [])

  form.setFieldsValue({ ...payment })

  return (
    <article className='page-content__container page-content__container--with-page-header'>
      <h1 className='page-content-header__title'>My Practice</h1>
      <Tabs className='pt-100'>
        <TabPane
          key='Payment Information'
          tab={
            <>
              <span>Billing Details</span>
              {payment.errors && payment.errors.length > 0 && (
                <Avatar
                  size='small'
                  className='form-error-count'
                >
                  {payment.errors.length}
                </Avatar>
              )}
            </>
          }
        >
          {loading && <Loading />}
          {!loading && (
            <Form form={form}>
              {paymentType.toLocaleUpperCase() === 'CREDIT CARD' && (
                <BillingContent />
              )}
              {paymentType.toLocaleUpperCase() !== 'CREDIT CARD' && (
                <NoBillingContent />
              )}
            </Form>
          )}
        </TabPane>
      </Tabs>
    </article>
  )
}

export default () => (
  <InvoiceContextProvider>
    <PaymentContextProvider>
      <BillingDetails />
    </PaymentContextProvider>
  </InvoiceContextProvider>
)
