import {
  BellFilled,
  BlockOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  PauseCircleOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  SendOutlined,
  SwapOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { observer } from '@legendapp/state/react'
import { Tooltip } from 'antd'
import { ReactNode } from 'react'

import ClaimQueuePendingIcon from 'ui:assets/claim-queue-grid-pending.gif'
import EditNoteIcon from 'ui:assets/edit-note.svg?react'
import ProcessedIcon from 'ui:assets/processed-icon.svg?react'
import ProcessingIcon from 'ui:assets/processing-icon.svg?react'
import ResentIcon from 'ui:assets/resent.svg?react'
import SendIcon from 'ui:assets/send.svg?react'
import UnsupportedCarrierIcon from 'ui:assets/unsupported-carrier-icon.svg?react'
import UploadedIcon from 'ui:assets/uploaded-icon.svg?react'
import ValidatedIcon from 'ui:assets/validated-icon.svg?react'

import './status.scss'

type StatusDisplayProps = {
  status: string
  toolTip?: ReactNode
  type?: string
  queued?: boolean | number
  getStatusOverride?: (status: string) => {
    Status: string
    StatusTooltip?: string
  }
  whiteBackgroundOverride?: boolean
}

export const StatusDisplay = observer(
  ({
    status,
    toolTip,
    type = 'icon',
    queued = false,
    getStatusOverride,
    whiteBackgroundOverride = false,
  }: StatusDisplayProps) => {
    const ttColor = whiteBackgroundOverride
      ? 'var(--white-000)'
      : 'var(--charcoal-400)'

    const toolTipText: ReactNode = whiteBackgroundOverride ? (
      <div
        style={{
          backgroundColor: 'var(--white-000, #fff)',
          color: 'var(--character-primary)',
        }}
      >
        {toolTip}
      </div>
    ) : (
      <>{toolTip}</>
    )

    return (
      <Tooltip
        title={toolTip ? toolTipText : ''}
        placement='bottomLeft'
        trigger={['hover', 'click']}
        color={ttColor}
      >
        <section className={`status-container status-container--${type}`}>
          <StatusIcon
            status={status}
            type={type}
            queued={queued === true || queued === 1}
          />
          <p className='ml-050'>
            {getStatusOverride(status)?.Status || status}
          </p>
        </section>
      </Tooltip>
    )
  },
)

export const QueueClaimPendingIcon = ({
  className,
}: {
  className?: string
}) => (
  <svg
    className={className}
    viewBox='0 0 12 12'
    width='1em'
    height='1em'
    fill='none'
    aria-hidden='true'
  >
    <image
      href={ClaimQueuePendingIcon}
      width='100%'
      height='100%'
    />
    <path
      d='M6 1C3.24 1 1 3.24 1 6C1 8.76 3.24 11 6 11C8.76 11 11 8.76 11 6C11 3.24 8.76 1 6 1ZM6 10C3.79 10 2 8.21 2 6C2 3.79 3.79 2 6 2C8.21 2 10 3.79 10 6C10 8.21 8.21 10 6 10Z'
      fill='#333333'
    />
  </svg>
)

interface StatusIconProps {
  extraClasses?: string
  isDefaultStyle?: boolean
  queued?: boolean
  showLabel?: boolean
  status: string
  type?: string
}

export const StatusIcon = ({
  isDefaultStyle = false,
  showLabel = true,
  queued = false,
  status,
  type = 'icon',
}: StatusIconProps) => {
  let icon: JSX.Element
  switch (status) {
    case 'Accepted':
    case 'Eligible':
    case 'In Queue':
    case 'Ready':
    case 'Mailed':
      icon = (
        <span
          className={
            !isDefaultStyle
              ? `status-${type}-success`
              : `status-${type}-default`
          }
        >
          <CheckCircleOutlined />
        </span>
      )
      break
    case 'Rejected':
    case 'Not Eligible':
      icon = (
        <span
          className={
            !isDefaultStyle ? `status-${type}-error` : `status-${type}-default`
          }
        >
          <CloseCircleOutlined />
        </span>
      )
      break
    case 'Failed':
    case 'Failed Validation':
    case 'Undeliverable':
      icon = (
        <span
          className={
            !isDefaultStyle ? `status-${type}-error` : `status-${type}-default`
          }
        >
          <ExclamationCircleOutlined />
        </span>
      )
      break
    case 'Carrier Unavailable':
    case 'Carrier Error':
      icon = (
        <span
          className={
            !isDefaultStyle ? `status-${type}-error` : `status-${type}-default`
          }
        >
          <HistoryOutlined />
        </span>
      )
      break
    case 'In Process':
      icon = (
        <span
          className={
            !isDefaultStyle ? `status-${type}-info` : `status-${type}-default`
          }
        >
          <SwapOutlined />
        </span>
      )
      break
    case 'Transmit':
      icon = (
        <span
          className={
            !isDefaultStyle ? `status-${type}-info` : `status-${type}-default`
          }
        >
          <SendOutlined />
        </span>
      )
      break
    case 'Hold':
    case 'On Hold':
    case 'Holding':
      icon = (
        <span
          className={
            !isDefaultStyle ? `status-${type}-info` : `status-${type}-default`
          }
        >
          <PauseCircleOutlined />
        </span>
      )
      break
    case 'Disabled':
    case 'Tax ID Error':
    case 'Other Coverage':
    case 'Additional Coverage':
      icon = (
        <span
          className={
            !isDefaultStyle
              ? `status-${type}-warning`
              : `status-${type}-default`
          }
        >
          <WarningOutlined />
        </span>
      )
      break
    case 'Printed':
    case 'Printing in Progress':
      icon = (
        <span
          className={
            !isDefaultStyle ? `status-${type}-info` : `status-${type}-default`
          }
        >
          <PrinterOutlined />
        </span>
      )
      break
    case 'Duplicate':
      icon = (
        <span
          className={
            !isDefaultStyle ? `status-${type}-info` : `status-${type}-default`
          }
        >
          <BlockOutlined />
        </span>
      )
      break
    case 'Needs Attention':
      icon = (
        <span
          className={
            !isDefaultStyle
              ? `status-${type}-warning`
              : `status-${type}-default`
          }
        >
          <InfoCircleOutlined />
        </span>
      )
      break
    case 'Unsupported Carrier':
      icon = (
        <span style={{ height: '1.5rem' }}>
          <UnsupportedCarrierIcon className={`status-${type}-default`} />
        </span>
      )
      break
    case 'Pending':
      if (queued) {
        icon = (
          <QueueClaimPendingIcon
            className={
              !isDefaultStyle
                ? `status-${type}-queue-pending`
                : `status-${type}-default`
            }
          />
        )
      } else {
        icon = (
          <span
            className={
              !isDefaultStyle ? `status-${type}-info` : `status-${type}-default`
            }
          >
            <ClockCircleOutlined />
          </span>
        )
      }
      break
    case 'Patient Response':
      icon = (
        <span
          className={
            !isDefaultStyle ? `status-${type}-info` : `status-${type}-default`
          }
        >
          <BellFilled />
        </span>
      )
      break
    case 'Pending Response':
      icon = (
        <span
          className={
            !isDefaultStyle
              ? `status-${type}-warning`
              : `status-${type}-default`
          }
        >
          <BellFilled />
        </span>
      )
      break
    case 'Carrier Response Pending':
      icon = (
        <span
          className={
            !isDefaultStyle ? `status-${type}-info` : `status-${type}-default`
          }
        >
          <HistoryOutlined />
        </span>
      )
      break
    case 'Provider Info Issue':
    case 'Insurance Info Issue':
    case 'Benefit Info Issue':
    case 'Info Needed':
    case 'Not Available':
      icon = (
        <span
          className={
            !isDefaultStyle
              ? `status-${type}-warning`
              : `status-${type}-default`
          }
        >
          <ExclamationCircleOutlined />
        </span>
      )
      break
    case 'Duplicate File Detected':
    case 'Unauthorized Submission':
    case 'Unauthorized':
      icon = (
        <span
          className={
            !isDefaultStyle ? `status-${type}-error` : `status-${type}-default`
          }
        >
          <ExclamationCircleOutlined />
        </span>
      )
      break
    case 'Uploaded':
      icon = <UploadedIcon className={`status-${type}-default`} />
      break
    case 'Processing':
      icon = (
        <ProcessingIcon
          className={
            !isDefaultStyle ? `status-${type}-info` : `status-${type}-default`
          }
        />
      )
      break
    case 'Processed':
      icon = <ProcessedIcon className={`status-${type}-default`} />
      break
    case 'Validated':
    case 'Printed and Mailed':
      icon = <ValidatedIcon className={`status-${type}-default`} />
      break
    case 'Edited':
      icon = <EditNoteIcon className={`status-${type}-default`} />
      break
    case 'Claim Sent':
    case 'Sent':
    case 'Resent':
      icon = <ResentIcon className={`status-${type}-default`} />
      break
    case 'Received by Vyne':
      icon = <SendIcon className={`status-${type}-default`} />
      break
    case 'Unknown':
    case 'Unverified':
    default:
      icon = (
        <span className={`status-${type}-default`}>
          <QuestionCircleOutlined />
        </span>
      )
      break
  }

  return showLabel ? <span className={`status-${type}`}>{icon}</span> : icon
}

export const getEligibilityStatusInfo = (status: any) => {
  const info: any = {}
  switch (status ? status.toString().toLowerCase() : status) {
    case 'unverified':
    case '0':
      info.status = 'Unverified'
      info.toolTip = 'Eligibility was not verified'
      break
    case 'eligible':
    case '1':
      info.status = 'Eligible'
      info.toolTip = 'Patient has active coverage'
      break
    case 'not eligible':
    case '2':
      info.status = 'Not Eligible'
      info.toolTip = `Patient's coverage is inactive`
      break
    case 'needs attention':
    case '3':
      info.status = 'Needs Attention'
      info.toolTip = 'Information needed to complete the request'
      break
    case 'unsupported carrier':
    case '4':
      info.status = 'Unsupported Carrier'
      info.toolTip =
        'Carrier ID not recognized, or this carrier is not yet supported'
      break
    case 'failed':
    case '5':
      info.status = 'Failed'
      info.toolTip = 'Request Failed, retry or contact carrier'
      break
    case 'carrier response pending':
    case '6':
      info.status = 'Carrier Response Pending'
      info.toolTip = `Carrier's response is pending delivery`
      break
    case 'pending response':
    case '7':
      info.status = 'Pending Response'
      info.toolTip = 'Updated information needed from patient'
      break
    case 'provider info issue':
    case '10':
      info.status = 'Provider Info Issue'
      info.toolTip = 'Verify the provider info submitted on the request'
      break
    case 'insurance info issue':
    case '20':
      info.status = 'Insurance Info Issue'
      info.toolTip = 'Verify the subscriber and patient information submitted'
      break
    case 'benefit info issue':
    case '30':
      info.status = 'Benefit Info Issue'
      info.toolTip = 'Verify the benefit or service info on the request'
      break
    case 'carrier unavailable':
    case '40':
      info.status = 'Carrier Unavailable'
      info.toolTip = `The carrier's system is currently unavailable`
      break
    case 'carrier error':
    case '50':
      info.status = 'Carrier Error'
      info.toolTip = `The carrier's system failed to respond or returned an error`
      break
    case 'info needed':
    case '60':
      info.status = 'Info Needed'
      info.toolTip = 'Information needed to complete the request'
      break
    case 'not available':
    case '70':
      info.status = 'Not Available'
      info.toolTip = 'Benefit information is not available electronically'
      break
    case 'unknown':
    case '98':
      info.status = 'Unknown'
      info.toolTip = 'No additional information available'
      break
    case 'request pending':
      info.status = 'Pending'
      info.toolTip = 'Request may take 36 hours to complete'
      break
    case 'patient response':
      info.status = 'Patient Response'
      info.toolTip = 'Patient form received.'
      break
    case 'pending patient response':
      info.status = 'Pending Response'
      info.toolTip = 'Pending patient response.'
      break
    case 'invalid carrier info':
      info.status = 'Invalid Carrier Info'
      info.toolTip = 'Carrier info missing or not found.'
      break
    default:
      info.status = 'Unverified'
      info.toolTip = 'Patient response history not available'
      break
  }

  return info
}
