import { Button, Col, Row } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'

import logo from '../../assets/reslogo3-28-2017.png'

import './receipt.scss'

import {
  InvoiceContextProvider,
  useInvoiceContext,
} from '../_siteWide/payment/context/invoiceContext'
import { RpReceiptContent } from '../../api/billing/billing-client'

export const Receipt = () => {
  const [successHeader, setSuccessHeader] = useState(null)
  const [model, setModel] = useState<RpReceiptContent>(null)

  const { getReceipt } = useInvoiceContext()

  const printRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  // NOTE: timerRef and useEffect are only used for redirectToIndex which will not be needed in SPA since it will all be on a single page
  const timerRef = useRef(null)
  const redirectToIndex = () => {
    timerRef.current = setTimeout(
      () => (window.location.href = `/Payment`),
      100,
    )
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const receiptId = urlParams.get('receiptIdentifier')
    const receipt = getReceipt(receiptId)

    if (!receipt) {
      redirectToIndex()
    }

    setModel(receipt)

    if (receipt?.IsRecurring) {
      setSuccessHeader(
        'Congratulations! You have successfully setup a recurring payment.',
      )
    } else {
      setSuccessHeader('Congratulations! You have made a successful payment.')
    }
  }, [])

  const getCardImage = () => {
    const cardType = model?.EmailParams['CardType'].trim()
    let cardClass = ''

    switch (cardType) {
      case 'VS':
      case 'V':
        cardClass = 'fab fa-cc-visa'
        break
      case 'MC':
        cardClass = 'fab fa-cc-mastercard'
        break
      case 'DC':
        cardClass = 'fab fa-cc-discover'
        break
      case 'AE':
        cardClass = 'fab fa-cc-amex'
        break
    }

    return cardClass
  }

  return (
    <article
      id='page'
      className='page-content__container page-content__container--with-page-header'
      ref={printRef}
    >
      <div>
        <Row className='row-header'>
          <Col flex={1}>
            <h1 className='page-content-header__title'>Receipt</h1>
          </Col>
          <Col>
            <img
              className='logo'
              src={logo}
              width='135'
              alt='logo'
            />
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <div className='success-header'>{successHeader}</div>
          </Col>
        </Row>
      </div>
      <Row
        className='main-content'
        gutter={16}
      >
        <Col span={12}>
          <Row style={{ padding: '8px 0' }}>
            <div>
              <strong>Customer: </strong>
              {model?.EmailParams['GCID']} -{' '}
              {model?.EmailParams['CustomerName']}
            </div>
          </Row>
          <Row style={{ padding: '8px 0' }}>
            <div>
              <strong>Date: </strong>
              {model?.EmailParams['LongDate']}
            </div>
          </Row>
          <Row style={{ padding: '8px 0' }}>
            <div>
              <strong>Account/Tax ID: </strong>
              {model?.EmailParams['TIN']}
            </div>
          </Row>
          <Row style={{ padding: '8px 0' }}>
            <p className='note-text'>{paymentNoteText}</p>
          </Row>
        </Col>
        <Col span={12}>
          <Row className='card-info'>
            <div className='card-text'>{model?.EmailParams['Card']} </div>
            <span className={getCardImage()}></span>
          </Row>
          <Row className='row-payment'>
            <Col span={12}>
              <strong>One-time payment:</strong>
            </Col>
            <Col span={12}>
              <div>{model?.EmailParams['OnetimeAmt']}</div>
            </Col>
          </Row>
          <Row className='row-payment'>
            <Col span={12}>
              <strong>Pre-Pay:</strong>
            </Col>
            <Col span={12}>
              <div>{model?.EmailParams['PayAheadAmt']}</div>
            </Col>
          </Row>
          <Row className='row-payment'>
            <Col span={12}>
              <strong>Amount Charged:</strong>
            </Col>
            <Col span={12}>
              <div>{model?.EmailParams['AmountCharged']}</div>
            </Col>
          </Row>
          <Row className='row-payment'>
            <Col span={12}>
              <strong>Recurring:</strong>
            </Col>
            <Col span={12}>
              <div>
                {model?.IsRecurring
                  ? model.EmailParams['RecurringPlan']
                  : 'N/A'}
              </div>
              <div>
                {model?.IsRecurring
                  ? `Starting on: ${model.EmailParams['RecurringPaymentDate']}`
                  : ''}
              </div>
            </Col>
          </Row>
          <Row className='row-print'>
            <Button
              className='block-button no-print'
              onClick={handlePrint}
            >
              <i className='far fa-print print-icon'></i> Print
            </Button>
          </Row>
        </Col>
      </Row>
    </article>
  )
}

const paymentNoteText =
  'Note that it may take 3 - 5 business days for balance changes to be processed and to reflect in your balance on RPractice.  Make sure and check the "As Of" date if you have made a recent payment.'

const content = (
  <InvoiceContextProvider>
    <Receipt />
  </InvoiceContextProvider>
)
