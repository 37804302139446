import { Col, Row, Table } from 'antd'
import { format } from 'date-fns'
import React, { FC } from 'react'

import {
  Invoice,
  InvoiceLineItem,
} from '../../../../api/billing/billing-client'
import {
  addCommasToNumber,
  formatDateString,
} from '../../../../utilities/general'
import { Loading } from '../../loader/dataDisplay'

interface InvoiceSummaryProps {
  loading: boolean
  invoice: Invoice
}

const formatInvoiceNumber = (date: string, number: string): string =>
  `${formatDateString(date, 'MMyy')} - ${number}`

export const InvoiceSummary: FC<InvoiceSummaryProps> = ({
  loading,
  invoice,
}) => {
  if (loading) return <Loading />

  if (!invoice?.LineItems?.length)
    return (
      <div className='pt-200 pl-100 fs-125'>
        We do not currently see an invoice in our system that you have access
        to, please contact support at (866) 712-9584 Ext. 2452
      </div>
    )

  const lastItem = invoice?.LineItems[invoice.LineItems.length - 1]
  const lastInvoice = formatInvoiceNumber(
    lastItem.InvoiceDt,
    lastItem.InvoiceNum,
  )

  return (
    <>
      <InvoiceHeaderRow
        invoice={invoice}
        lastInvoice={lastInvoice}
      />
      <InvoiceTable
        loading={loading}
        invoices={invoice.LineItems}
      />
    </>
  )
}

interface InvoiceHeaderRowProps {
  invoice: Invoice
  lastInvoice: string
}

const InvoiceHeaderRow: FC<InvoiceHeaderRowProps> = ({
  invoice,
  lastInvoice,
}) => (
  <Row className='p-200'>
    {invoice.InvoiceAddress && (
      <Col>
        <Row>
          <b>{invoice.InvoiceAddress.PracticeName}</b>
        </Row>
        <Row className='pt-025'>{invoice.InvoiceAddress.Address}</Row>
        <Row className='pt-025'>
          {`${
            invoice.InvoiceAddress.City
          }, ${invoice.InvoiceAddress.State?.toUpperCase()} ${
            invoice.InvoiceAddress.Zip
          }`}
        </Row>
        <Row className='pt-025'>{invoice.InvoiceAddress.Phone}</Row>
      </Col>
    )}
    <Col className='pl-250'>
      <Row>
        <b>Renaissance Systems Services, LLC.</b>
      </Row>
      <Row className='pt-025'>P.O. Box 67000 Dept. 277501</Row>
      <Row className='pt-025'>Detroit, MI 48267-2775</Row>
      <Row className='pt-025'>(866)712-9584</Row>
      <Row className='pt-025'>
        <a href='mailto:billing@vynedental.com'>billing@vynedental.com</a>
      </Row>
    </Col>
    {invoice.Tin && (
      <Col className='pl-250'>
        <Row>
          <Col flex={1}>
            <b className='pr-025'>Account:</b>
          </Col>
          <Col className='pl-100'>{invoice.Tin}</Col>
        </Row>
        <Row className='pt-025'>
          <Col flex={1}>
            <b className='pr-025'>Date:</b>
          </Col>
          <Col className='pl-100'>{format(new Date(), 'MM/dd/yyyy')}</Col>
        </Row>
        <Row className='pt-025'>
          <Col flex={1}>
            <b className='pr-025'>Invoice:</b>
          </Col>
          <Col className='pl-100'>{lastInvoice || 'N/A'}</Col>
        </Row>
        <Row className='pt-025'>
          <Col flex={1}>
            <b className='pr-025'>Subtotal:</b>
          </Col>
          <Col className='pl-100'>
            ${addCommasToNumber(invoice.InvoiceTotal.toFixed(2))}
          </Col>
        </Row>
        <Row className='pt-025'>
          <Col flex={1}>
            <b className='pr-025'>Tax (Avatax):</b>
          </Col>
          <Col className='pl-100'>
            (${addCommasToNumber(Math.abs(invoice.InvoiceTaxAmount).toFixed(2))}
            )
          </Col>
        </Row>
        <Row className='pt-025'>
          <Col flex={1}>
            <b className='pr-025'>Amount Due:</b>
          </Col>
          <Col className='pl-100'>
            $
            {addCommasToNumber(
              (
                Math.abs(invoice.InvoiceTaxAmount) + invoice.InvoiceTotal
              ).toFixed(2),
            )}
          </Col>
        </Row>
      </Col>
    )}
  </Row>
)

interface InvoiceTableProps {
  loading: boolean
  invoices: InvoiceLineItem[]
}

const InvoiceTable: FC<InvoiceTableProps> = ({ loading, invoices }) => {
  invoices.map((invoice: any, i: number) => {
    invoice.InvoiceId = i
  })

  const columns = [
    {
      title: 'Bill Date',
      dataIndex: 'InvoiceDt',
      noFilter: true,
      noSort: true,
      width: 150,
      render: (value: string) => formatDateString(value, 'MM/dd/yyyy'),
    },
    {
      title: 'Product',
      dataIndex: 'BillMsg',
      noFilter: true,
      noSort: true,
      width: 150,
    },
    {
      title: 'Invoice #',
      dataIndex: 'InvoiceNum',
      noFilter: true,
      noSort: true,
      width: 150,
      render: (value: string, row: any) =>
        formatInvoiceNumber(row.InvoiceDt, row.InvoiceNum),
    },
    {
      title: 'Subtotal',
      dataIndex: 'AmountBilled',
      noFilter: true,
      noSort: true,
      width: 150,
      render: (value: any) =>
        value > 0 ? `$${value.toFixed(2)}` : `($${Math.abs(value).toFixed(2)})`,
    },
  ]

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={invoices}
      rowKey='InvoiceId'
      pagination={false}
      className='pt-200'
    />
  )
}
