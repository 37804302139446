import { Col, Dropdown, Menu, Row } from 'antd'
import React, { Dispatch, FC, SetStateAction } from 'react'

import { ImageType } from '../../../../../api/attachment/attachment-client/api'
import { ImageOrientationType } from '../../../attachment.interface'
import { imagesOptions, optionType, otherOptions } from './dropdownOptions'

import './imageTypeDropdown.scss'

import { DownOutlined } from '@ant-design/icons'

type ImageDropDownProps = {
  setImageType: Dispatch<SetStateAction<ImageType>>
  setIsXrayType: Dispatch<SetStateAction<boolean>>
  setOrientation: Dispatch<SetStateAction<ImageOrientationType>>
  activeImageTypeOption: string
  setActiveImageTypeOption: Dispatch<SetStateAction<string>>
  defaultImageDropdownText: string
  orientation: any
}

const ImageTypeDropDown: FC<ImageDropDownProps> = ({
  orientation,
  setImageType,
  setIsXrayType,
  setOrientation,
  activeImageTypeOption,
  setActiveImageTypeOption,
  defaultImageDropdownText,
}) => {
  const onImageTypeChange = (selectedImageTypeOption: optionType) => {
    const { isXray } = selectedImageTypeOption

    setImageType(selectedImageTypeOption)
    setIsXrayType(isXray)
    if (!isXray) {
      setOrientation(ImageOrientationType.None)
    } else {
      let imageOrientationType = ImageOrientationType.LeftIsRight
      if (localStorage.getItem('localStorageImageOrientation') == 'LEFT') {
        imageOrientationType = ImageOrientationType.LeftIsLeft
      }
      setOrientation(imageOrientationType)
    }
  }

  const onChangeXray = (value: optionType) => {
    setActiveImageTypeOption(value.imageTypeName)
    onImageTypeChange(value)
  }

  const menuImageOptions = imagesOptions.map((option) => ({
    key: option.imageTypeId,
    label: (
      <div onClick={() => onChangeXray(option)}>{option.imageTypeName}</div>
    ),
  }))

  const menuOtherOptions = otherOptions.map((option) => ({
    key: option.imageTypeId,
    label: <a onClick={() => onChangeXray(option)}>{option.imageTypeName}</a>,
  }))

  const menu = (
    <Menu
      className='image-type-menu'
      items={[
        {
          key: 'images',
          label: 'Images',
          children: menuImageOptions,
        },
        {
          key: 'otherType',
          label: 'Other Type',
          children: menuOtherOptions,
        },
      ]}
    />
  )

  const isDefaultOption = activeImageTypeOption === defaultImageDropdownText

  return (
    <Row className='image-type-container mb-150'>
      <Col
        span={24}
        className='image-type'
      >
        <h4 className='image-type__title mb-050'>Image Type</h4>
      </Col>
      <Col span={24}>
        <Dropdown
          overlayStyle={{ borderRadius: 0 }}
          overlay={menu}
        >
          <a
            className={`image-type__dropdown ${
              isDefaultOption ? `image-type__dropdown--default` : ''
            }`}
          >
            <span>{activeImageTypeOption} &nbsp;</span>
            <DownOutlined />
          </a>
        </Dropdown>
      </Col>
    </Row>
  )
}

export default ImageTypeDropDown
