import { useObservable } from '@legendapp/state/react'
import { Table, TableColumnsType, Tooltip } from 'antd'
import { format } from 'date-fns'
import { FC, useEffect, useState } from 'react'

import { StatusIcon } from 'ui'

import { ClaimActivityLogRecord } from 'trellis:api/claim/claim-client'
import { ClaimDetailsModelExtended } from 'trellis:components/claims/context/claimDetailContext'
import { ClaimStatus } from 'trellis:components/claims/detail/tabs/status/claimStatus'
import { LDFlags$ } from 'trellis:state/globalState'

import {
  matchClaimStatus,
  matchClaimStatusDescription,
} from '../../util/claim-status-mapping'

import textFormattingStyles from 'trellis:styles/text-formatting.module.scss'

export interface MappedClaimActivityLogRecord extends ClaimActivityLogRecord {
  StatusDescriptionTooltip?: string
  StatusTooltip?: string
}
interface ClaimActivityLogProps {
  claim: ClaimDetailsModelExtended
  activityLog: MappedClaimActivityLogRecord[]
}

// TODO: update save/send claim to push field updates
export const ClaimActivityLog: FC<ClaimActivityLogProps> = ({
  claim,
  activityLog,
}) => {
  const [tableScrollHeight, setTableScrollHeight] = useState<number>(0)

  useEffect(() => {
    const bodyElement: HTMLElement = document.querySelector('body')
    if (bodyElement) resizeObserver.observe(bodyElement)

    return () => resizeObserver.unobserve(bodyElement)
  }, [])

  const resizeObserver = new ResizeObserver(() => {
    const tableElement: HTMLElement = document.querySelector('.ant-table-body')
    if (tableElement) {
      const scrollHeight =
        window.innerHeight -
        (Math.round(tableElement.getBoundingClientRect().top) + 90)
      setTableScrollHeight(scrollHeight)
    }
  })

  const getEditedStatusDescription = (
    record: MappedClaimActivityLogRecord,
  ): string[] => {
    const editedFields: string[] = []
    record?.Data?.FieldUpdates.map((update) => {
      if (update.LineItemIndex >= 0) {
        editedFields.push(
          `${update.FieldName} on Procedures line ${update.LineItemIndex + 1}`,
        )
      } else {
        editedFields.push(update.FieldName)
      }
    })

    return editedFields
  }

  const mapClaimActivityLogData = (
    data: MappedClaimActivityLogRecord[],
  ): MappedClaimActivityLogRecord[] => {
    const mappedData: MappedClaimActivityLogRecord[] = data.map((record) => {
      matchClaimStatus(record)
      matchClaimStatusDescription(record)

      switch (record.Status) {
        case 'Edited':
          record.StatusDescription = record.Data?.FieldUpdates?.length
            ? record.UserName
              ? `${getEditedStatusDescription(record).join(', ')}, were updated by user ${record.UserName}`
              : `${getEditedStatusDescription(record).join(', ')}, were edited.`
            : record.UserName
              ? `Claim was edited by user ${record.UserName}`
              : 'Claim was edited.'
          break
        case 'Hold':
          record.StatusDescription = record.UserName
            ? `Claim placed on hold by user ${record.UserName}.`
            : 'Claim was placed on hold.'
          break
        case 'Resent':
          record.StatusDescription = record.UserName
            ? `Claim resent by user ${record.UserName}.`
            : 'Claim was resent.'
          break
        case 'Claim Sent':
          record.StatusDescription = record.UserName
            ? `Claim sent by user ${record.UserName}.`
            : 'Claim was sent.'
      }

      return record
    })

    return mappedData
  }

  const mapStatusDescription = (
    value: string,
    record: MappedClaimActivityLogRecord,
  ) => {
    let alteredValue: string = ''

    if (value) {
      alteredValue = value
        .replace('NEA Attachment', 'NEA')
        .replace('please add', '')
        .replace(' for this claim', '')
        .replace(' for Claim.', '')
        .replace('Please verify information and try again.', '')
    }

    const description = (
      <div
        className={textFormattingStyles['truncate-text']}
        data-testid={`claim-activity-log-record-${record.Id}-description`}
      >
        {alteredValue}
      </div>
    )

    const tooltip =
      alteredValue?.length > 45 || record?.StatusDescriptionTooltip ? (
        <div data-testid={`claim-activity-log-${record.Id}-status-tooltip`}>
          {alteredValue?.length > 45 && (
            <div className='mb-100'>{alteredValue}</div>
          )}
          {record.StatusDescriptionTooltip && (
            <div>{record.StatusDescriptionTooltip}</div>
          )}
        </div>
      ) : null

    return (
      <Tooltip
        mouseEnterDelay={1}
        placement='topLeft'
        title={tooltip}
      >
        {description}
      </Tooltip>
    )
  }

  const columns: TableColumnsType<MappedClaimActivityLogRecord> = [
    {
      align: 'right',
      dataIndex: 'AddDate',
      defaultSortOrder: 'descend',
      key: 'AddDate',
      render: (text: string) => format(new Date(text), 'MM/dd/yyyy h:mm a'),
      sorter: (a, b) => a.Id - b.Id,
      title: 'Date',
      width: 175,
    },
    {
      dataIndex: 'Status',
      key: 'Status',
      render: (text: string, record: MappedClaimActivityLogRecord) => {
        const tooltipText = record.StatusTooltip ? record.StatusTooltip : null
        return (
          <Tooltip
            mouseEnterDelay={1}
            title={tooltipText}
          >
            <div className='flex align-center'>
              <StatusIcon
                isDefaultStyle={true}
                status={text}
                showLabel={false}
                type='icon'
              />
              <p
                className='ml-4-px'
                data-testid={`claim-activity-log-record-${record.Id}-status`}
              >
                {text}
              </p>
            </div>
          </Tooltip>
        )
      },
      title: 'Status',
      width: 175,
    },
    {
      dataIndex: 'StatusDescription',
      key: 'StatusDescription',
      render: mapStatusDescription,
      title: 'Description',
    },
  ]

  const pagination = {
    defaultCurrent: 1,
    defaultPageSize: 10,
    showSizeChanger: true,
    showTotal: (total: number, range: [number, number]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    total: activityLog?.length,
  }

  const mappedActivityLogData$ = useObservable<MappedClaimActivityLogRecord[]>(
    mapClaimActivityLogData(activityLog),
  )

  return (
    <>
      {!LDFlags$.activityLog.get() ||
      (!activityLog.length && claim.StatusHistory) ? (
        <ClaimStatus />
      ) : (
        <Table
          data-testid='claim-activity-log'
          className='table--non-smart mt-100'
          columns={columns}
          dataSource={mappedActivityLogData$.get()}
          loading={!activityLog}
          pagination={pagination}
          rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
          rowKey='Id'
          scroll={{ y: tableScrollHeight }}
        />
      )}
    </>
  )
}
