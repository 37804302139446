import { LoadingOutlined } from '@ant-design/icons'
import { ObservableObject } from '@legendapp/state'
import { format } from 'date-fns'
import { FC } from 'react'

import StructuredViewBenefits from '../components/structuredViewBenefits'
import StructuredViewPatientSubscriber from '../components/structuredViewPatientSubscriber'
import StructuredPlan from '../components/structuredViewPlan'
import StructuredViewPlanDetails from '../components/structuredViewPlanDetails'
import {
  EligibilityResponseStateType,
  StructuredPatSubDetails,
  StructuredPlanDetails,
  StructuredViewPlan,
} from '../utilities/eligibilityTyping'

import './StructuredView.scss'

type StructuredViewProps = {
  state: ObservableObject<EligibilityResponseStateType>
}

const StructuredView: FC<StructuredViewProps> = ({ state }) => {
  const structuredOnederfulResponse = state.structuredOnederfulResponse.use()
  const structuredView = state.structuredView.use()

  const getPatientDetails = () => {
    const { EligibilityParams: EP } = structuredOnederfulResponse

    return {
      lastName: EP?.PatientLastName,
      firstName: EP?.PatientFirstName,
      birthDate:
        EP?.PatientBirthdate &&
        format(new Date(EP.PatientBirthdate), 'MM/dd/yyyy'),
      memberId: EP?.SubscriberId,
      address: structuredView?.patient?.address?.street1,
      city: structuredView?.patient?.address?.city,
      state: structuredView?.patient?.address?.state,
      zipCode: structuredView?.patient?.address?.zip,
    } as StructuredPatSubDetails
  }

  const getSubscriberDetails = () => {
    const { EligibilityParams: EP } = structuredOnederfulResponse

    return {
      lastName: EP?.SubscriberLastName,
      firstName: EP?.SubscriberFirstName,
      birthDate:
        EP?.SubscriberBirthdate &&
        format(new Date(EP.SubscriberBirthdate), 'MM/dd/yyyy'),
      memberId: EP?.SubscriberId,
      address: structuredView?.subscriber?.address?.street1,
      city: structuredView?.subscriber?.address?.city,
      state: structuredView?.subscriber?.address?.state,
      zipCode: structuredView?.subscriber?.address?.zip,
    } as StructuredPatSubDetails
  }

  const getPlan = () => {
    return {
      name: structuredView?.plan?.name,
      group_number: structuredView?.plan?.group_number,
      start_date:
        structuredView?.coverage?.start_date &&
        format(new Date(structuredView?.coverage?.start_date), 'MM/dd/yyyy'),
      period:
        structuredView?.plan?.period?.charAt(0).toUpperCase() +
        structuredView?.plan?.period?.slice(1),
      effective_date:
        structuredView?.coverage?.effective_date &&
        format(
          new Date(structuredView?.coverage?.effective_date),
          'MM/dd/yyyy',
        ),
      end_date:
        structuredView?.coverage?.end_date &&
        format(new Date(structuredView?.coverage?.end_date), 'MM/dd/yyyy'),
    } as StructuredViewPlan
  }

  const getPlanDetails = () => {
    return {
      pays_on: structuredView?.plan?.seat_or_prep_date?.toUpperCase(),
      crown_downgrades: structuredView?.plan?.downgrade_on_crowns,
      diagnosis_downgrades:
        structuredView?.plan?.should_apply_deductible_to_diagnostic,
      preventative_deductible:
        structuredView?.plan?.should_apply_deductible_to_preventive,
      fillings_downgrades: structuredView?.plan?.downgrade_on_fillings,
    } as StructuredPlanDetails
  }

  return structuredOnederfulResponse ? (
    <div
      className='structured-response'
      data-testid='structured-view-response'
    >
      <section className='structured-response--info'>
        <StructuredViewPatientSubscriber
          type='Patient'
          details={getPatientDetails()}
        />
        <StructuredViewPatientSubscriber
          type='Subscriber'
          details={getSubscriberDetails()}
        />
        <StructuredPlan
          type='Plan'
          details={getPlan()}
        />
        <StructuredViewPlanDetails
          type='Plan Details'
          details={getPlanDetails()}
        />
      </section>
      <StructuredViewBenefits state={state} />
    </div>
  ) : (
    <LoadingOutlined
      className='ml-100 loading-structured-response'
      spin
    />
  )
}

export default StructuredView
