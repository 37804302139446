import { CustomerTypes } from '../../constants/general'
import { IAuthenticatedApiModel } from '../../utilities/api'
import { PmgApi } from '../pmg/pmgApi'
import { IClaimsApi } from './claimApi'
import { CustomerApi } from './customerApi'

export const enum ApiTypes {
  CLAIM = 'claim',
  PMG = 'pmg',
}

export const ClaimsApiFactory = (
  authentication: IAuthenticatedApiModel,
  pmgOverride: boolean = false,
  standardOverride: boolean = false,
): IClaimsApi => {
  const isPmg: boolean =
    authentication.CustomerTypeId !== CustomerTypes.Standard
  if ((isPmg || pmgOverride) && !standardOverride) {
    return new PmgApi(authentication, ApiTypes.PMG)
  } else {
    return new CustomerApi(authentication, ApiTypes.CLAIM)
  }
}
