import { LoadingSpinner } from 'ui'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LogError } from 'utils'
import { showMessage } from 'trellis:utilities/general';
import { logoutUser } from 'trellis:features/authentication/Login/utils/login-helpers'
import { useLDClient } from 'launchdarkly-react-client-sdk';
import GlobalState from 'trellis:state/globalState'
import { GetSsoUrl } from 'trellis:api/authentication/authenticationApi';
import { ProductType } from 'trellis:api/authentication/authentication-client';
import { TermsAndConditions } from 'trellis:constants/general';
import { confirmEulaAndReloadUser, getEulaReturnPath } from 'trellis:utilities/eulaHelper';
  
const AcceptEulaExternal: FC = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const location = useLocation()
    const ldClient = useLDClient()
    const navigate = useNavigate()
    const origin: string = window.location.origin
    const redirectPath: string = location?.state?.redirectURL
    const userInfo = GlobalState.UserInfo.use()

    useEffect(() => {
      setLoading(true)

      const handleEula = async () => {
          try {
            const eulaAccepted = await confirmEulaAndReloadUser(userInfo.customerUserID)
            if(eulaAccepted) {
              navigate(redirectPath || TermsAndConditions.redirectRootPath)
            } else {
              const eulaReturnPath = getEulaReturnPath(
                TermsAndConditions.termsPath, 
                origin + (redirectPath || TermsAndConditions.redirectRootPath), 
                origin + TermsAndConditions.logoutPath
              )

              const ssoUrlresponse = await GetSsoUrl({destinationProduct: ProductType.Sso, returnPath: eulaReturnPath})
              window.location.replace(ssoUrlresponse.data.ssoUrl)
            }
          } catch(error) {
            showMessage('There was a problem getting the EULA.')
            LogError(error, "Failed to get EULA")
            logoutUser(ldClient)
          } finally {
            setLoading(false)
          }
      }

      handleEula()
    }, [])
  
    return (
        loading ? <LoadingSpinner /> : ''
    )
}
  
export default AcceptEulaExternal