import { addMonths, setDate } from 'date-fns'
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { LogError } from 'utils'

import { validateRequiredFields } from '../../_siteWide/form/util'
import { useInvoiceContext } from '../../_siteWide/payment/context/invoiceContext'
import {
  MakePaymentRequest,
  PaymentViewModel,
} from '../../../api/billing/billing-client'
import {
  GetTsysPaymentInfo,
  MakePayment,
} from '../../../api/billing/billingApi'
import { useGlobalContext } from '../../../context/GlobalContextProvider'
import { formatDateString, showMessage } from '../../../utilities/general'
import {
  removeItemFromLocalStorage,
  storageVariables,
} from '../../../utilities/localStorageHelpers'
import { validateProperty } from '../../../utilities/validators/baseValidator'
import { validateZip } from '../../../utilities/validators/zipValidator'

const SubmitPaymentContext = createContext(null)
const defaultOptionalFields = [
  'SaveNewCard',
  'CardNickname',
  'SelectedSavedCard',
]
const defaultPayment: any = {
  PaymentDay: null,
  tsepToken: null,
  CVV: null,
  MaskedCardNumber: null,
  'tsep-cardNum': '',
  'tsep-datepicker': '',
  CardExpiration: null,
  CreditCardExpireMonth: '',
  CreditCardExpireYear: '',
  Zip: '',
  SaveNewCard: false,
  SelectedSavedCard: null,
  CardNickname: '',
  errors: [],
}

export interface SubmitPaymentModel extends PaymentViewModel {
  PaymentDay?: string
  InternalPaymentGuid?: string
  SelectedSavedCard?: string
  CardNumber?: string
  SaveNewCard?: boolean
  errors?: string[]
}

export const SubmitPaymentContextProvider = ({ children }: any) => {
  const [initialLoad, setInitialLoad] = useState(true)
  const [loading, setLoading] = useState(false)
  const [tin, setTin] = useState()
  const [payment, setPayment] = useState<SubmitPaymentModel>(defaultPayment)
  const [tsys, setTsys] = useState<any>({})
  const [savedCards, setSavedCards] = useState([])
  const [loadingCards, setLoadingCards] = useState(true)
  const [usingSavedCard, setUsingSavedCard] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [optionalFields, setOptionalFields] = useState(defaultOptionalFields)
  const [cardNumIsValid, setCardNumIsValid] = useState(true)
  const [cardExpDateIsValid, setCardExpDateIsValid] = useState(true)

  const { authentication } = useGlobalContext()
  const { selectedInvoice, saveReceipt, refreshInvoices } = useInvoiceContext()

  // NOTE: timerRef and useEffect are only used for redirectToReceipt which will not be needed in SPA since it will all be on a single page
  const timerRef = useRef(null)
  useEffect(() => {
    return () => clearTimeout(timerRef.current)
  }, [])

  const onSubmit = (formData: any) => {
    setSubmitting(true)

    setTimeout(() => {
      const updatedPaymentInfo = validate(formData)
      if (!usingSavedCard && !updatedPaymentInfo.isValid) {
        showMessage('Please correct all required fields and try again.')
        setSubmitting(false)
        return
      }

      const tsepStorage = window.localStorage.getItem('tsep')

      const tsep = JSON.parse(tsepStorage)

      if (tsep?.responseCode === 'TSEP001') {
        showMessage(
          'Session was corrupted. Please refresh the page and try again.',
        )
        removeItemFromLocalStorage(storageVariables.tsepJson)
        setSubmitting(false)
        return
      }

      if (tsep && tsep?.status !== 'PASS') {
        showMessage(
          'Something went wrong. Please verify the card information is correct and try again.',
        )
        setSubmitting(false)
        return
      }

      const copy = { ...payment }
      copy.CardNumber = `************${tsep?.maskedCardNumber}`

      let paymentRequest: MakePaymentRequest

      if (usingSavedCard) {
        paymentRequest = {
          SelectedSavedCard: payment?.SelectedSavedCard?.toString(),
        }
      } else {
        paymentRequest = {
          TsepToken: tsep.tsepToken,
          DeviceId: copy.DeviceId,
          Manifest: copy.Manifest,
          CardExpiration: tsep?.expirationDate,
          CardLastFour: tsep?.maskedCardNumber,
          CardType: tsep?.cardType,
          Zip: formData.Zip,
          SaveNewCard: formData.SaveNewCard,
          CardNickname: formData.CardNickname,
        }
      }

      paymentRequest = {
        ...paymentRequest,
        InternalPaymentGuid: copy.InternalPaymentGuid,
        CustomRecurringStartDate: getPaymentDate(formData.PaymentDay),
        Balance: selectedInvoice.Balance,
      }

      MakePayment(
        authentication,
        selectedInvoice.InvoiceEntityId,
        paymentRequest,
      )
        .then(({ data }) => {
          if (data.ErrorMessage) {
            showMessage(data.ErrorMessage)
            setSubmitting(false)
            startNewSession()
          } else {
            showMessage('Successfully submitted payment method', 'success')
            saveReceipt(data.ReceiptIdentifier, data.Receipt)
            setPayment(copy)
            setSubmitting(false)
            window.localStorage.removeItem('tsep')
            refreshInvoices()
            redirectToReceipt(data?.ReceiptIdentifier)
          }
        })
        .catch((e) => {
          showMessage(
            'There was an error submitting your payment. Please try again later.',
          )
          setPayment(copy)
          setSubmitting(false)
          window.localStorage.removeItem('tsep')
          LogError(e, 'Failed to make payment')
        })
    }, 2000)
  }

  const getPaymentDate = (paymentDay: string): string => {
    let customDate = new Date()

    customDate = addMonths(customDate, 1)
    customDate = setDate(customDate, Number(paymentDay))

    return customDate.toISOString().split('T')[0]
  }

  const startNewSession = async () => {
    document.getElementById('tsep-cardNum')?.remove()
    document.getElementById('tsep-datepicker')?.remove()

    GetTsysPaymentInfo(authentication)
      .then(({ data }) => {
        const model: SubmitPaymentModel = data?.PaymentViewModel

        if (model) {
          model.PaymentDay = formatDateString(
            model.CustomRecurringStartDate,
            'd',
          )
          model.InternalPaymentGuid = crypto.randomUUID()
          // We refresh the session by adding tsep library to header.
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.src = `${model.TsepBaseUrl}${model.DeviceId}?${model.Manifest}`
          script.async = true
          document.head.appendChild(script)

          if (data?.SavedCards?.length) {
            model.SelectedSavedCard = data?.SavedCards[0].WalletID?.toString()
          } else {
            model.SelectedSavedCard = 'new'
          }
        }
        setPayment(model)
      })
      .catch((e) => {
        showMessage(
          'The payment session was corrupted. Please refresh the page and try again.',
        )
        LogError(e, 'Failed to get payment model')
      })
  }

  // NOTE: redirectToRegistrationFinal will not be needed in SPA since it will all be on a single page
  const redirectToReceipt = (receiptIdentifier: string) => {
    const receiptUrl = `/Payment/Receipt?receiptIdentifier=${receiptIdentifier}`
    timerRef.current = setTimeout(
      () => (window.location.href = receiptUrl),
      2000,
    )
  }

  const toggleSaveCard = () => {
    const copy = { ...payment }
    let copyOptionalFields: any = [...optionalFields]
    copy.SaveNewCard = !copy.SaveNewCard
    copyOptionalFields = copy.SaveNewCard
      ? ['SaveNewCard', 'SelectedSavedCard']
      : defaultOptionalFields
    copy.errors = []
    validateRequiredFields(copy, copyOptionalFields)
    setOptionalFields(copyOptionalFields)
    setPayment(copy)
  }

  const onCardChange = (walletId: any) => {
    const copy = { ...payment }
    copy.SelectedSavedCard = walletId
    setPayment(copy)
    setUsingSavedCard(walletId !== 'new')
  }

  const onNewCardChange = () => {
    const value = !usingSavedCard
    const copy = { ...payment }
    copy.SelectedSavedCard = !value ? 'new' : copy.SelectedSavedCard
    setUsingSavedCard(!usingSavedCard)
  }

  const validate = (formData: any) => {
    const copy = { ...payment }
    let copyOptionalFields: any = [...optionalFields]
    copyOptionalFields = copy.SaveNewCard
      ? ['SaveNewCard', 'SelectedSavedCard']
      : defaultOptionalFields
    formData.isValid = true
    formData.errors = []
    validateRequiredFields(formData, copyOptionalFields)
    validateProperty(validateZip, formData, 'Zip', null, true)

    if (formData.errors && formData.errors.length) {
      formData.isValid = false
    }

    const updatedPayment = { ...copy, ...formData }
    setPayment(updatedPayment)
    return updatedPayment
  }

  return (
    <SubmitPaymentContext.Provider
      value={{
        // state
        initialLoad,
        setInitialLoad,
        loading,
        setLoading,
        tin,
        setTin,
        selectedInvoice,
        payment,
        setPayment,
        savedCards,
        setSavedCards,
        tsys,
        setTsys,
        loadingCards,
        setLoadingCards,
        usingSavedCard,
        setUsingSavedCard,
        submitting,
        optionalFields,
        cardNumIsValid,
        setCardNumIsValid,
        cardExpDateIsValid,
        setCardExpDateIsValid,

        // functions
        onSubmit,
        toggleSaveCard,
        onCardChange,
        onNewCardChange,
      }}
    >
      {children}
    </SubmitPaymentContext.Provider>
  )
}

export const useSubmitPaymentContext = () => {
  const context = useContext(SubmitPaymentContext)
  if (context === undefined) {
    throw new Error('Context must be used within a Provider')
  }
  return context
}
