import { ObservableObject } from '@legendapp/state'
import { useObserve } from '@legendapp/state/react'
import { FC, RefObject, useEffect, useState } from 'react'

import { TextFinderState } from '../TextFinder'

interface TextFinderInputProps {
  elementRef: RefObject<HTMLElement>
  state: ObservableObject<TextFinderState>
}

const TextFinderInput: FC<TextFinderInputProps> = ({ elementRef, state }) => {
  const [timeoutId, setTimeoutId] =
    useState<ReturnType<typeof setTimeout>>(null)

  useEffect(() => {
    return () => clearTimeout(timeoutId)
  }, [])

  useObserve(state.textToFind, ({ value }) => {
    if (elementRef?.current) handleOnChange(value)
  })

  const handleOnChange = (value: string) => {
    clearTimeout(timeoutId) // stop current search setTimeout

    const duration = value ? 1000 : 500
    const newTimeoutId = setTimeout(() => {
      recursiveElementTextSearch(elementRef.current, value)
    }, duration)

    setTimeoutId(newTimeoutId)
  }

  // iterate all child nodes of element ref
  // update innerhtml/text for each with matched highlights
  const recursiveElementTextSearch = (
    element: HTMLElement,
    textToFind: string,
  ) => {
    if (element.innerHTML.includes('search-text-highlight')) {
      const elementsToRemove = element.getElementsByClassName(
        'search-text-highlight',
      )
      const childElementsArr = Array.from(elementsToRemove)

      childElementsArr.forEach((childElement) => {
        const textNode = document.createTextNode(childElement.textContent)
        childElement.parentNode?.replaceChild(textNode, childElement)
      })

      state.currentMatchIndex.set(0)
      state.matchesTotal.set(0)
    }

    if (!textToFind) return

    for (let i = 0; i < element.childNodes.length; i++) {
      const child = element.childNodes[i]

      if (child instanceof HTMLElement && child.tagName !== 'STYLE') {
        child.childNodes.forEach((node, index) => {
          if (
            node.nodeName !== 'STYLE' &&
            // checks if nodeType is text (3) or an element without children
            (node.nodeType === 3 ||
              (node instanceof HTMLElement && !node.children.length)) &&
            node.textContent
              .trim()
              .toLowerCase()
              .includes(textToFind.trim().toLowerCase())
          ) {
            const text: string = node.textContent
            const indices: number[] = []

            // get all instances of matched search
            let currentIndex = text
              .toLowerCase()
              .indexOf(textToFind.trim().toLowerCase())
            while (currentIndex !== -1) {
              indices.push(currentIndex)
              currentIndex = text.indexOf(textToFind.trim(), currentIndex + 1)
            }

            let modifiedText: string[] = []
            // beginning portion of text that doesn't match search
            modifiedText.push(text.slice(0, indices[0]))

            // add mark tag to highlight all instances of matched search
            indices.forEach((subStringIndex, index) => {
              const matchedText = text.slice(
                subStringIndex,
                subStringIndex + textToFind.length,
              )
              modifiedText.push(
                `<mark class="search-text-highlight" tabindex="${index}">` +
                  matchedText +
                  '</mark>',
              )

              if (index < indices.length - 1)
                modifiedText.push(
                  text.slice(
                    subStringIndex + textToFind.length,
                    indices[index + 1],
                  ),
                )
              state.matchesTotal.set((count) => count + 1)
            })

            // end portion of text that doesn't match search
            modifiedText.push(
              text.slice(
                indices[indices.length - 1] + textToFind.length,
                text.length,
              ),
            )

            let modifiedInnerHTML: string = modifiedText.join('')
            // if node type is element then add tag name and attributes
            if (node instanceof HTMLElement) {
              const attributes = node
                .getAttributeNames()
                .map((attr) => `${attr}=${node.getAttribute(attr)}`)
              modifiedInnerHTML = `<${node.nodeName} ${attributes.join(
                ' ',
              )}>${modifiedText.join('')}</${node.nodeName}>`
            }

            // create a newNode with matched search modification to replace existing node so innerHTML changes
            const newNode = document.createElement('div')
            newNode.className = 'search-text-wrapper'
            newNode.innerHTML = modifiedInnerHTML

            child.replaceChild(newNode, child.childNodes[index])
          } else if (node instanceof HTMLElement)
            recursiveElementTextSearch(node, textToFind)
        })
      }
    }

    // focus to first matched search
    if (element.innerHTML.includes('search-text-highlight')) {
      const matches = document.getElementsByClassName('search-text-highlight')
      state.textMatches.set(matches)
      if (matches.length > 0) {
        state.currentMatchIndex.set(1)

        const firstResult = matches[0] as HTMLElement
        firstResult.classList.add('search-text-highlight--current')
        firstResult.scrollIntoView()
      }
    }
  }

  return (
    <input
      className='text-finder__input'
      onChange={(e) => state.textToFind.set(e.target.value)}
      placeholder='Find'
      value={state.textToFind.get()}
    />
  )
}

export default TextFinderInput
